import {Injectable} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { filter } from 'rxjs/operators';
import {FormService} from "../form-service/form.service";

@Injectable({
  providedIn: 'root'
})
export class WizardService {
  private readonly pageRoutes: { [key: number]: string } = {
    1: '/duration',
    2: '/sponsoring-value',
    3: '/start-date',
    4: '/end-date',
    5: '/confirmation',
    6: '/applicant',
    7: '/sponsored',
    8: '/financial-assistance',
    9: '/entitlements',
    10: '/consent',
    11: '/file-upload',
    12: '/conclusion',
    13: '/next-steps'
  };

  currentPage: number = 1;
  allPages: number = Object.keys(this.pageRoutes).length -1;

  constructor(private router: Router, private formService: FormService) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.updateCurrentPage();
    });
  }

   updateCurrentPage(): void {
    const currentRoute = this.router.url;
    const pageEntry = Object.entries(this.pageRoutes).find(([_, route]) => route === currentRoute);
    if (pageEntry) {
      this.currentPage = +pageEntry[0];
    } else {
      this.currentPage = 1;
    }
  }

  navigateToNextPage(): void {
    if (this.currentPage < this.allPages) {
      this.currentPage++;
      this.router.navigate([this.pageRoutes[this.currentPage]]);
      document.body.scrollTop = 0;
    }
  }

  navigateToPreviousPage(): void {
    if (this.currentPage <= 1) {
      localStorage.removeItem('form-data');
      this.router.navigate(['/dashboard']);
    } else {
      this.router.navigate([this.pageRoutes[--this.currentPage]]);
    }
  }

  saveFormInStorage(form: FormGroup, controls?: Array<any>, otherErrors?: boolean): void {
    if(!controls){
      this.navigateToNextPage();
    }
      controls?.forEach(control => {
        if (control && !control.disabled) {
          control.markAsTouched();
        }
      });
      const invalidControls = controls?.some(control => control?.invalid);
      if(form && !invalidControls && !otherErrors) {
        this.formService.setFormData(form);
        this.navigateToNextPage();
      }
  }
}
