<div class="container-wizard container" *transloco="let t">
  <app-wizard-header [viewMode]="true"
                     [applicationId]="applicationId"
                     [status]="this.applicationData?.sponsoringValue.application_state_label"
                     [descriptionOrganisation]="applicationData?.sponsored.sponsoredName"
                     [title]="'Zuschuss-Dokumente hochladen'">
  </app-wizard-header>

  <div class="hints container">
    <h4>Sie haben einen Zuschuss beantragt. Damit Ihr Zuschuss bewilligt wird, führen Sie bitte die folgenden Schritte durch:</h4>
    <app-hint [hintDescription]="t('zuschuss.hint')"></app-hint>
  </div>

  <div class="documents container">
    <h4 class="redHeadline">Zuschussrelevante Dateien hochladen</h4>

    <app-file-uploader [showExample]="false" description="Umsatzsteuerbefreiung des Gesponserten" [purpose]="'Umsatzsteuerbefreiung'"></app-file-uploader>
    <app-file-viewer [downloadedFiles]="selectedFiles" [title]="''" [purpose]="['Umsatzsteuerbefreiung']"></app-file-viewer>

    <app-file-uploader [showExample]="false" description="Belegfotos aller werblichen Maßnahmen (Leistungsbestandteile)" [purpose]="'Leistungsbestandteile'"></app-file-uploader>
    <app-file-viewer [downloadedFiles]="selectedFiles" [title]="''" [purpose]="['Leistungsbestandteile']"></app-file-viewer>

    <app-file-uploader [showExample]="false"description="Rechnungskopien" [purpose]="'Rechnungskopien'"></app-file-uploader>
    <app-file-viewer [downloadedFiles]="selectedFiles" [title]="''" [purpose]="['Rechnungskopien']"></app-file-viewer>

    <app-file-uploader [showExample]="false"[showHint]="true" description="Zahlungsnachweise über gesamte Brutto-Sponsoring-Summe " [purpose]="'Zahlungsnachweise'"></app-file-uploader>
    <app-file-viewer [downloadedFiles]="selectedFiles" [title]="''" [purpose]="['Zahlungsnachweise']"></app-file-viewer>

    <app-file-uploader [showExample]="false" description="Sonstige Dateien" [purpose]="'sonstiges_zuschuss'"></app-file-uploader>
    <app-file-viewer [downloadedFiles]="selectedFiles" [title]="''" [purpose]="['sonstiges_zuschuss']"></app-file-viewer>
  </div>

  <div class="comment-history row">
    <h5 *ngIf="getCommentsForSection('fundingCheck')">Historie der Kommentare</h5>
    <ng-container *ngFor="let comment of getCommentsForSection('fundingCheck')">
      <div class="col-lg-12 element-space">
        <h5 class="col-lg-12"><b>Von ERGO Sponsoring-Manager</b>  {{ comment.authorName }}
          am {{ comment.created_at | date:'dd.MM.yyyy' }}</h5>
        <p class="font-c2">{{ comment.content }}</p>
      </div>
    </ng-container>
  </div>


  <div class="correctionButton" *ngIf="getCommentsForSection('fundingCheck') else Default">
    <app-text-button
      [isPrimaryButton]="true"
      [buttonText]="'Korrektur senden'"
      (click)="onClickChangeState('accepted')"
    />
  </div>
  <ng-template #Default>
    <div class="buttonGroup" [ngClass]="applicationData?.sponsoringValue?.application_state === applicationStateEnum.ZUSCHUSS_EDIT_MODE ? '' : 'buttonRight'" >
      <app-text-button *ngIf="applicationData?.sponsoringValue?.application_state === applicationStateEnum.ZUSCHUSS_EDIT_MODE"
        [isPrimaryButton]="false"
        [buttonText]="'Zwischenspeichern'"
        (click)="onClickSave()"
      />
      <app-text-button
        [isPrimaryButton]="true"
        [buttonText]="'Zur Prüfung freigeben'"
        (click)="onClickChangeState('accepted')"
      />
    </div>
  </ng-template>

  <app-media-box class="element-space-top" [isText]="true" [textContent]="mediaBoxContent"></app-media-box>
  <ng-template #mediaBoxContent>
    <app-icons class="question-icon" [iconColor]="'red'" [iconSize]="'large'" [iconName]="'question-icon'"></app-icons>
    <h5 class="text-center" [innerHTML]="'dashboard.mediaPart.helpTitle' | transloco"></h5>
    <a href="mailto:sponsoring.regional@ergo.de"><p class="font-c3 text-center">{{t('dashboard.mediaPart.helpDescription')}}</p></a>
  </ng-template>
</div>
