import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {
  ApplicationStateComponentComponent
} from "../../../../shared/components/application-state-component/application-state-component.component";
import {DatePipe} from "@angular/common";
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell, MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow, MatRowDef, MatTable, MatTableDataSource
} from "@angular/material/table";
import {SponsoredOverviewDto} from "../../../../api/sponsoredOverviewDto";
import {MatPaginator, MatPaginatorIntl, PageEvent} from "@angular/material/paginator";
import {Router} from "@angular/router";
import {TableSortComponent} from "../../../../shared/utils/table-sort/table-sort.component";
import {CustomMatPaginatorIntl} from "../../../../shared/utils/custom-paginator-intl";

@Component({
  selector: 'app-sponsored-detail-view-table',
  standalone: true,
  imports: [
    ApplicationStateComponentComponent,
    DatePipe,
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatTable,
    MatHeaderCellDef,
    MatPaginator,
    TableSortComponent
  ],
  providers: [{provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl}],
  templateUrl: './sponsored-detail-view-table.html',
  styleUrl: './sponsored-detail-view-table.scss'
})

export class SponsoredDetailViewTable implements OnChanges {
  @Input() allSponsorings: Array<any> = [];
  @Input() paginationInformation: {total: number, current: number} = {total: 0, current: 0};
  @Output() pageChanged: EventEmitter<any> = new EventEmitter();
  @Output() sortingChanged: EventEmitter<any> = new EventEmitter();

  displayedColumns: string [] = ['responsiblePersonal', 'start', 'end', 'sponsoringValue', 'state'];
  dataSource = new MatTableDataSource<SponsoredOverviewDto>();
  constructor(private router: Router) {}

  ngOnChanges() {
    this.initializeDataSource();
  }

  initializeDataSource(): void {
    this.dataSource = new MatTableDataSource(this.allSponsorings);
  }

  setSponsoringValue(element: any): string {
    let value = '';
    if(element.taxFree) {
      if(element.sponsoringNetAmountSecondYear) {
        value =  `1. Jahr: ${element.sponsoringValueNetAmountFirstYear} € <br> 2. Jahr: ${element.sponsoringValueNetAmountSecondYear} €`
      } else {
        value =  `1. Jahr: ${element.sponsoringValueNetAmountFirstYear} €`
      }
    } else {
      if(element.sponsoringGrossAmountSecondYear) {
        value =  `1. Jahr: ${element.sponsoringValueGrossAmountFirstYear} € <br> 2. Jahr: ${element.sponsoringValueGrossAmountSecondYear} €`
      } else {
        value =  `1. Jahr: ${element.sponsoringValueGrossAmountFirstYear} €`
      }
    }
    return value;
  }

  onPageChange(event: PageEvent) {
    const pageIndex = event.pageIndex + 1;
    this.pageChanged.emit(pageIndex);
  }

  editApplication(row: any) {
     this.router.navigate(['/detailview'], {state: {applicationId: row.id}});
  }

  sortValues(event: { column: string, direction: string }): void {
    this.sortingChanged.emit(event);
  }

}
