<ng-container *transloco="let t">

  <div class="spinner-container" *ngIf="!applicationData">
    <mat-spinner></mat-spinner>
  </div>
  <ng-container *ngIf="applicationData">
    <div class="container-wizard container">
      <app-wizard-header [viewMode]="true" [status]="this.applicationData?.sponsoringValue.application_state_label"
                         [title]="'Prüfung von ' + applicationData?.sponsored.sponsoredName">
      </app-wizard-header>

      <div class="container">
        <h2 class="description">Bitte überprüfen Sie die eingereichten Dateien: </h2>
        <app-file-viewer [downloadedFiles]="selectedFiles" [titleIsAccordionStyle]="false" [title]="'Umsatzsteuerbefreiung des Gesponserten'" [purpose]="['Umsatzsteuerbefreiung']"></app-file-viewer>
        <app-file-viewer [downloadedFiles]="selectedFiles" [titleIsAccordionStyle]="false"  [title]="'Belegfotos aller werblichen Maßnahmen (Leistungsbestandteile)'" [purpose]="['Leistungsbestandteile']"></app-file-viewer>
        <app-file-viewer [downloadedFiles]="selectedFiles" [titleIsAccordionStyle]="false"  [title]="'Rechnungskopien'" [purpose]="['Rechnungskopien']"></app-file-viewer>
        <app-file-viewer [downloadedFiles]="selectedFiles" [titleIsAccordionStyle]="false"  [title]="'Zahlungsnachweise über gesamte Brutto-Sponsoring-Summe '" [purpose]="['Zahlungsnachweise']"></app-file-viewer>
        <app-file-viewer [downloadedFiles]="selectedFiles" [titleIsAccordionStyle]="false"  [title]="'Sonstige Dateien'" [purpose]="['sonstiges_zuschuss']"></app-file-viewer>

        <h5>Kommentar</h5>
        <textarea [(ngModel)]="zuschussComment"
                  placeholder="min. 10 Zeichen"
                  class="col-lg-12 section-space-default" [minlength]="10">
      </textarea>

        <div class="zuschuss-amount">
          <h3 class="subtitle element-space">Zuschussbetrag</h3>
          <div class="row element-space">
            <label class="font-c2 col-lg-12">Kürzung</label>
            <mat-radio-group class="radio-button" [(ngModel)]="kuerzung" (change)="changeValue($event)">
              <mat-radio-button [value]="true"><p>{{t('common.yes')}}</p></mat-radio-button>
              <mat-radio-button [value]="false"><p>{{t('common.no')}}</p></mat-radio-button>
            </mat-radio-group>
          </div>

          <mat-form-field *ngIf="kuerzung" class="full-with">
            <label class="font-c2">Grund der Kürzung</label>
            <input matInput [(ngModel)]="kuerzung_reason">
          </mat-form-field>

          <mat-form-field class="full-with">
            <label class="font-c2">Zuschussbetrag in Euro brutto</label>
            <input type="text"
                   min="0"
                   matInput
                   [(ngModel)]="amount" appCurrencyFormat appNumericInput>
          </mat-form-field>


        </div>

        <div class="col-lg-12 decisionButton">
          <app-text-button [isPrimaryButton]="false" [buttonText]="'Zuschuss ablehnen'"
                           (click)="openRejectionModal('rejected')"></app-text-button>
          <app-text-button [isPrimaryButton]="true"
                           [buttonText]="'Rückgabe zur Korrektur'"
                           (click)="saveCommentForZuschuss('correctionNeeded')"></app-text-button>
          <app-text-button [isPrimaryButton]="true" [isButtonReadOnly]="isApproveButtonDisabled()" [buttonText]="'Zuschuss freigeben'"
                           (click)="approveFunding()"></app-text-button>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
