import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {DataService} from "../../../../core/services/data.service";
import {IconTextButtonComponent} from "../../../../shared/components/button/icon-text-button/icon-text-button.component";
import {TranslocoDirective} from "@ngneat/transloco";
import {MatCard, MatCardContent} from "@angular/material/card";
import {DatePipe, NgForOf, NgIf} from "@angular/common";
import {SponsoredOverviewDto} from "../../../../api/sponsoredOverviewDto";
import {Subscription} from "rxjs";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TextButtonComponent} from "../../../../shared/components/button/text-button/text-button.component";
import {UserDto} from "../../../../api/userDto";
import {SponsoredDetailViewTable} from "../sponsored-detail-view-table/sponsored-detail-view-table";

@Component({
  selector: 'app-sponsored-detail-view',
  standalone: true,
  imports: [
    IconTextButtonComponent,
    TranslocoDirective,
    MatCard,
    MatCardContent,
    NgForOf,
    ReactiveFormsModule,
    TextButtonComponent,
    FormsModule,
    DatePipe,
    NgIf,
    SponsoredDetailViewTable
  ],
  templateUrl: './sponsored-detail-view.html',
  styleUrl: './sponsored-detail-view.scss'
})
export class SponsoredDetailView implements OnInit, OnDestroy {
  partnerCard: Array<{key: string, value: string}> = [];
  addressCard: Array<{key: string, value: string}> = [];
  historyInternalNotes: Array<{authorName: string, created_at: string, note: string}> = [];
  sortOrder : {column: string, direction: string} = {column: '', direction: ''};

  internalNote: string = '';
  applicationName: string = '';
  sponsoredId: string = '';
  user: UserDto | null = null;

  allSponsoringForSelectedSponsor: Array<SponsoredOverviewDto> = [];
  paginationInformation: {total: number, current: number} = {total: 0, current: 0};

  private routeSubscription!: Subscription;

  constructor(
    private dataService: DataService,
    private router: Router,
    private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.dataService.getUser().subscribe((result) => {
      this.user = result;
    })
    this.routeSubscription = this.route.paramMap.subscribe(params => {
      const state: any = history.state;
      if (state.sponsoredId) {
        this.sponsoredId = state.sponsoredId;
        this.getSponsoringDataForSelectedId(state.sponsoredId);
      }
    });
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  getSponsoringDataForSelectedId(sponsoredId: string) {
    this.dataService.getSponsoredDataForId(sponsoredId).subscribe((result) => {
      this.initializeData(result);
    })
  }

  loadMoreSponsoringData(page: number): void {
    this.dataService.getAllSponsoredData(page, this.sortOrder).subscribe((result) => {
      this.initializeData(result.data);
    });
  }

  sortValues(event: { column: string, direction: string }): void {
    this.dataService.getAllSponsoredData(1, event).subscribe((result) => {
      this.sortOrder = event;
      if(result){
        this.paginationInformation = result.total;
        this.initializeData(result.data);
      }
    });
  }

  saveInternalNote(note: string): void {
    this.historyInternalNotes.unshift({
      authorName: this.user?.firstName + ' ' + this.user?.lastName,
      note: note,
      created_at: new Date().toString(),
    });

    this.dataService.postInternalNoteApplicantOverview(this.sponsoredId, {note:note, rating: 'good'}).subscribe({
      next: () => {
        this.internalNote = ''
      },
      error: (err) => {
        console.error('Interne Anmerkung konnte nicht geändert werden', err);
      }
    });
  }

  navigateToSponsoredOverview(): void {
    this.router.navigate(['/sponsored-overview']);
  }

  private initializeData(data: any): void {
    this.allSponsoringForSelectedSponsor = data.application_list.data;
    this.paginationInformation = {total: data.application_list.total, current: data.application_list.current};
    this.historyInternalNotes = data.internalNotes;
    this.applicationName = data.sponsoredName;

    this.partnerCard.push(
      {key: 'Vertriebspartner', value: data.applicant},
      {key: 'Nummer/ID', value: data.responsiblePersonal + ' ' +  data.applicant_rd}
    );
    this.addressCard.push(
      {key: 'Adresse', value: data.street_name + ' ' + data.street_number + '<br>' + data.zip + ' ' + data.city},
    )
  }
}
