import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class IconService {
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.registerSvgIcon('add-icon', 'assets/icons/modulbaukasten/hinzufuegen-plus.svg');
    this.registerSvgIcon('reminder-icon', 'assets/icons/modulbaukasten/glocke1.svg');
    this.registerSvgIcon('info-icon', 'assets/icons/modulbaukasten/information1.svg');
    this.registerSvgIcon('download-icon', 'assets/icons/modulbaukasten/download.svg');
    this.registerSvgIcon('question-icon', 'assets/icons/modulbaukasten/fragezeichen1.svg');
    this.registerSvgIcon('arrow-right', 'assets/icons/modulbaukasten/pfeil_rechts.svg');
    this.registerSvgIcon('arrow-left', 'assets/icons/modulbaukasten/pfeil_links.svg');
    this.registerSvgIcon('arrow-down', 'assets/icons/modulbaukasten/pfeil_runter.svg');
    this.registerSvgIcon('arrow-up', 'assets/icons/modulbaukasten/pfeil_rauf.svg');
    this.registerSvgIcon('document', 'assets/icons/modulbaukasten/dokument10.svg');
    this.registerSvgIcon('glocke', 'assets/icons/modulbaukasten/glocke1.svg');
    this.registerSvgIcon('wolke', 'assets/icons/modulbaukasten/wolken.svg');
    this.registerSvgIcon('mountain', 'assets/icons/modulbaukasten/berg_wolke.svg');
    this.registerSvgIcon('edit-icon', 'assets/icons/modulbaukasten/fueller.svg');
    this.registerSvgIcon('close-icon', 'assets/icons/modulbaukasten/schliessen_new.svg');
    this.registerSvgIcon('open-accordion', 'assets/icons/modulbaukasten/pfeil_rauf.svg');
    this.registerSvgIcon('close-accordion', 'assets/icons/modulbaukasten/pfeil_runter.svg');
    this.registerSvgIcon('delete-icon', 'assets/icons/modulbaukasten/loeschen.svg');
    this.registerSvgIcon('check-icon', 'assets/icons/modulbaukasten/check.svg');
    this.registerSvgIcon('rejection-icon', 'assets/icons/modulbaukasten/rejection.svg');
    this.registerSvgIcon('print-icon', 'assets/icons/modulbaukasten/print.svg');
    this.registerSvgIcon('customer-icon', 'assets/icons/modulbaukasten/kunde.svg');
  }

  public registerSvgIcon(name: string, path: string): void {
    this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(path));
  }
}
