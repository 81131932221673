import {Component, Input} from '@angular/core';
import {TranslocoDirective} from "@ngneat/transloco";
import {WizardService} from "../../../shared/services/wizard-service/wizard.service";
import {FormService} from "../../../shared/services/form-service/form.service";
import {DataService} from "../../../core/services/data.service";
import {Router} from "@angular/router";
import {MatCard} from "@angular/material/card";
import {StateTextService} from "../../../shared/services/state-text-service/state-text.service";
import { NgIf} from "@angular/common";
import {applicationStateEnum} from "../../../api/enum/stateEnum";
import {FileUploadService} from "../../../shared/components/file-uploader/file-uploader-service";


@Component({
  selector: 'app-special-case-cards',
  standalone: true,
  imports: [
    MatCard,
    TranslocoDirective,
    NgIf,
  ],
  templateUrl: './special-case-cards.component.html',
  styleUrl: './special-case-cards.component.scss'
})
export class SpecialCaseCardsComponent {
  @Input() applicationData: any = null;
  @Input() applicationId: any = null;

  constructor(public wizardService: WizardService,
              public stateTextService: StateTextService,
              public formService: FormService,
              public fileUploadService: FileUploadService,
              public dataService: DataService,
              public router: Router,
  ) {
  }

  protected readonly JSON = JSON;
  protected readonly applicationStateEnum = applicationStateEnum;
}
