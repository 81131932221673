<ng-container *transloco="let t" id="applicant-detail">
  <div class="container container-wizard">
    <app-icon-text-button [buttonSecondary]="true"
                          [iconName]="'arrow-left'"
                          [iconSize]="'small'"
                          [buttonText]="t('common.previous')"
                          [textColor]="'red'"
                          [buttonLeft]="true"
                          (click)="navigateToApplicantOverview()"
    ></app-icon-text-button>

    <ng-container id="header">
      <app-icons class="icon"
                 [bgColor]="'warningRed'"
                 [iconName]="'customer-icon'"
                 [iconSize]="'xLarge'"
                 [circleIcon]="true"></app-icons>
      <h1 class="spacing-title" [innerHTML]="vpName"></h1>
      <h4 class="spacing-title" [innerHTML]="careerLevel"></h4>
      <h3 class="header">Übersicht</h3>
    </ng-container>

    <ng-container id="content">
      <div class="key-container col-lg-12">
        <mat-card *ngFor="let cardGroup of [partnerCard, addressCard]" class="col-lg-6 card-spacing">
          <mat-card-content>
            <div *ngFor="let card of cardGroup" class="key-container">
              <div class="key"><h5>{{ card.key }}</h5></div>
              <div class="value"><p class="font-c2" [innerHTML]="card.value"></p></div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </ng-container>

    <app-applicant-detailview-table
      [allData]="allDataForSelectedApplicant"
      [applicantId]="applicantId" >
    </app-applicant-detailview-table>
  </div>
</ng-container>
