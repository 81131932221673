<div class="login" id="login-table">
  <div class="container login-container">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="sticky-header"><h4>Name</h4></th>
        <td mat-cell *matCellDef="let element"><p class="font-c2">{{element.name}}</p></td>
      </ng-container>

      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef class="sticky-header"><h4>Rolle</h4></th>
        <td mat-cell *matCellDef="let element"><p class="font-c2">{{element.role}}</p></td>
      </ng-container>

      <ng-container matColumnDef="link">
        <th mat-header-cell *matHeaderCellDef class="sticky-header"><h4>Link</h4></th>
        <td mat-cell *matCellDef="let element">
          <button (click)="authenticationService.setToken(element.link)"><p class="font-c2-link">Login</p></button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
