<ng-container *transloco="let t">
  <ng-container *ngIf="(data.action === 'rejected' || data === 'rejected') else sendMessage">
    <div class="dialog-header">
      <h3>{{ title }}</h3>
      <mat-icon svgIcon="close-icon" (click)="closeDialog()"></mat-icon>
    </div>

    <div class="dialog-content container">
      <div class="row">
        <label><h5>{{content}}</h5></label>
        <textarea [(ngModel)]="rejectionReason" class="element-space commentFieldStyle"></textarea>
        <div>
          <div class="button-group col-lg-12">
            <app-text-button  [isButtonReadOnly]="!rejectionReason" [isPrimaryButton]="true" buttonText="Vertrag ablehnen" (click)="rejectContract()"></app-text-button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #sendMessage>
    <div class="dialog-header">
      <h3>Zusätzliche Nachricht an den Antragsteller</h3>
      <mat-icon svgIcon="close-icon" (click)="closeDialog()"></mat-icon>
    </div>

    <div class="dialog-content container">
      <div class="row">
        <label>Was möchten Sie dem Antragsteller zusätzlich mitteilen? <br>Ihr Text erscheint 1:1 in der automatischen Mail.</label>
        <textarea [(ngModel)]="rejectionReason" class="element-space commentFieldStyle"></textarea>
        <div>
          <div class="button-message col-lg-12">
            <app-text-button  [isPrimaryButton]="false" buttonText="Ohne Nachricht senden" (click)="rejectContract()"></app-text-button>
            <app-text-button [isPrimaryButton]="true" [isButtonReadOnly]="!rejectionReason" buttonText="Nachricht hinzufügen" (click)="rejectContract()"></app-text-button>
          </div>
        </div>
      </div>

    </div>
  </ng-template>
</ng-container>


