import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {MatRadioButton, MatRadioChange, MatRadioGroup} from "@angular/material/radio";
import {ReactiveFormsModule} from "@angular/forms";
import {TranslocoDirective} from "@ngneat/transloco";
import {WizardService} from "../../../../shared/services/wizard-service/wizard.service";
import {FormService} from "../../../../shared/services/form-service/form.service";
import {HintComponent} from "../../../../shared/components/hint/hint.component";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-zuschuss',
  standalone: true,
  imports: [
    MatRadioButton,
    MatRadioGroup,
    ReactiveFormsModule,
    TranslocoDirective,
    HintComponent,
    NgIf
  ],
  templateUrl: './zuschuss.component.html',
  styleUrl: './zuschuss.component.scss'
})
export class ZuschussComponent {
  @Input() editMode = false;
  form = this.formService.buildForm();

  constructor(public wizardService: WizardService,
              public formService: FormService) {
    this.formService.initializeForm(this.form);
  }

  changeZuschuss(event: MatRadioChange) {
    if(event){
      this.formService.setFormData(this.form);
    }
  }
}
