import {Component, Input} from '@angular/core';
import {MatCard} from "@angular/material/card";
import {IconsComponent} from "../icons/icons.component";

@Component({
  selector: 'app-empty-state-general',
  standalone: true,
  imports: [
    MatCard,
    IconsComponent
  ],
  templateUrl: './empty-state-general.component.html',
  styleUrl: './empty-state-general.component.scss'
})
export class EmptyStateGeneralComponent {
  @Input() title: string = '';
  @Input() description: string = '';
}
