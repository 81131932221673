<ng-container *transloco="let t">
  <div id="wizard-footer" class="row footer">

    <app-text-button class="button save-button text-center"
                     [isPrimaryButton]="true" [buttonText]="textInput ? textInput : t('common.next')"
                     (click)="this.wizardService.saveFormInStorage(newForm, splitForm, otherErrors)">
    </app-text-button>

  </div>
</ng-container>
