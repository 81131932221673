import {Component, Input} from '@angular/core';
import {TextButtonComponent} from "../../../shared/components/button/text-button/text-button.component";
import {WizardService} from "../../../shared/services/wizard-service/wizard.service";
import {TranslocoDirective} from "@ngneat/transloco";
import {FormService} from "../../../shared/services/form-service/form.service";

@Component({
  selector: 'app-wizard-footer',
  standalone: true,
  imports: [
    TextButtonComponent,
    TranslocoDirective
  ],
  templateUrl: './wizard-footer.component.html',
  styleUrl: './wizard-footer.component.scss'
})

export class WizardFooterComponent {
  @Input() newForm: any;
  @Input() splitForm: any;
  @Input() textInput: any;
  @Input() otherErrors = false;

  constructor(public wizardService: WizardService) {
    // noop
  }
}
