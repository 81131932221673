import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Observable, take} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApplicationDto} from "../../api/applicationDto";
import {API_URLS} from "../../api/_api_config";
import {CookieService} from "ngx-cookie-service";
import {environment} from "../../../environments/environment";
import {DataService} from "./data.service";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  backendURL = environment.apiPath;
  url = '';
  token = '';

  constructor(private http: HttpClient, private router: Router, private activatedRoute: ActivatedRoute, private cookieService: CookieService, private dataService: DataService ) {
    // noop
  }

  getRolesForLoginPage(): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', this.token );
    const url = this.backendURL + API_URLS.GET_ROLES_FOR_LOGIN_PAGE;
    return this.http.get<ApplicationDto>(url, {headers})
  }

  ssoLogin(): void {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe(params => {
      if(params['pnr'] !== undefined){
        this.url = this.backendURL + '/api/auth/sso?'  + 'pnr='+ params['pnr'] + '&' + 'signed_key=' +params['signed_key'] + '&' + 'time=' + params['time'];
        this.setToken(this.url);
      }
    });
  }

  setToken(link: string) {
    this.deleteToken();
    this.http.get<any>(link, {}).subscribe((result) => {
      this.token = 'Bearer ' + result.token;
      if (this.token) {
        this.cookieService.set(
          'user-token',
          this.token,
          {
            secure: true,
            sameSite: 'Strict',
            path: '/',
            expires: 1,
          }
        );
        this.router.navigate(['/dashboard']);
        this.setUserRole();
      }
    });
  }


  setUserRole(): void {
    this.dataService.getUserRole().subscribe((result) => {
      this.dataService.role = result.role;

      this.cookieService.set(
        'user-role',
        result.role,
        {
          secure: true,
          sameSite: 'Strict',
          path: '/',
          expires: 1
        }
      );
    })
  }

  deleteToken() {
    localStorage.removeItem('form-data');
    this.cookieService.delete('user-token');
    this.cookieService.delete('user-role');
    this.token = '';
    this.router.navigate(['/']);
  }
}
