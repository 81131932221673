<ng-container *transloco="let t">
  <div class="application-container" id="add-new-application">
    <div *ngIf="emptyDashboard else addApplicationSmall">
      <mat-card>
        <div class="add-content row section-space-large">
          <app-icons class="section-space-large"
                     [circleIcon]="true"
                     [iconName]="'document'"
                     [iconSize]="'large'"
                     [bgColor]="'warningRed'">
          </app-icons>
          <h1 [innerHTML]="getTitle()"></h1>
          <p class="font-c2 section-space-large" [innerHTML]="getDescription()"></p>

          <app-icon-text-button *ngIf="activeUserRole === UserRoleEnum.APPLICANT"
                                (click)="addNewApplication()"
                                [iconName]="'add-icon'"
                                [iconSize]="'large'"
                                [class]="'ergo-red'"
                                [textColor]="'white'"
                                [buttonText]="t('dashboard.addNewApplication.addApplication')">
          </app-icon-text-button>
        </div>
      </mat-card>
    </div>
  </div>


  <ng-template #addApplicationSmall>
    <mat-card class="smallCard">
      <h5>{{ t('dashboard.addNewApplication.addApplicationTitle') }}</h5>
      <app-icon-text-button
        (click)="addNewApplication()"
        [iconName]="'add-icon'"
        [iconSize]="'large'"
        [class]="'ergo-red'"
        [textColor]="'white'"
        [buttonText]="t('dashboard.addNewApplication.addApplication')">
      </app-icon-text-button>
    </mat-card>
  </ng-template>
</ng-container>
