import {Component, OnInit} from '@angular/core';
import {TranslocoModule} from "@ngneat/transloco";
import {TextButtonComponent} from "../../../shared/components/button/text-button/text-button.component";
import {MatCard} from "@angular/material/card";
import {WizardHeaderComponent} from "../wizard-header/wizard-header.component";
import {ReactiveFormsModule} from "@angular/forms";
import {WizardService} from "../../../shared/services/wizard-service/wizard.service";
import {FormService} from "../../../shared/services/form-service/form.service";
import {WizardFooterComponent} from "../wizard-footer/wizard-footer.component";
import {StepperComponent} from "../../../shared/components/stepper/stepper.component";

@Component({
  selector: 'app-wizard-sponsoring-duration',
  standalone: true,
  imports: [
    TranslocoModule,
    TextButtonComponent,
    MatCard,
    WizardHeaderComponent,
    ReactiveFormsModule,
    WizardFooterComponent,
    StepperComponent
  ],
  templateUrl: './sponsoring-duration.component.html',
  styleUrl: './sponsoring-duration.component.scss'
})
export class SponsoringDurationComponent {
  form = this.formService.buildForm();

  constructor(private formService: FormService, public wizardService: WizardService) {
    // noop
  }

  onClickButton(duration: number) {
    this.form.get('sponsoringValue.sponsoringDuration')?.setValue(duration);
    this.wizardService.saveFormInStorage(this.form, []);
    this.disableFormControl(duration);
  }

  disableFormControl(duration: number): void {
    if(duration === 1){
      this.form.get('sponsoringValue.sponsoringValueGrossAmountSecondYear')?.setValue(null);
      this.form.get('sponsoringValue.sponsoringValueNetAmountSecondYear')?.setValue(null);
    }
    this.formService.setFormData(this.form);
  }


}

