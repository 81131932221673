<div class="headerRow">
  <div class="container">
    <div class="col-lg-12 content">
      <div class="col-lg-2 logo">
        <img src="../../../../assets/icons/svg/Logo.svg" (click)="navigateToDashboard()">
      </div>
      <div class="col-lg-8">
        <app-navigation></app-navigation>
      </div>
      <div class="col-lg-2 button">
        <app-text-button [buttonText]="'Abmelden'" [class]="'secondary-red'" (click)="logout()"></app-text-button>
      </div>
    </div>
  </div>
</div>
