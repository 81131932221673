import {Component, OnDestroy, OnInit} from '@angular/core';
import {WizardService} from "../../../shared/services/wizard-service/wizard.service";
import {TranslocoModule} from "@ngneat/transloco";
import {WizardHeaderComponent} from "../wizard-header/wizard-header.component";
import {WizardFooterComponent} from "../wizard-footer/wizard-footer.component";
import {MatError, MatFormField} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {ReactiveFormsModule} from "@angular/forms";
import {FormService} from "../../../shared/services/form-service/form.service";
import {StepperComponent} from "../../../shared/components/stepper/stepper.component";
import {FormErrorDirective} from "../../../shared/directives/form-error-directive";
import {NgIf} from "@angular/common";
import {ValidationService} from "../../../shared/services/validation-service";
import {DataService} from "../../../core/services/data.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-wizard-sponsoring-applicantinformation',
  standalone: true,
  imports: [
    TranslocoModule,
    WizardHeaderComponent,
    WizardFooterComponent,
    MatFormField,
    MatInput,
    ReactiveFormsModule,
    StepperComponent,
    FormErrorDirective,
    MatError,
    NgIf
  ],
  templateUrl: './applicant-information.component.html',
  styleUrl: './applicant-information.component.scss'
})
export class ApplicantInformationComponent implements OnInit, OnDestroy {
  form = this.formService.buildForm();
  submitted = false;

  subscription: Subscription = new Subscription();

  constructor(public wizardService: WizardService,
              public dataService: DataService,
              public formService: FormService,
              public validationService: ValidationService) {
    // noop
  }

  ngOnInit(): void {
    this.formService.initializeForm(this.form);

    if(this.loadDataFromDatabase()) {
      const userSub = this.dataService.getUser().subscribe((result) => {
          this.form.get('applicant')?.setValue(result);
          this.form.patchValue(result);
      });
      this.subscription.add(userSub);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  loadDataFromDatabase(): boolean {
    const controls = this.form.get('applicant')?.value;
    if (!controls) {
      return false;
    }
    return Object.values(controls).every(value => value === null);
  }
}
