import {Component, Inject, Input} from '@angular/core';
import {FormBuilder, FormsModule} from "@angular/forms";
import {MatIcon} from "@angular/material/icon";
import {TextButtonComponent} from "../../../../shared/components/button/text-button/text-button.component";
import {TranslocoDirective} from "@ngneat/transloco";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-information-modal',
  standalone: true,
    imports: [
        FormsModule,
        MatIcon,
        TextButtonComponent,
        TranslocoDirective
    ],
  templateUrl: './information-modal.component.html',
  styleUrl: './information-modal.component.scss'
})
export class InformationModalComponent {
  constructor(public dialogRef: MatDialogRef<InformationModalComponent>,
              public fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
