export const API_URLS = {
  GET_ALL_APPLICATIONS_FOR_DASHBOARD: '/api/applications/getAllForDashboard',
  GET_ALL_APPLICATIONS: '/api/applications',
  GET_APPLICATION_BY_ID: '/api/applications/',
  PUT_APPLICATION: '/api/applications/{application_id}',
  POST_APPLICATION: '/api/applications',

  GET_NOTIFICATIONS: '/api/notifications',
  DELETE_NOTIFICATIONS: '/api/notifications/{notification_ID}',

  GET_USER: '/api/user',
  GET_ROLE: '/api/user/role',
  GET_ALL_APPLICANTS: '/api/applicants/',
  POST_APPLICANT: '/api/applicants/{applicantsId}',
  GET_SPONSORED: '/api/sponsored/{sponsoredId}',

  GET_ROLES_FOR_LOGIN_PAGE: '/api/ssoList',

  POST_COMMENT: '/api/applications/{application_ID}/comments',
  POST_ITERNAL_NOTE: '/api/applications/{application_ID}/internal-notes',

  PUT_UPDATE_STATE: '/api/applications/{application_ID}/status',
  PUT_STATUS: 'pi/applications/{application_id}/status',
  GET_STATUS_HISTORY: '/api/applications/{application_ID}/status',

  GET_CONFIRMATIONS: '/api/confirmations/{confirmation_id}',
  POST_DOCUMENTS: '/api/applications/{application_ID}/documents',
  DOWNLOAD_DOCUMENT_CONTENT: '/api/documents/{document_id}',

  POST_FUNDING_AMOUNT: '/api/applications/{application_id}/funding-data',
  DELETE_DOCUMENTS: '/api/documents/{document_id}',

  GET_FUNDING_DOCUMENTS: '/api/applications/{application_id}/funding-documents',
  GET_CONTRACT_BY_APPLICATION_ID: '/api/applications/{application_id}/contract-documents',

  GET_ALL_SPONSORED_DATA: '/api/sponsored',
  GET_SPONSORED_DATA_FOR_ID: '/api/sponsored/{sponsored_id}',
  GET_ALL_APPLICATIONS_FOR_OVERVIEW: '/api/applications',

  GET_ALL_APPLICANTS_OVERVIEW: '/api/applicants',
  GET_APPLICANT_OVERVIEW_DATA_FOR_ID: '/api/applicants/{applicant_id}',

  SAVE_INTERNAL_NOTE_SPONSORED_OVERVIEW: '/api/sponsored/{sponsored_id}/internal-notes',

  EXCEL_EXPORT: '/api/reporting-controlling/csv-export',
  CONTROLLING_KPI: '/api/reporting-controlling/kpis'
};
