<div class="container col-lg-12 section-space-default" *ngIf="showViewer()">
  <ng-container *ngIf="titleIsAccordionStyle else showOnlyTitle">
    <mat-card-title *ngIf="title !== ''" class="cardHeader title element-space">
      <h3>{{ title }}</h3>
      <div>
        <button class="open-card-button" (click)="closeCard()">
          <mat-icon class="icon-arrow" [svgIcon]="isCardOpen ? 'open-accordion' : 'close-accordion'"></mat-icon>
        </button>
      </div>
    </mat-card-title>
  </ng-container>
  <ng-template #showOnlyTitle>
    <h4 *ngIf="downloadedFilesList && downloadedFilesList.length > 0" [innerHTML]="title"></h4>
  </ng-template>

  <mat-card-content class="cardContent" *ngIf="isCardOpen && showViewer()">
    <ng-container *ngIf="downloadedFilesList">
      <div class="file" *ngFor="let downloadedFile of downloadedFilesList">

        <ng-container *ngIf="this.purpose.length > 1">
          <div class="displayFile">
            <app-icons class="icon" [iconName]="'mountain'"></app-icons>
            <div class="text">
              <p class="font-c2">{{ downloadedFile.name }}</p>
              <p class="font-c2 description">
                {{ '.' + downloadedFile.type.split('/')[1]! }} |
                {{ downloadedFile.size | bytesToSize }} |
                {{ (downloadedFile.date | date:'dd.MM.yyyy H:mm') + ' Uhr' }}
              </p>
            </div>
          </div>
          <div class="buttonGroup">
            <app-icon-text-button [buttonSecondary]="true"
                                  [backgroundColor]="'bg-white'"
                                  [iconName]="'download-icon'"
                                  [iconSize]="'medium'"
                                  [buttonText]="'Download'"
                                  [textColor]="'red'"
                                  [buttonLeft]="true"
                                  (click)="downloadUploadedFile(downloadedFile)"
            ></app-icon-text-button>
            <app-icons *ngIf="deletePossible" class="delete" iconName="delete-icon"
                       (click)="this.deleteUploadedFile(downloadedFile)"></app-icons>
            <app-icons *ngIf="!deletePossible" class="print" iconName="print-icon"
                       (click)="this.printDocument(downloadedFile.id)"></app-icons>
          </div>
        </ng-container>

        <ng-container *ngIf="this.purpose.length === 1 && downloadedFile['purpose'] === this.purpose[0]">
          <div class="displayFile">
            <app-icons class="icon" [iconName]="'mountain'"></app-icons>
            <div class="text">
              <p class="font-c2">{{ downloadedFile.name }}</p>
              <p class="font-c2 description">
                {{ '.' + downloadedFile.type.split('/')[1]! }} |
                {{ downloadedFile.size | bytesToSize }} |
                {{ (downloadedFile.date | date:'dd.MM.yyyy H:mm') + ' Uhr' }}
              </p>
            </div>
          </div>
          <div class="buttonGroup">
            <app-icon-text-button [buttonSecondary]="true"
                                  [backgroundColor]="'bg-white'"
                                  [iconName]="'download-icon'"
                                  [iconSize]="'medium'"
                                  [buttonText]="'Download'"
                                  [textColor]="'red'"
                                  [buttonLeft]="true"
                                  (click)="downloadUploadedFile(downloadedFile)"
            ></app-icon-text-button>
            <app-icons *ngIf="deletePossible" class="delete" iconName="delete-icon"
                       (click)="this.deleteUploadedFile(downloadedFile)"></app-icons>
            <app-icons *ngIf="!deletePossible" class="print" iconName="print-icon"
                       (click)="this.printDocument(downloadedFile.id)"></app-icons>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="selectedFiles">
      <div class="file" *ngFor="let selectedFile of allFiles">
        <ng-container *ngIf="selectedFile['purpose'] === this.purpose[0]">
          <div class="displayFile">
            <app-icons class="icon" [iconName]="'mountain'"></app-icons>
            <div class="text">
              <p class="font-c2">{{ selectedFile.name }}</p>
              <p class="font-c2 description">
                {{ '.' + selectedFile.type.split('/')[1]! }} |
                {{ selectedFile.size | bytesToSize }} |
                {{ (selectedFile.date | date:'dd.MM.yyyy H:mm') + ' Uhr' }}
              </p>
            </div>
          </div>
          <div class="buttonGroup">
            <app-icon-text-button [buttonSecondary]="true"
                                  [backgroundColor]="'bg-white'"
                                  [iconName]="'download-icon'"
                                  [iconSize]="'medium'"
                                  [buttonText]="'Download'"
                                  [textColor]="'red'"
                                  [buttonLeft]="true"
                                  (click)="downloadFile(selectedFile)"
            ></app-icon-text-button>
            <app-icons class="delete" iconName="delete-icon"
                       (click)="this.fileUploadService.delete(selectedFile)"></app-icons>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </mat-card-content>
</div>
