import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    ApplicationStateComponentComponent
} from "../../shared/components/application-state-component/application-state-component.component";
import {DatePipe} from "@angular/common";
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell, MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow, MatRowDef, MatTable, MatTableDataSource
} from "@angular/material/table";
import {AuthenticationService} from "../../core/services/authentication.service";
import {ApplicationDto} from "../../api/applicationDto";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    ApplicationStateComponentComponent,
    DatePipe,
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatTable,
    MatHeaderCellDef
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit, OnDestroy {
  displayedColumns: string [] = ['name', 'role', 'link'];
  dataSource = new MatTableDataSource<ApplicationDto>();

  private subscriptions = new Subscription();

  constructor(public authenticationService: AuthenticationService, private authenficationService: AuthenticationService) {
    // noop
  }

  ngOnInit() {
   this.authenficationService.deleteToken();
   const rolesForLoginSub =  this.authenticationService.getRolesForLoginPage().subscribe((roles) => {
      this.dataSource = roles;
    });
    this.subscriptions.add(rolesForLoginSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
