import { Injectable } from '@angular/core';
import {MatRadioChange} from "@angular/material/radio";
import {FormService} from "../form-service/form.service";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class FinancialAssistanceService {
  form = this.formService.buildForm();

  get financialAssistance(): FormGroup {
    return this.form.get('financialAssistance') as FormGroup;
  }

  get listOfRatesFirstYear(): FormArray {
    return this.form.get('financialAssistance.firstYear.rates') as FormArray;
  }

  get listOfRatesSecondYear(): FormArray {
    return this.form.get('financialAssistance.secondYear.rates') as FormArray;
  }

  constructor(public formService: FormService, public fb: FormBuilder) {
    this.formService.initializeForm(this.form);
  }

  changePaymentMethod(fullPayed: MatRadioChange, formGroup: string, year?:number): void {
    this.form.get(`financialAssistance.${formGroup}.fullPayed`)?.setValue(fullPayed.value);
    if (year === 1 || formGroup === 'firstYear') {
      this.clearRates(this.listOfRatesFirstYear);
      this.form.get('financialAssistance.firstYear.rateAmount')?.setValue(null);
      this.addRatesForFirstYear(1);
    } else if (formGroup === 'secondYear') {
      this.clearRates(this.listOfRatesSecondYear);
      this.form.get('financialAssistance.secondYear.rateAmount')?.setValue(null);
      this.addRatesForSecondYear(1);
    }
    this.formService.setFormData(this.form);
  }

  private addRatesForFirstYear(numberOfRates?: number): void {
    const rateControl = numberOfRates !== undefined ? numberOfRates : this.form.get('financialAssistance.firstYear.rateAmount')?.value;
    this.clearRates(this.listOfRatesFirstYear);

    if (numberOfRates === 1) {
      this.listOfRatesFirstYear.push(this.fb.group({
        date: this.fb.control(null, [Validators.required]),
      }));
    } else {
      for (let i = 0; i < rateControl; i++) {
        this.listOfRatesFirstYear.push(this.formService.createNewRateForm(i));
      }
    }
  }

  private addRatesForSecondYear(numberOfRates?: number): void {
    const rateControl = numberOfRates !== undefined ? numberOfRates : this.form.get('financialAssistance.secondYear.rateAmount')?.value;
    this.clearRates(this.listOfRatesSecondYear);

    if(this.form.get('sponsoringValue.sponsoringDuration')?.value === 2){
      if (numberOfRates === 1) {
        this.listOfRatesSecondYear.push(this.fb.group({
          date: this.fb.control(null, [Validators.required]),
        }));
      } else {
        for (let i = 0; i < rateControl; i++) {
          this.listOfRatesSecondYear.push(this.formService.createNewRateForm(i));
        }
      }
    }
  }

  onSelectRate(selectedRate: number, formGroup: string): void {
    if(formGroup === 'firstYear') {
      this.form.get('financialAssistance.firstYear.rateAmount')?.setValue(selectedRate);
      this.addRatesForFirstYear(selectedRate)
    }
    if(formGroup === 'secondYear') {
      this.form.get('financialAssistance.secondYear.rateAmount')?.setValue(selectedRate);
      this.addRatesForSecondYear(selectedRate);
    }
    this.formService.setFormData(this.form);
  }

  private clearRates(rateList: FormArray<any>) {
    while (rateList.length !== 0) {
      rateList.removeAt(0);
    }
  }
}
