<ng-container *transloco="let t">
  <div id="dashboard-media">
    <div class="row g-4">
      <div class="col-12 col-md-6 col-lg-3 d-flex">
        <app-media-box [isPdf]="true"  [label]="'Datenschutzhinweise für den Gesponserten öffnen'" [pdfName]="'datenschutzhinweise-gesponserte.pdf'"></app-media-box>
      </div>

      <div class="col-12 col-md-6 col-lg-3 d-flex">
        <app-media-box [isPdf]="true" [label]="'Leitfaden gesetzliche Vorgaben öffnen'" [pdfName]="'leitfaden-gesetzliche-vorgaben.pdf'"></app-media-box>
      </div>

      <div class="col-12 col-md-6 col-lg-3 d-flex">
        <app-media-box [isPdf]="true" [label]="'Richtlinie für Sponsoringmaßnahmen öffnen'" [pdfName]="'richtlinie-sponsoringmaßnahmen.pdf'"></app-media-box>
      </div>

      <div class="col-12 col-md-6 col-lg-3 d-flex">
        <app-media-box [isText]="true" [textContent]="mediaBoxContent"></app-media-box>
      </div>
    </div>
  </div>

  <ng-template #mediaBoxContent>
    <app-icons class="question-icon" [iconColor]="'red'" [iconSize]="'large'" [iconName]="'question-icon'"></app-icons>
    <h5 class="text-center" [innerHTML]="'dashboard.mediaPart.helpTitle' | transloco"></h5>
    <a href="mailto:sponsoring.regional@ergo.de"><p
      class="font-c3 text-center">{{ t('dashboard.mediaPart.helpDescription') }}</p></a>
  </ng-template>
</ng-container>
