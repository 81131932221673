<ng-container id="overview-applicant" *transloco="let t">
  <div class="container container-wizard">
    <h1 class="spacing-title element-space" [innerHTML]="t('overviewPages.applicant.title')"></h1>

    <ng-container *ngIf="dataSource else emptyState">
      <table mat-table id="applicant-table" [dataSource]="dataSource">
        <ng-container matColumnDef="applicant">
          <th mat-header-cell *matHeaderCellDef>
            <div class="header-icon-row">
              <h4>Antragsteller</h4>
              <app-table-sort [columnName]="'applicantName'" (sortDirection)="sortValues($event)"></app-table-sort>
            </div>
          </th>
          <td mat-cell *matCellDef="let element"><p class="font-c2">{{ element.applicant }}</p></td>
        </ng-container>

        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef>
            <div class="header-icon-row">
              <h4>Adresse</h4>
              <app-table-sort [columnName]="'applicantAddress'" (sortDirection)="sortValues($event)"></app-table-sort>
            </div>
          </th>
          <td mat-cell *matCellDef="let element"><p class="font-c2">{{ element.address }}</p></td>
        </ng-container>

        <ng-container matColumnDef="contact">
          <th mat-header-cell *matHeaderCellDef><h4 [innerHTML]="'Telefon<br>E-Mail-Adresse'"></h4></th>
          <td mat-cell *matCellDef="let element"><p class="font-c2" [innerHTML]="element.phone + '</br>' +element.email"></p></td>
        </ng-container>

        <ng-container matColumnDef="sponsoredNumber">
          <th class="small" mat-header-cell *matHeaderCellDef><h4 [innerHTML]="'Anzahl der Sponsoringverträge'"></h4></th>
          <td mat-cell *matCellDef="let element"><p class="font-c2" [innerHTML]="element.application_count"></p></td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openDetailForSponsored(row)"></tr>
      </table>
      <mat-paginator
        [length]="totalApplications"
        [pageSize]="12"
        [pageIndex]="currentPage - 1"
        (page)="onPageChange($event)">
      </mat-paginator>
</ng-container>

  </div>
  <ng-template #emptyState>
    <app-empty-state-general [title]="'Es gibt noch keine Antragsteller'" [description]="'Sobald ein Antragsteller erfasst wird wird dieser hier angzeigt.'" ></app-empty-state-general>
  </ng-template>

</ng-container>
