<ng-container *transloco="let t" id="sponsored-overview" class="sponsored">
  <div class="container container-wizard">

    <app-icon-text-button [buttonSecondary]="true"
                          [iconName]="'arrow-left'"
                          [iconSize]="'small'"
                          [buttonText]="t('common.previous')"
                          [textColor]="'red'"
                          [buttonLeft]="true"
                          (click)="navigateToDashboard()"
    ></app-icon-text-button>

    <h1 class="spacing-title" [innerHTML]="t('overviewPages.applicant.sponsored.title')"></h1>

    <ng-container *ngIf="dataSource else emptyState">
      <div class="content">
        <div *ngIf="isLoading" class="spinner-container"><mat-spinner></mat-spinner></div>


        <table *ngIf="!isLoading" mat-table id="applicant-sponsored-table" [dataSource]="dataSource">
          <ng-container matColumnDef="name">
            <th class="large" mat-header-cell *matHeaderCellDef>
              <div class="header-icon-row">
                <h4>Gesponserte</h4>
                <app-table-sort [columnName]="'sponsoredName'" (sortDirection)="sortValues($event)"></app-table-sort>
              </div>
            </th>
            <td mat-cell *matCellDef="let element"><p class="font-c2">{{ element.name }}</p></td>
          </ng-container>
          <ng-container matColumnDef="address">
            <th class="large" mat-header-cell *matHeaderCellDef>
              <div class="header-icon-row">
                <h4>Adresse</h4>
                <app-table-sort [columnName]="'sponsoredAddress'" (sortDirection)="sortValues($event)"></app-table-sort>
              </div>
            </th>
            <td mat-cell *matCellDef="let element"><p class="font-c2">{{ element.address }}</p></td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openDetailForSponsored(row)"></tr>
        </table>
        <mat-paginator
          [length]="totalApplications"
          [pageSize]="12"
          [pageIndex]="currentPage - 1"
          (page)="onPageChange($event)">
        </mat-paginator>
      </div>
    </ng-container>

    <ng-template #emptyState>
      <app-empty-state-general [title]="'Es gibt noch keine Gesponserte'" [description]="'Sobald ein Gesponserte erstellt wird wird dieser hier angzeigt.'" ></app-empty-state-general>
    </ng-template>

  </div>
</ng-container>
