<ng-container id="overview-applications" *transloco="let t">
  <div class="container container-wizard">
    <h1 class="spacing-title element-space" [innerHTML]="t('overviewPages.applicant.applications.title')"></h1>

    <ng-container *ngIf="dataSource else emptyState">
      <table mat-table id="applications-overview-table" [dataSource]="dataSource">
        <ng-container matColumnDef="applicant">
          <th mat-header-cell *matHeaderCellDef>
            <div class="header-icon-row">
              <h4>Gesponserte</h4>
              <app-table-sort [columnName]="'sponsoredName'" (sortDirection)="sortValues($event)"></app-table-sort>
            </div>
          </th>
          <td mat-cell *matCellDef="let element">
            <p class="font-c2">{{ element.name }}</p>
          </td>
        </ng-container>

        <ng-container matColumnDef="application">
          <th mat-header-cell *matHeaderCellDef>
            <div class="header-icon-row">
              <h4>Vertriebspartner</h4>
              <app-table-sort [columnName]="'applicantName'" (sortDirection)="sortValues($event)"></app-table-sort>
            </div>
          </th>
          <td mat-cell *matCellDef="let element"><p class="font-c2">{{ element.applicant }}</p></td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef>
            <div class="header-icon-row">
              <h4>Datum des Einreichens</h4>
              <app-table-sort [columnName]="'creationDate'" (sortDirection)="sortValues($event)"></app-table-sort>
            </div>
          </th>
          <td mat-cell *matCellDef="let element"><p class="font-c2">{{ element.created_at | date: 'dd.MM.YYYY' }}</p></td>
        </ng-container>

        <ng-container matColumnDef="state">
          <th mat-header-cell *matHeaderCellDef><h4>Status</h4></th>
          <td mat-cell *matCellDef="let element">
            <p class="font-c2">
              <app-application-state-component class="state"
                                               [state]="element.status"></app-application-state-component>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="showApplication(row)"></tr>
      </table>
      <mat-paginator
        [length]="totalApplications"
        [pageSize]="8"
        [pageIndex]="currentPage - 1"
        (page)="onPageChange($event)">
      </mat-paginator>
    </ng-container>

    <ng-template #emptyState>
      <app-empty-state-general [title]="'Es gibt noch keine Anträge'" [description]="'Sobald ein Antrag erstellt wird wird dieser hier angzeigt.'" ></app-empty-state-general>
    </ng-template>

  </div>
</ng-container>
