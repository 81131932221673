<ng-container *transloco="let t">
  <div id="wizard-header" class="row wizard-header">
    <div class="container">
      <ng-container *ngIf="currentComponent === 'sponsoring-value' else defaultHeader">

        <div class="element-space">
          <app-icon-text-button [buttonSecondary]="true"
                                [iconName]="'arrow-left'"
                                [iconSize]="'small'"
                                [buttonText]="t('common.previous')"
                                [textColor]="'red'"
                                [buttonLeft]="true"
                                (click)="this.wizardService.navigateToPreviousPage()"
          ></app-icon-text-button>
        </div>

        <app-hint [hintDescription]="t('wizard.sponsoringValue.hints.download')"></app-hint>
        <app-hint [hintDescription]="t('wizard.sponsoringValue.hints.required')"></app-hint>

        <p class="font-c1 text-center"
           [innerHTML]="t('wizard.stepper', { current: this.wizardService.currentPage, all: this.wizardService.allPages})"></p>
        <p *ngIf="descriptionOrganisation !== ''" class="font-c1 text-center" [innerHTML]="descriptionOrganisation"></p>

        <h1 class="text-center section-space-default" [innerHTML]="title"></h1>
        <h3 class="text-center" [innerHTML]="subtitle"></h3>
        <p *ngIf="viewMode" class="text-center" [innerHTML]="status"></p>

      </ng-container>
    </div>
  </div>

  <ng-template #defaultHeader>
    <div class="section-space-default">
      <app-icon-text-button [buttonSecondary]="true"
                            [iconName]="'arrow-left'"
                            [iconSize]="'small'"
                            [buttonText]="t('common.previous')"
                            [textColor]="'red'"
                            [buttonLeft]="true"
                            (click)="viewMode ? this.navigateToDashboard() : this.wizardService.navigateToPreviousPage()"
      ></app-icon-text-button>
    </div>
    <p *ngIf="!viewMode" class="font-c1 text-center" [innerHTML]="
        t('wizard.stepper', {
          current: this.wizardService.currentPage,
          all: this.wizardService.allPages
        })"></p>
    <p *ngIf="descriptionOrganisation !== ''" class="font-c1 text-center" [innerHTML]="descriptionOrganisation"></p>
    <h1 class="text-center" [innerHTML]="title"></h1>
    <h3 class="text-center" [innerHTML]="subtitle"></h3>
    <div class="text-center state" *ngIf="viewMode">
      <p class="lead">Status:</p>
      <app-application-state-component [state]="status"></app-application-state-component>
    </div>

  </ng-template>
</ng-container>
