import {Injectable} from '@angular/core';
import {applicationStateEnum} from "../../../api/enum/stateEnum";
import {DatePipe} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class StateTextService {
  constructor(private datePipe: DatePipe) {
  }

  returnTextForApplicant(state: string, date?: string, parameter?: string, reduced?: boolean): {
    button: Array<{ text: string, disabled: boolean, clickEvent?: string, primary: boolean }>,
    title: string,
    content: string
  } {
    if (state === applicationStateEnum.OPEN) {
      return {
        button: [{text: "Weiter im Prozess", disabled: true, clickEvent: 'switchToEditMode', primary: true}],
        title: "Ihr Antrag wird derzeit von einem ERGO Sponsoring-Manager geprüft<br><br>",
        content: "Sie erhalten eine entsprechende Benachrichtigung über das Prüfergebnis. Falls Anpassungen erforderlich sind, wird der Antrag zur Korrektur zurückgegeben, und Sie können ihn erneut bearbeiten. <br><br>Wenn der untenstehende Button aktiv ist, können Sie mit dem nächsten Prozessschritte fortfahren."
      };
    }
    if (state === applicationStateEnum.CORRECTION_NEEDED) {
      return {
        button: [{text: "Weiter im Prozess", disabled: false, clickEvent: 'switchToEditMode', primary: true}],
        title: "Eine Korrektur ist erforderlich<br><br>",
        content: "Bitte korrigieren Sie die markierten Bereiche. Sie erkennen die zu korrigierenden Bereiche anhand der orangefarbenen Umrandung. Zusätzlich hat ihr ERGO Sponsoring-Manager einen Kommentar für Sie hinterlassen, welche Anpassungen Sie vornehmen müssen. </br> </br>Klicken Sie auf den Button “Weiter im Prozess” um den Antrag zu korrigieren."
      };
    }
    if (state === applicationStateEnum.CORRECTION_CHECK) {
      return {
        button: [{text: "Weiter im Prozess", disabled: true, clickEvent: '', primary: true}],
        title: "Ihr Antrag wird derzeit von einem ERGO Sponsoring-Manager geprüft",
        content: "Sie erhalten eine entsprechende Benachrichtigung über das Prüfergebnis. Falls Anpassungen erforderlich sind, wird der Antrag zur Korrektur zurückgegeben, und Sie können ihn erneut bearbeiten.Wenn der untenstehende Button aktiv ist, können Sie mit dem nächsten Prozessschritte fortfahren."
      }
    }
    if (state === applicationStateEnum.CHECK_RD) {
      return {
        button: [{text: "Weiter im Prozess", disabled: true, primary: true, clickEvent: ''}],
        title: "Ihr Antrag wird derzeit vom RD-Leiter geprüft<br><br>",
        content: "Der ERGO Sponsoring-Manager hat Ihre Daten akzeptiert und Ihren Antrag an den zuständigen RD-Leiter weitergereicht. Ihre RD muss nun das Sponsoring empfehlen.<br><br>Wenn der untenstehende Button aktiv ist, können Sie mit dem nächsten Prozessschritte fortfahren."
      };
    }
    if (state === applicationStateEnum.APPROVED_RD_LEITER) {
      return {
        button: [{text: "Weiter im Prozess", disabled: true, clickEvent: '', primary: true}],
        title: "Ihr Antrag wird derzeit von einem ERGO Sponsoring-Manager geprüft<br><br>",
        content: "Ihre RD hat die Sponsoring-Maßnahme empfohlen. Ihr ERGO Sponsoring-Manager muss nun über die Genehmigung oder Ablehnung der Sponsoring-Maßnahme entscheiden. <br><br>Wenn der untenstehende Button aktiv ist, können Sie mit dem nächsten Prozessschritte fortfahren."
      };
    }
    if (state === applicationStateEnum.CHECK_GLVKF) {
      return {
        button: [{text: "Weiter im Prozess", disabled: true, clickEvent: '', primary: true}],
        title: "Ihr Antrag wird derzeit vom GLVKF geprüft<br><br>",
        content: "Ihre RD hat die Sponsoring-Maßnahme empfohlen. Der GLVKF muss nun über die Genehmigung oder Ablehnung der Sponsoring-Maßnahme entscheiden.<br><br>Wenn der untenstehende Button aktiv ist, können Sie mit dem nächsten Prozessschritte fortfahren."
      };
    }
    if (state === applicationStateEnum.APPROVED_GLVKF) {
      return {
        button: [{text: "Weiter im Prozess", disabled: true, clickEvent: '', primary: true}],
        title: "Ihr Antrag wird derzeit von einem ERGO Sponsoring-Manager geprüft<br><br>",
        content: "Ihre RD hat die Sponsoring-Maßnahme empfohlen. Der GLVKF muss nun über die Genehmigung oder Ablehnung der Sponsoring-Maßnahme entscheiden.<br><br>Wenn der untenstehende Button aktiv ist, können Sie mit dem nächsten Prozessschritte fortfahren."
      };
    }
    if (state === applicationStateEnum.APPROVED_ADD_CONTRACT) {
      return {
        button: [{text: "Weiter im Prozess", disabled: false, clickEvent: 'openAddContract', primary: true}],
        title: "Vertrag einreichen<br>",
        content: "Ihr Sponsoring-Antrag wurde genehmigt.<br>Um die Genehmigung abzuschließen sind noch weitere Schritte erforderlich.<br><br>Klicken Sie auf den Button “Weiter im Prozess” um den Vertrag einzureichen."
      }
    }
    if (state === applicationStateEnum.CONTRACT_ADDED) {
      return {
        button: [{text: "Weiter im Prozess", disabled: true, clickEvent: '', primary: true}],
        title: "Ihr Vertrag wird derzeit von einem ERGO Sponsoring-Manager geprüft<br><br>",
        content: "Ein ERGO Sponsoring-Manager wird den eingereichten Vertrag auf Richtigkeit und Vollständigkeit prüfen. Wenn eine Korrektur erforderlich sein sollte, werden Sie darüber benachrichtigt.<br>Nach erfolgreicher Überprüfung können Sie Dateien für den Zuschussprozess einreichen. <br><br>Wenn der untenstehende Button aktiv ist, können Sie mit dem nächsten Prozessschritte fortfahren."
      }
    }
    if (state === applicationStateEnum.CONTRACT_CORRECTIONS) {
      return {
        button: [{text: "Weiter im Prozess", disabled: false, clickEvent: 'openAddContract', primary: true}],
        title: "Eine Korrektur ist erforderlich<br><br>",
        content: "Ihr eingereichter Vertrag ist fehlerhaft. Bitte reichen Sie den Vertrag erneut ein. Ihr ERGO Sponsoring-Manager hat einen Kommentar für Sie hinterlassen, welche Anpassungen Sie vornehmen müssen.<br> <br>Klicken Sie auf den Button “Weiter im Prozess” um den Vertrag zu korrigieren."
      }
    }
    if (state === applicationStateEnum.APPROVED_NO_ZUSCHUSS) {
      return {
        button: [],
        title: reduced ? "Ihr Zuschuss wurde vom ERGO Sponsoring-Manager abgelehnt - mit Kürzung<br><br>":  "Ihr Zuschuss wurde vom ERGO Sponsoring-Manager abgelehnt<br><br>",
        content: "Ihr ERGO Sponsoring-Manager hat Ihre Zuschuss-Dokumente fachlich geprüft und konnte keinen Zuschuss bewilligen. <br><br>Der Prozess ist hiermit abgeschlossen."
      }
    }
    if (state === applicationStateEnum.REJECTED_VKF) {
      return {
        button: [],
        title: "Ihre Sponsoring-Maßnahme wurde vom ERGO Sponsoring-Manager abgelehnt<br><br>",
        content: "Sie dürfen das Sponsoring nicht durchführen. Der Prozess ist hiermit abgeschlossen."
      }
    }
    if (state === applicationStateEnum.REJECTED_RD_LEITER) {
      return {
        button: [],
        title: "Ihre Sponsoring-Maßnahme wurde von Ihrem RD-Leiter nicht empfohlen<br><br>",
        content: "Aufgrund dessen müssen wir Ihre Sponsoring-Maßnahme ablehnen.<br><br>Sie dürfen das Sponsoring nicht durchführen. Der Prozess ist hiermit abgeschlossen."
      }
    }
    if (state === applicationStateEnum.REJECTED_GLVKF) {
      return {
        button: [],
        title: "Ihre Sponsoring-Maßnahme wurde vom ERGO Sponsoring-Manager abgelehnt<br><br>",
        content: "Sie dürfen das Sponsoring nicht durchführen. Der Prozess ist hiermit abgeschlossen."
      }
    }
    if (state === applicationStateEnum.ZUSCHUSS) {
      return {
        button: [{text: "Weiter im Prozess", disabled: false, clickEvent: 'openZuschussDocuments', primary: true}],
        title: "Zuschuss-Dokumente hochladen<br><br>",
        content: "Ihr unterschriebener Sponsoring-Vertrag wurde geprüft und akzeptiert.<br> Da Sie einen Zuschuss beantragt haben, können Sie nun mit dem Hochladen der erforderlichen Nachweise und Belege beginnen.<br><br>Klicken Sie auf den Button “Weiter im Prozess” um Zuschuss-Dokumente hochzuladen."
      }
    }
    if (state === applicationStateEnum.ZUSCHUSS_UPLOADED) {
      return {
        button: [{text: "Weitere hinzufügen", disabled: false, clickEvent: 'openZuschussDocuments', primary: true}],
        title: "Sie haben Zuschuss-Dokumente vergessen hochzuladen? Kein Problem!<br><br>",
        content: "Klicken Sie auf den Button “Weitere hinzufügen” um weitere Zuschuss-Dokumente zur Prüfung hinzuzufügen."
      }
    }
    if (state === applicationStateEnum.ZUSCHUSS_CORRECTIONS) {
      return {
        button: [{text: "Weiter im Prozess", disabled: false, clickEvent: 'openZuschussDocuments', primary: true}],
        title: "Eine Korrektur ist erforderlich<br><br>",
        content: "Ihr ERGO Sponsoring-Manager hat einen Kommentar für Sie hinterlassen, welche Anpassungen Sie vornehmen müssen.<br><br>Klicken Sie auf den Button “Weiter im Prozess” um die Anpassungen vorzunehmen."
      }
    }
    if (state === applicationStateEnum.ZUSCHUSS_CORRECTIONSSECOND) {
      return {
        button: [{text: "Weitere hinzufügen", disabled: false, clickEvent: 'switchToEditMode', primary: true}],
        title: "Eine Korrektur ist erforderlich<br><br>",
        content: "Ihr ERGO Sponsoring-Manager hat einen Kommentar für Sie hinterlassen, welche Anpassungen Sie vornehmen müssen.<br><br>Klicken Sie auf den Button “Weiter im Prozess” um die Anpassungen vorzunehmen."
      }
    }
    if(state === applicationStateEnum.DONE_WITH_FUNDING){
      if(reduced){
        return {
          button: [],
          title: "Ihr Zuschuss wurde freigegeben - mit Kürzung<br><br>",
          content: `Ihr ERGO Sponsoring-Manager hat Ihre Zuschuss-Dokumente fachlich geprüft. Folgender Zuschussbetrag wurde für Sie bewilligt und freigegeben:<br><br><h3 class="h3">${parameter} € brutto</h3><br>Der Zuschussbetrag wird über die nächstmögliche Vermittlerabrechnung gutgeschrieben. Der Prozess ist hiermit abgeschlossen.`
        }
      } else {
        return {
          button: [],
          title: "Ihr Zuschuss wurde freigegeben<br><br>",
          content: `Ihr ERGO Sponsoring-Manager hat Ihre Zuschuss-Dokumente fachlich geprüft. Folgender Zuschussbetrag wurde für Sie bewilligt und freigegeben:<br><br><h3 class="h3">${parameter} € brutto</h3><br>Der Zuschussbetrag wird über die nächstmögliche Vermittlerabrechnung gutgeschrieben. Der Prozess ist hiermit abgeschlossen.`
        }
      }
    }
    return {button: [], title: '', content: ''}
  };

  returnTextForVkf(state: string, date?: string, parameter?: string, reduced?: boolean): {
    button: Array<{ text: string, disabled: boolean }>,
    title: string,
    content: string
  } {
    switch (state) {
      case applicationStateEnum.OPEN:
        return {
          button: [{text: "Daten prüfen", disabled: false}],
          title: "Neuer Sponsoring-Antrag eingegangen <br><br>",
          content: `Bitte prüfen Sie die Daten des neu eingegangenen Antrags vom ${this.datePipe.transform(date, 'dd.MM.yyyy')}.</br></br> Klicken Sie auf den Button "Daten prüfen".`
        }
      case applicationStateEnum.ZUSCHUSS_APPROVED_GLVKF:
        return {
          button: [{text: "Zuschuss freigeben", disabled: false}],
          title: "Eine Entscheidung des GLVKF liegt vor<br><br>",
          content: "Der GLVKF gibt einen Zuschuss frei. Bitte geben Sie den Zuschuss frei, indem Sie auf den Button “Zuschuss freigeben” klicken."
        }
      case applicationStateEnum.OD_CHECK_ZUSCHUSS:
        return {
          button: [{text: "Weiter im Prozess", disabled: true}],
          title: "Der Zuschuss wird derzeit vom Vorprüfer OD geprüft",
          content: "Nachdem der Vorprüfer OD eine Empfehlung ausgesprochen hat wird die Angelegenheit automatisch an den OD-Leiter übergeben. Nach der Entscheidung des OD-Leiters können Sie mit der Bearbeitung fortfahren. Wenn der untenstehende Button aktiv ist, können Sie mit dem nächsten Prozessschritte fortfahren."
        }
      case applicationStateEnum.DONE_WITH_FUNDING:
        return {
          button: [],
          title: reduced ? "Ein Zuschuss wurde freigegeben - mit Kürzung<br><br>": "Ein Zuschuss wurde freigegeben<br><br>",
          content: `Folgenden Zuschussbetrag haben Sie bewilligt und freigegeben:<br><br><h3 class="h3">${parameter} € brutto</h3><br>Es gibt nichts weiter für Sie zu tun. Der Prozess ist hiermit abgeschlossen.`
        }
      case applicationStateEnum.DONE_WITH_FUNDING_REDUCED:
        return {
          button: [],
          title: "Ein Zuschuss wurde freigegeben - mit Kürzung<br><br>",
          content: `Folgenden Zuschussbetrag haben Sie bewilligt und freigegeben:<br><br><h3 class="h3">${parameter} € brutto</h3><br>Es gibt nichts weiter für Sie zu tun. Der Prozess ist hiermit abgeschlossen.`
        }
      case applicationStateEnum.CORRECTION_VP:
        return {
          button: [{text: "Weiter im Prozess", disabled: true}],
          title: "Der Antrag wird derzeit vom Antragsteller korrigiert",
          content: "Sie erhalten eine entsprechende Benachrichtigung wenn Sie die Daten erneut prüfen können. <br> <br>Wenn der untenstehende Button aktiv ist, können Sie mit dem nächsten Prozessschritte fortfahren."
        };
      case applicationStateEnum.CORRECTION_NEEDED:
        return {
          button: [{text: "Weiter im Prozess", disabled: true}],
          title: "Der Antrag wird derzeit vom Antragsteller korrigiert",
          content: "Sie erhalten eine entsprechende Benachrichtigung wenn Sie die Daten erneut prüfen können. <br> <br>Wenn der untenstehende Button aktiv ist, können Sie mit dem nächsten Prozessschritte fortfahren."
        };
      case applicationStateEnum.CORRECTION_CHECK:
        return {
          button: [{text: "Daten prüfen", disabled: false}],
          title: "Der Antrag ist zur Korrektur bereit",
          content: "Bitte prüfen Sie die Daten. <br><br>Klicken Sie auf den Button 'Daten prüfen'."
        };
      case applicationStateEnum.CHECK_RD:
        return {
          button: [{text: "Weiter im Prozess", disabled: true}],
          title: 'Der Antrag wird derzeit vom RD-Leiter geprüft',
          content: 'Sie erhalten eine entsprechende Benachrichtigung wenn der RD-Leiter eine Empfehlung ausgesprochen hat.<br><br>Wenn der untenstehende Button aktiv ist, können Sie mit dem nächsten Prozessschritte fortfahren.'
        };
      case applicationStateEnum.APPROVED_RD_LEITER:
        return {
          button: [{text: "Antrag ablehnen", disabled: false}, {text: "Antrag genehmigen", disabled: false}],
          title: 'Eine Empfehlung der RD liegt vor',
          content: 'Die RD hat die Sponsoring-Maßnahme empfohlen. Bitte prüfen Sie die Empfehlung und entscheiden Sie über die Genehmigung oder Ablehnung der Sponsoring-Maßnahme.<br><br>Wenn Sie auf einen der Buttons klicken, wird die Aktion direkt ausgeführt.'
        };
      case applicationStateEnum.APPROVAL_NO_CONTRACT:
        return {
          button: [{text: "Weiter im Prozess", disabled: true}],
          title: 'Warten auf Einreichung des Vertrags<br>',
          content: "Sie erhalten eine entsprechende Benachrichtigung wenn der Antragsteller den Vertrag hochgeladen hat- <br><br>Wenn der untenstehende Button aktiv ist, können Sie mit dem nächsten Prozessschritte fortfahren."
        };
      case applicationStateEnum.CONTRACT_SIGNED:
        return {
          button: [{text: "Weiter im Prozess", disabled: false}],
          title: "Der Vertrag ist zur Überprüfung bereit<br>",
          content: "Bitte prüfen Sie den eingereichten Vertrag auf Richtigkeit und Vollständigkeit.<br><br>Klicken Sie auf den Button “Weiter im Prozess”."
        };
      case applicationStateEnum.SPONSORING_APPROVED_VKF:
        return {
          button: [{text: "Weiter im Prozess", disabled: true}],
          title: "NDer Antrag wird derzeit vom RD-Leiter geprüft",
          content: "Sie erhalten eine entsprechende Benachrichtigung wenn der RD-Leiter eine Empfehlung ausgesprochen hat.<br><br>Wenn der untenstehende Button aktiv ist, können Sie mit dem nächsten Prozessschritte fortfahren."
        };
      case applicationStateEnum.APPROVED_GLVKF:
        return {
          button: [{text: "Antrag genehmigen", disabled: false}],
          title: "Eine Entscheidung vom GLVKF liegt vor<br>",
          content: "Der GLVKF hat die Sponsoring-Maßnahme genehmigt. <br>Bitte führen Sie die Genehmigung der Sponsoring-Maßnahme aus.<br><br>Klicken Sie auf den Button “Antrag genehmigen”."
        };
      case applicationStateEnum.REJECTED_GLVKF:
        return {
          button: [{text: "Antrag ablehnen", disabled: false}],
          title: "Die Sponsoring-Maßnahme wurde vom GLVKF abgelehnt",
          content: "Aufgrund dessen müssen Sie Sponsoring-Maßnahme ablehnen.\n Wenn Sie auf den Button “Antrag ablehnen” klicken, wird die Sponsoring-Maßnahme direkt abgelehnt."
        };
      case applicationStateEnum.NEU:
        return {
          button: [{text: "Daten prüfen", disabled: false}],
          title: "Neuer Sponsoring-Antrag eingegangen",
          content: `Bitte prüfen Sie die Daten des neu eingegangenen Antrags vom ${this.datePipe.transform(date, 'dd.MM.yyyy')}.</br></br> Klicken Sie auf den Button 'Daten prüfen'.`
        };
      case applicationStateEnum.CHECK_GLVKF:
        return {
          button: [{text: "Weiter im Prozess", disabled: true}],
          title: "Der Antrag wird derzeit vom GLVKF geprüft",
          content: "Wenn der untenstehende Button aktiv ist, können Sie mit dem nächsten Prozessschritte fortfahren."
        };
      case applicationStateEnum.CHECK_DONE_VKF:
        return {
          button: [{text: "Weiter im Prozess", disabled: true}],
          title: 'Warten auf Einreichung des Vertrags',
          content: 'Sie erhalten eine entsprechende Benachrichtigung wenn der Antragsteller den Vertrag hochgeladen hatt.Wenn der untenstehende Button aktiv ist, können Sie mit dem nächsten Prozessschritte fortfahren.'
        };

      case applicationStateEnum.CONTRACT_CHECK:
        return {
          button: [{text: "Weiter im Prozess", disabled: false}],
          title: "Der Vertrag ist zur Überprüfung bereit",
          content: "Bitte prüfen Sie den eingereichten Vertrag auf Richtigkeit und Vollständigkeit.Klicken Sie auf den Button “Weiter im Prozess”."
        };

      case applicationStateEnum.ZUSCHUSS:
        return {
          button: [{text: "Weiter im Prozess", disabled: true}],
          title: "Der Antragsteller lädt derzeit Zuschuss-Dokumente hoch",
          content: "Sie erhalten eine entsprechende Benachrichtigung wenn Zuschuss-Dokumente vorliegen und Sie diese prüfen können.<br><br>Wenn der untenstehende Button aktiv ist, können Sie mit dem nächsten Prozessschritte fortfahren."
        };
      case applicationStateEnum.ZUSCHUSS_UPLOADED:
        return {
          button: [{text: "Weiter im Prozess", disabled: false}],
          title: "Es liegen neue Zuschuss-Dokumente zur Prüfung breit<br>",
          content: "Bitte prüfen Sie die vom Antragsteller hochgeladenen Zuschuss-Dokumente auf Richtigkeit und Vollständigkeit.<br><br>Klicken Sie auf den Button “Weiter im Prozess”."
        };
      case applicationStateEnum.ZUSCHUSS_CORRECTIONS:
        return {
          button: [{text: "Weiter im Prozess", disabled: true}],
          title: "Die Zuschuss-Dokumente werden derzeit vom Antragsteller korrigiert<br>",
          content: "Sie erhalten eine entsprechende Benachrichtigung wenn Sie die Zuschuss-Dokumente erneut prüfen können.<br><br>Wenn der untenstehende Button aktiv ist, können Sie mit dem nächsten Prozessschritte fortfahren."
        };
      case applicationStateEnum.APPROVED_NO_ZUSCHUSS:
        return {
          button: [],
          title: reduced ? "Ein Zuschuss wurde abgelehnt - mit Kürzung<br>" : "Ein Zuschuss wurde abgelehnt<br>",
          content: "Sie konnten nach fachlicher Prüfung keinen Zuschuss bewilligen.<br><br>Es gibt nichts weiter für Sie zu tun. Der Prozess ist hiermit abgeschlossen."
        };
      case applicationStateEnum.IN_WORK_ZUSCHUSS:
        return {
          button: [{text: "Weiter im Prozess", disabled: true}],
          title: "Der Zuschuss wird derzeit vom GLVKF geprüft",
          content: "Wenn der untenstehende Button aktiv ist, können Sie mit dem nächsten Prozessschritte fortfahren."
        };
      case applicationStateEnum.CONTRACT_CORRECTIONS:
        return {
          button: [{text: "Weiter im Prozess", disabled: true}],
          title: "Die Vertrags-Dokumente werden derzeit vom Antragsteller korrigiert<br>",
          content: "Sie erhalten eine entsprechende Benachrichtigung wenn Sie die Vertrags-Dokumente erneut prüfen können. <br><br>Wenn der untenstehende Button aktiv ist, können Sie mit dem nächsten Prozessschritte fortfahren."
        };
      case applicationStateEnum.APPROVED_OD:
        return {
          button: [{text: "Weiter im Prozess", disabled: true}],
          title: "Der Zuschuss wird derzeit vom OD-Leiter geprüft<br>",
          content: "Nach der Entscheidung des OD-Leiters können Sie mit der Bearbeitung fortfahren.<br><br>Wenn der untenstehende Button aktiv ist, können Sie mit dem nächsten Prozessschritte fortfahren."
        };
      case applicationStateEnum.APPROVED_OD_LEAD:
        return {
          button: [{text: "Weitergabe an GLVKF", disabled: false}],
          title: "Eine Entscheidung des OD-Leiters liegt vor<br>",
          content: "Der OD-Leiter gibt einen Zuschuss frei. Bitte übergeben Sie die Angelegenheit an den GLVKF zur finalen Überprüfung, indem Sie den Butten “Weitergabe an GLVKF” klicken."
        };
      case applicationStateEnum.DONE:
        return {
          button: [],
          title: "Die Sponsoring-Maßnahme wurde vertraglich genehmigt",
          content: "Es gibt nichts weiter für Sie zu tun. Der Prozess ist hiermit abgeschlossen."
        };
      case applicationStateEnum.REJECTED_VKF:
        return {
          button: [],
          title: "Sie haben die Sponsoring-Maßnahme abgelehnt",
          content: "Es gibt nichts weiter für Sie zu tun. Der Prozess ist hiermit abgeschlossen."
        };
      case applicationStateEnum.REJECTED_RD:
        return {
          button: [{text: "Antrag ablehnen", disabled: false}],
          title: "Die Sponsoring-Maßnahme wurde vom RD-Leiter nicht empfohlen",
          content: "Aufgrund dessen müssen Sie Sponsoring-Maßnahme ablehnen. Wenn Sie auf den Button “Antrag ablehnen” klicken, wird die Sponsoring-Maßnahme direkt abgelehnt."
        };
      default:
        return {button: [], title: '', content: ''};
    }
  }

  returnTextForRDLeiter(state: string, date?: string): {
    button: Array<{ text: string, disabled: boolean }>,
    title: string,
    content: string
  } {
    if (state === applicationStateEnum.CHECK_RD || state === applicationStateEnum.SPONSORING_APPROVED_VKF) {
      return {
        button: [],
        title: "Empfehlung für oder gegen diese Sponsoring-Maßnahme<br>",
        content: "Bitte prüfen Sie anhand des Antrags und des Vertragsentwurfs, ob das Sponsoring aus Ihrer Sicht zu empfehlen ist.<br><br>Geben Sie durch den Klick auf einen Button Ihre Empfehlung ab. Wichtig: Sie können diese Empfehlung über das System nicht mehr ändern."
      };
    }
    if (state === applicationStateEnum.APPROVED_RD_LEITER) {
      return {
        button: [],
        title: "Sie empfehlen diese Sponsoring-Maßnahme",
        content: "Ihre Empfehlung wird nun vom System automatisch an einen ERGO Sponsoring-Manager weitergeleitet. Es gibt nichts weiter für Sie zu tun."
      }
    }
    if (state === applicationStateEnum.REJECTED_RD_LEITER) {
      return {
        button: [],
        title: "Sie empfehlen diese Sponsoring-Maßnahme nicht",
        content: "Ihre Begründung wird nun vom System automatisch an einen ERGO Sponsoring-Manager weitergeleitet. Es gibt nichts weiter für Sie zu tun."
      }
    }

    if (state === applicationStateEnum.APPROVED_GLVKF) {
      return {
        button: [],
        title: "Eine Entscheidung vom GLVKF liegt vor<br><br>",
        content: "Die Begründung wird nun vom System automatisch an einen ERGO Sponsoring-Manager weitergeleitet. Es gibt nichts weiter für Sie zu tun."
      }
    }
    return {button: [], title: '', content: ''}
  }

  returnTextForGLVKF(state: string, date?: string): {
    button: Array<{ text: string, disabled: boolean }>,
    title: string,
    content: string
  } {
    if (state === applicationStateEnum.CHECK_RD || state === applicationStateEnum.CHECK_DONE_VKF) {
      return {
        button: [],
        title: "Der Antrag ist zur Korrektur bereit",
        content: "Bitte prüfen Sie die Daten. <br><br>Unter den Antragsdaten finden Sie zwei Buttons. Geben Sie durch Klick auf einen der Buttons Ihre Entscheidung ab. <br><br>Wichtig: Sie können diese Entscheidung über das System nicht mehr ändern."
      };
    }
    if (state === applicationStateEnum.IN_WORK_ZUSCHUSS) {
      return {
        button: [],
        title: "Prüfung über eine Freigabe des Zuschusses<br><br>",
        content: "Bitte prüfen Sie die Sponsoring-Maßnahme sowie alle Zuschuss-Dokumente und entscheiden Sie für oder gegen die Freigabe des Zuschusses.<br><br>Der OD-Leiter hat eine Empfehlung für die Freigabe des Zuschusses gegeben. <br><br>Unter den Antragsdaten finden Sie zwei Buttons. Geben Sie durch Klick auf einen der Buttons Ihre Entscheidung ab. <br><br>Wichtig: Sie können diese Entscheidung über das System nicht mehr ändern."
      };
    }
    if (state === applicationStateEnum.CHECK_GLVKF) {
      return {
        button: [],
        title: "Der Antrag ist zur Korrektur bereit",
        content: "Bitte prüfen Sie die Daten. <br><br>Unter den Antragsdaten finden Sie zwei Buttons. Geben Sie durch Klick auf einen der Buttons Ihre Entscheidung ab. <br><br>Wichtig: Sie können diese Entscheidung über das System nicht mehr ändern."
      };
    }
    if (state === applicationStateEnum.APPROVED_GLVKF) {
      return {
        button: [],
        title: "Sie haben die Sponsoring-Maßnahme genehmigt",
        content: "Ihre Entscheidung wird nun vom System automatisch an einen ERGO Sponsoring-Manager weitergeleitet. Es gibt nichts weiter für Sie zu tun."
      }
    }
    if (state === applicationStateEnum.REJECTED_GLVKF) {
      return {
        button: [],
        title: "Sie haben die Sponsoring-Maßnahme abgelehnt",
        content: "Ihre Entscheidung wird nun vom System automatisch an einen ERGO Sponsoring-Manager weitergeleitet. Es gibt nichts weiter für Sie zu tun."
      }
    }
    if (state === applicationStateEnum.ZUSCHUSS_APPROVED_GLVKF) {
      return {
        button: [],
        title: "Sie haben für die Freigabe des Zuschusses entschieden",
        content: "Ihre Empfehlung wird nun vom System automatisch an einen ERGO Sponsoring-Manager weitergeleitet. Es gibt nichts weiter für Sie zu tun."
      }
    }
    return {button: [], title: '', content: ''}
  }

  returnTextForOD(state: string, date?: string): {
    button: Array<{ text: string, disabled: boolean }>,
    title: string,
    content: string
  } {
    if (state === applicationStateEnum.OD_CHECK_ZUSCHUSS) {
      return {
        button: [],
        title: "Empfehlung für oder gegen eine Freigabe des Zuschusses ",
        content: "Bitte prüfen Sie die Sponsoring-Maßnahme sowie alle Zuschuss-Dokumente und geben Sie eine Empfehlung für oder gegen die Freigabe des Zuschusses ab. <br><br>Unter den Antragsdaten finden Sie zwei Buttons. Geben Sie durch Klick auf einen der Buttons Ihre Empfehlung ab.<br><br>Wichtig: Sie können diese Empfehlung über das System nicht mehr ändern."
      }
    }
    if (state === applicationStateEnum.APPROVED_OD) {
      return {
        button: [],
        title: "Sie empfehlen die Freigabe des Zuschusses<br><br>",
        content: "Ihre Empfehlung wird nun vom System automatisch an den OD-Leiter weitergeleitet. Es gibt nichts weiter für Sie zu tun."
      }
    }
    if (state === applicationStateEnum.REJECTED_OD) {
      return {
        button: [],
        title: "Sie empfehlen die Freigabe des Zuschusses nicht<br><br>",
        content: "Ihre Empfehlung wird nun vom System automatisch an den OD-Leiter weitergeleitet. Es gibt nichts weiter für Sie zu tun."
      }
    }
    return {button: [], title: '', content: ''}
  }

  returnTextForODLead(state: string, date?: string): {
    button: Array<{ text: string, disabled: boolean }>,
    title: string,
    content: string
  } {
    if (state === applicationStateEnum.APPROVED_OD) {
      return {
        button: [],
        title: "Prüfung über eine Freigabe des Zuschusses<br><br>",
        content:"Bitte prüfen Sie die Sponsoring-Maßnahme sowie alle Zuschuss-Dokumente und entscheiden Sie für oder gegen die Freigabe des Zuschusses.<br><br>Der Vorprüfer OD hat eine Empfehlung für die Freigabe des Zuschusses gegeben.<br>Unter den Antragsdaten finden Sie zwei Buttons. Geben Sie durch Klick auf einen der Buttons Ihre Entscheidung ab. <br><br>Wichtig: Sie können diese Empfehlung über das System nicht mehr ändern."
      }
    }
    if (state === applicationStateEnum.APPROVED_OD_LEAD) {
      return {
        button: [],
        title: "Sie haben für die Freigabe des Zuschusses entschieden<br><br>",
        content: "Ihre Empfehlung wird nun vom System automatisch an einen ERGO Sponsoring-Manager weitergeleitet. Es gibt nichts weiter für Sie zu tun."
      }
    }

    return {button: [], title: '', content: ''}
  }
}


