<ng-container *transloco="let t" [formGroup]="form">
  <app-stepper></app-stepper>

  <div class="spinner-container" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
  </div>


  <div *ngIf="!isLoading" class="container-wizard">
    <app-wizard-header [title]="t('wizardTitles.conclusion')"></app-wizard-header>
      <div class="container">

        <app-key-value-card-component [title]="t('overviewTableHeader.sponsoringValue')"
                                      [requestedFormGroupName]="'sponsoringValue'">
        </app-key-value-card-component>
        <app-key-value-card-component [isEditable]="true"
                                      [title]="t('overviewTableHeader.applicant')"
                                      [requestedFormGroupName]="'applicant'">
        </app-key-value-card-component>
        <app-key-value-card-component [isEditable]="true" [title]="t('overviewTableHeader.sponsored')"
                                      [requestedFormGroupName]="'sponsored'"></app-key-value-card-component>
        <app-financial-assistance-key-value-component [isEditable]="true"></app-financial-assistance-key-value-component>
        <app-key-value-card-component [isEditable]="true" [title]="t('overviewTableHeader.entitlements')"
                                      [requestedFormGroupName]="'entitlements'"></app-key-value-card-component>

        <div id="dataUpload" class="col-lg-12 sectionSpaceDefault">
          <app-file-viewer   [titleIsAccordionStyle]="true" [purpose]="['sonstiges_antrag']" [title]="'Daten-Upload'"></app-file-viewer>
          <app-file-uploader [description]="'Sonstige Dateien (optional)'" (filesSelected)="uploadAllFiles($event) "
                             [purpose]="'sonstiges_antrag'"></app-file-uploader>
        </div>
        <div class="col-lg-12">
          <app-text-button class="button save-button text-center element-space-top"
                           [isPrimaryButton]="true" [buttonText]="'Antrag stellen'"
                           (click)="createApplication()">
          </app-text-button>
        </div>
      </div>
  </div>
</ng-container>
