import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  private files: any[] = [];

  delete(fileData: any): void {
    const index = this.files.indexOf(fileData);
    if (index > -1) {
      this.files.splice(index, 1);
    }
  }

  setData(fileData: any): void {
    fileData.date = Date.now();
    this.files.push(fileData);
  }

  getData(): any[] {
    return this.files;
  }

  clearData() {
    this.files = [];
  }
}
