<ng-container *transloco="let t" id="applicant-sponsored-overview" class="sponsored">
  <div class="container container-wizard">

    <app-icon-text-button [buttonSecondary]="true"
                          [iconName]="'arrow-left'"
                          [iconSize]="'small'"
                          [buttonText]="t('common.previous')"
                          [textColor]="'red'"
                          [buttonLeft]="true"
                          (click)="navigateToSponsoredOverview()"
    ></app-icon-text-button>

    <h1 class="spacing-title" [innerHTML]="applicationName"></h1>

    <ng-container id="content">
      <div class="key-container col-lg-12">
        <mat-card *ngFor="let cardGroup of [partnerCard, addressCard]" class="col-lg-6 card-spacing">
          <mat-card-content>
            <div *ngFor="let card of cardGroup" class="key-container">
              <div class="key"><h5>{{ card.key }}</h5></div>
              <div class="value"><p class="font-c2" [innerHTML]="card.value"></p></div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </ng-container>

    <h3 class="customized-header" [innerHTML]="t('overviewPages.applicant.sponsored.detailview.title')"></h3>
    <app-sponsored-detail-view-table
      (pageChanged)="loadMoreSponsoringData($event)"
      (sortingChanged)="sortValues($event)"
      [paginationInformation]="paginationInformation"
      [allSponsorings]="allSponsoringForSelectedSponsor">
    </app-sponsored-detail-view-table>

    <div id="internalNotes" class="section-space-default">
      <ng-container id="textArea">
        <h4 [innerHTML]="'Interne Anmerkungen'"></h4>
        <textarea [(ngModel)]="internalNote"
                  placeholder="min. 10 Zeichen"
                  class="col-lg-12 element-space" [minlength]="10">
        </textarea>
        <app-text-button [isPrimaryButton]="true" [buttonText]="'Speichern'"
                         (click)="saveInternalNote(internalNote)"></app-text-button>
      </ng-container>
      <div id="history" class="history-internal">

        <h4 *ngIf="historyInternalNotes.length !== 0" [innerHTML]="'Historie der Internen Anmerkungen'"></h4>

        <ng-container *ngFor="let note of historyInternalNotes">
          <div class="col-lg-12 element-space">
            <h5 [innerHTML]="'von Antragsteller '+ note.authorName + 'am ' + (note.created_at | date: 'dd.MM.YYYY')"></h5>
            <p class="font-c2" [innerHTML]="note.note"></p>
          </div>
        </ng-container>
      </div>
      <div>
      </div>
    </div>

  </div>
</ng-container>
