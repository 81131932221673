import {Component, EventEmitter, Output} from '@angular/core';
import {MatRadioButton, MatRadioChange, MatRadioGroup} from "@angular/material/radio";
import {TranslocoDirective} from "@ngneat/transloco";

@Component({
  selector: 'app-glvkf-funding-selection',
  standalone: true,
  imports: [
    MatRadioGroup,
    MatRadioButton,
    TranslocoDirective
  ],
  templateUrl: './glvkf-funding-selection.component.html',
  styleUrl: './glvkf-funding-selection.component.scss'
})
export class GlvkfFundingSelectionComponent {
  @Output() selectedFundingOption = new EventEmitter<any>();

  changeFundingOption(event: MatRadioChange): void {
    this.selectedFundingOption.emit(event.value);
  }

}
