import {Component, Input, OnInit} from '@angular/core';
import {KeyValuePipe, NgForOf, NgIf} from "@angular/common";
import {DataService} from "../../../core/services/data.service";
import {MatCard, MatCardContent, MatCardTitle} from "@angular/material/card";
import {MatIcon} from "@angular/material/icon";

@Component({
  selector: 'app-zuschuss-confirmation-component',
  standalone: true,
  imports: [
    NgForOf,
    MatCard,
    MatCardTitle,
    MatCardContent,
    MatIcon,
    NgIf
  ],
  templateUrl: './zuschuss-confirmation-component.component.html',
  styleUrl: './zuschuss-confirmation-component.component.scss'
})
export class ZuschussConfirmationComponentComponent implements OnInit {
  @Input() applicationId: string = '';
  data: any;
  isCardOpen: boolean = true;

  constructor(public dataService: DataService) {
    // noop
  }

  ngOnInit() {
      this.dataService.getStateHistory(this.applicationId).subscribe((result)=>{
        this.data = result;
      });
  }

  getKeyValuePairs(supervisor: any) {
    const customOrder = [
      'Zuschuss bewilligt',
      'Kürzung ',
      'Berechneter Zuschussbetrag brutto',
      'Information vom ERGO Sponsoring Manager ',
      'Entscheidung GLVKF',
      'Entscheidung OD-Leiter',
      'Begründung durch OD-Leiter',
      'Empfehlung Vorprüfer OD',
      'Maximal möglicher Zuschussbetrag brutto',
      'Kürzung',
      'Information vom ERGO Sponsoring Manager'
    ];

    return Object.entries(supervisor)
      .sort(([keyA], [keyB]) => {
        let indexA = customOrder.findIndex(keyword => keyA.includes(keyword));
        let indexB = customOrder.findIndex(keyword => keyB.includes(keyword));

        indexA = indexA === -1 ? customOrder.length : indexA;
        indexB = indexB === -1 ? customOrder.length : indexB;

        return indexA - indexB;
      })
      .map(([key, value]) => {
        return { key, value };
      });
  }


  closeCard(): void {
    this.isCardOpen = !this.isCardOpen;
  }



  protected readonly JSON = JSON;
}
