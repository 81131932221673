<mat-card  [ngClass]="marcCorrectionArea ? 'marc':''" *transloco="let t">
  <mat-card-title class="title icontest">
    <div>{{title}}</div>
    <div class="button-group">
      <button *ngIf="isEditable" (click)="openEditDialog('300ms', '200ms')">
        <p class="font-c2">Bearbeiten</p>
        <mat-icon class="icon" svgIcon="edit-icon"></mat-icon>
      </button>
      <button *ngIf="extended" (click)="closeCard()">
        <mat-icon class="icon-arrow" [svgIcon]="isCardOpen ? 'open-accordion' : 'close-accordion'"></mat-icon>
      </button>
    </div>
  </mat-card-title>
  <mat-card-content *ngIf="isCardOpen">
    <ng-container *ngIf="(serverData && this.localStorage.getItem('form-data') === null) else defaultKeyValues">
      <div class="item" *ngFor="let selectedData of getFlattenedFormValues()">
        <div class="key-value-pair row">
          <div class="key col-lg-6">{{ translateDataKeys(selectedData[0])}} </div>
          <div class="value col-lg-6">
            {{ formattedValue(selectedData[1], selectedData) }}
            {{this.getFormUnit(selectedData[0])}}
          </div>
        </div>
      </div>
    </ng-container>

    <ng-template #defaultKeyValues>
      <ng-container *ngFor="let item of getFormControlsAndValues()">
        <div class="item">
          <div class="key-value-pair row" *ngIf="item['value'] !== null">
            <div class="key col-lg-6">{{ translateKeys(item['key'])}} </div>
            <div class="value col-lg-6"> {{ formattedValue(item['value'], item)}} {{this.getFormUnit(item['key'])}}</div>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </mat-card-content>
</mat-card>

<ng-container *ngIf="comment">
  <ng-container [formGroup]="commentForm">
    <ng-container formGroupName="{{requestedFormGroupName}}">
      <h5>Kommentar</h5>
      <textarea formControlName="comment" placeholder="min. 10 Zeichen" class="col-lg-12 comment commentFieldStyle"></textarea>
    </ng-container>
  </ng-container>
</ng-container>

