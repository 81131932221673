import {Component, Input, OnInit} from '@angular/core';
import {KeyValuePipe, NgForOf, NgIf} from "@angular/common";
import {DataService} from "../../../core/services/data.service";
import {MatCard, MatCardContent, MatCardTitle} from "@angular/material/card";
import {MatIcon} from "@angular/material/icon";
import {IconService} from "../../../shared/services/IconService";

@Component({
  selector: 'app-rd-confirmation-component',
  standalone: true,
  imports: [
    KeyValuePipe,
    NgForOf,
    MatCard,
    MatCardTitle,
    MatCardContent,
    MatIcon,
    NgIf
  ],
  templateUrl: './rd-confirmation-component.component.html',
  styleUrl: './rd-confirmation-component.component.scss'
})
export class RdConfirmationComponentComponent implements OnInit {
  @Input() applicationId: string = '';
  rdData: any;
  isCardOpen: boolean = true;

  constructor(public dataService: DataService, private IconService: IconService) {
    // noop
  }

  ngOnInit() {
    this.dataService.getStateHistory(this.applicationId).subscribe((result)=>{
      this.rdData = result;
    });
  }

  getKeyValuePairs(supervisor: any) {
    return Object.keys(supervisor).map(key => {
      return { key: key, value: supervisor[key] };
    });
  }

  closeCard(): void {
    this.isCardOpen = !this.isCardOpen;
  }



  protected readonly JSON = JSON;
}
