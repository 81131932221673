import {Component, Input, OnInit} from '@angular/core';
import {TextButtonComponent} from "../../../shared/components/button/text-button/text-button.component";
import {TranslocoDirective} from "@ngneat/transloco";
import {WizardHeaderComponent} from "../../sponsoring-wizard/wizard-header/wizard-header.component";
import {WizardService} from "../../../shared/services/wizard-service/wizard.service";
import {FormService} from "../../../shared/services/form-service/form.service";
import {DataService} from "../../../core/services/data.service";
import {Router} from "@angular/router";
import {StateTextService} from "../../../shared/services/state-text-service/state-text.service";
import {NgIf} from "@angular/common";
import {applicationStateEnum} from "../../../api/enum/stateEnum";
import {FileUploadService} from "../../../shared/components/file-uploader/file-uploader-service";
import {FileViewerComponent} from "../../../shared/components/file-viewer/file-viewer.component";
import {FormsModule} from "@angular/forms";
import {RejectionModalComponent} from "../Modal/rejection-modal/rejection-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {MatFormField} from "@angular/material/form-field";
import {MatRadioButton, MatRadioGroup} from "@angular/material/radio";
import {MatInput} from "@angular/material/input";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {CurrencyFormatDirective} from "../../../shared/directives/currency-directive";
import {NumericFieldDirective} from "../../../shared/directives/numeric-field-directive";
import {MessageModalComponent} from "../Modal/message-modal/message-modal.component";


@Component({
  selector: 'app-zuschuss-document-overview',
  standalone: true,
  imports: [
    WizardHeaderComponent,
    TextButtonComponent,
    TranslocoDirective,
    NgIf,
    FileViewerComponent,
    FormsModule,
    MatRadioButton,
    MatRadioGroup,
    MatFormField,
    MatInput,
    MatProgressSpinner,
    CurrencyFormatDirective,
    NumericFieldDirective
  ],
  templateUrl: './zuschuss-document-overview.html',
  styleUrl: './zuschuss-document-overview.scss'
})
export class ZuschussDocumentOverview implements OnInit {
  @Input() applicationData: any = null;
  @Input() applicationId: any = null;

  selectedFiles = [];
  zuschussComment = '';
  kuerzung: boolean = false;
  kuerzung_reason: string | null = null;
  amount: number | null = null;

  constructor(public wizardService: WizardService,
              public stateTextService: StateTextService,
              public formService: FormService,
              public fileUploadService: FileUploadService,
              public dataService: DataService,
              public router: Router,
              public dialog: MatDialog
  ) {}


  ngOnInit() {
    const state: any = history.state;
    if (state.applicationId) {
      this.applicationId = state.applicationId.toString();
      this.getZuschussDocuments();
      this.getDataForSelectedApplication(state.applicationId);
    } else {
      console.error('applicationId ist nicht im Router-State vorhanden.');
    }
  }

  getDataForSelectedApplication(applicationId: string): void {
    this.dataService.getApplicationById(applicationId).subscribe((result) => {
      this.applicationData = result;
    });
  }
  getZuschussDocuments(): any {
    this.dataService.getZuschussDocuments(this.applicationId).subscribe((result) => {
      this.selectedFiles = result;
    });
  }


  isApproveButtonDisabled(): boolean {
    return !this.amount || (!!this.kuerzung && !this.kuerzung_reason);
  }


  approveFunding(action: string, reason?: string): void {
    const funding = {
      funding_amount: this.amount,
      is_reduced_funding_amount: this.kuerzung,
      reduced_amount_reason: this.kuerzung_reason
    }

    this.dataService.postZuschussAmount(this.applicationId, funding).subscribe({
      next: () => {
        this.changeStateOfApplication(action, reason ? reason : '');
      },
      error: (err) => {
        console.error('Status konnte nicht geändert werden', err);
      }
    });
  }

  saveCommentForZuschuss(action: string): void {
    const postCommentRequest = {
      fundingCheck: {
        comments: [this.zuschussComment],
      },
    }
    this.dataService.postComment(this.applicationId, postCommentRequest).subscribe({
      next: () => {
          this.correctFunding(action)
      }
    })
  }

  rejectFunding(action:string, rejectionReason: string): void {
    const funding = {
      funding_amount: this.amount,
      is_reduced_funding_amount: this.kuerzung,
      reduced_amount_reason: this.kuerzung_reason
    }

    this.dataService.postZuschussAmount(this.applicationId, funding).subscribe({
      next: () => {
        this.changeStateOfApplication(action, rejectionReason);
      },
      error: (err) => {
        console.error('Status konnte nicht geändert werden', err);
      }
    });
  }

  correctFunding(action: string, ): void {
    const funding = {
      funding_amount: this.amount,
      is_reduced_funding_amount: this.kuerzung,
      reduced_amount_reason: this.kuerzung_reason
    }

    this.dataService.postZuschussAmount(this.applicationId, funding).subscribe({
      next: () => {
        this.changeStateOfApplication(action, '');
      },
      error: (err) => {
        console.error('Status konnte nicht geändert werden', err);
      }
    });
  }


  openModal(action: string) {
    const dialogRef = this.dialog.open(MessageModalComponent, {
      width: '920px',
      height:'max-content',
      data: action
    });
    dialogRef.afterClosed().subscribe( (rejectionReason) =>  {
      switch (action) {
        case 'cancel':
          return;
        case 'accepted':
          return this.approveFunding(action, rejectionReason);
        case 'rejected':
          return this.rejectFunding(action, rejectionReason);
      }
    });
  }


  changeStateOfApplication(action: string, rejectionReason: string): void {
    this.dataService.changeStateOfApplication(this.applicationId, action, rejectionReason).subscribe({
      next: () => {
        this.navigateToDashboard();
      },
      error: (err) => {
        console.error('Status konnte nicht geändert werden', err);
      }
    });
  }


  changeValue(event: any): void {
    this.kuerzung = event.value;
  }

  navigateToDashboard() {
    this.router.navigate(['/dashboard']);
  }

  protected readonly JSON = JSON;
  protected readonly applicationStateEnum = applicationStateEnum;
}
