import {Component, OnInit} from '@angular/core';
import {FileUploaderComponent} from "../../../shared/components/file-uploader/file-uploader.component";
import {
  FinancialAssistanceKeyValueComponent
} from "../../../shared/components/financial-assistance-table-component/financial-assistance-key-value-component";
import {
  KeyValueCardComponentComponent
} from "../../../shared/components/key-value-card-component/key-value-card-component.component";
import {StepperComponent} from "../../../shared/components/stepper/stepper.component";
import {TextButtonComponent} from "../../../shared/components/button/text-button/text-button.component";
import {TranslocoDirective} from "@ngneat/transloco";
import {WizardHeaderComponent} from "../../sponsoring-wizard/wizard-header/wizard-header.component";
import {WizardService} from "../../../shared/services/wizard-service/wizard.service";
import {FormService} from "../../../shared/services/form-service/form.service";
import {DataService} from "../../../core/services/data.service";
import {ActivatedRoute, Router} from "@angular/router";
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule
} from "@angular/forms";
import {HintComponent} from "../../../shared/components/hint/hint.component";
import {IconTextButtonComponent} from "../../../shared/components/button/icon-text-button/icon-text-button.component";
import {MatCard} from "@angular/material/card";
import {StateTextService} from "../../../shared/services/state-text-service/state-text.service";
import {DatePipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {MediaBoxComponent} from "../../../shared/components/media-box/media-box.component";
import {IconsComponent} from "../../../shared/components/icons/icons.component";
import {applicationStateEnum} from "../../../api/enum/stateEnum";
import {FileUploadService} from "../../../shared/components/file-uploader/file-uploader-service";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {
  FinancialKeyComponent
} from "../../../shared/components/financial-assistance-table-component/financial-key/financial-key.component";
import {FileViewerComponent} from "../../../shared/components/file-viewer/file-viewer.component";
import {ConsentComponent} from "../../sponsoring-wizard/consent/consent.component";
import {
  GlvkfConfirmationComponentComponent
} from "../glvkf-confirmation-component/glvkf-confirmation-component.component";
import {RdConfirmationComponentComponent} from "../rd-confirmation-component/rd-confirmation-component.component";
import {SpecialCaseCards} from "../special-case-cards/special-case-cards";
import {
  ZuschussConfirmationComponentComponent
} from "../zuschuss-confirmation-component/zuschuss-confirmation-component.component";
import {VkfConfirmationComponentComponent} from "../vkf-confirmation-component/vkf-confirmation-component.component";
import {CurrencyFormatDirective} from "../../../shared/directives/currency-directive";


@Component({
  selector: 'app-applicant-detailoverview',
  standalone: true,
  imports: [
    FileUploaderComponent,
    KeyValueCardComponentComponent,
    TextButtonComponent,
    TranslocoDirective,
    WizardHeaderComponent,
    ReactiveFormsModule,
    HintComponent,
    MatCard,
    NgForOf,
    NgIf,
    DatePipe,
    FormsModule,
    MediaBoxComponent,
    IconsComponent,
    MatProgressSpinner,
    FinancialKeyComponent,
    FileViewerComponent,
    ConsentComponent,
    GlvkfConfirmationComponentComponent,
    RdConfirmationComponentComponent,
    SpecialCaseCards,
    NgClass,
    ZuschussConfirmationComponentComponent,
    VkfConfirmationComponentComponent,
  ],
  providers: [CurrencyFormatDirective],
  templateUrl: './applicant-detailoverview.component.html',
  styleUrl: './applicant-detailoverview.component.scss'
})

export class ApplicantDetailoverviewComponent implements OnInit {
  sections = [
    {id: 'sponsoringValue', title: 'keyValueTitles.sponsoringValue', dataKey: 'sponsoringValue'},
    {id: 'applicant', title: 'keyValueTitles.applicant', dataKey: 'applicant'},
    {id: 'sponsored', title: 'keyValueTitles.sponsored', dataKey: 'sponsored'},
    {id: 'financialAssistance', title: 'keyValueTitles.financialAssistance', dataKey: 'financialAssistance'},
    {id: 'entitlements', title: 'keyValueTitles.entitlements', dataKey: 'entitlements'},
    {id: 'dataUpload', title: 'keyValueTitles.dataUpload', dataKey: 'dataUpload'}
  ];
  editMode = false;

  form = this.formService.buildForm();
  applicationId = '';
  selectedFiles: { documents: any[] } = { documents: [] };
  fundingFiles: { documents: any[] } = { documents: [] };

  commentForm = {} as FormGroup;
  applicationStateEnum = applicationStateEnum;
  comments: { [key: string]: Array<{ author: string; date: string; sonstiges: string }> } = {};
  applicationData: any = null;
  allConsentsCorrect: boolean = false;

  constructor(public wizardService: WizardService,
              public stateTextService: StateTextService,
              public formService: FormService,
              public fileUploadService: FileUploadService,
              public dataService: DataService,
              public router: Router,
              private fb: FormBuilder,
              private route: ActivatedRoute,
              public currencyFormat: CurrencyFormatDirective,
  ) {
    this.commentForm = this.fb.group({
      comments: this.fb.group({
        applicant: this.fb.control(null),
        sponsoringValue: this.fb.control(null),
        financialAssistance: this.fb.control(null),
        entitlements: this.fb.control(null),
        sponsored: this.fb.control(null),
        dataUpload: this.fb.control(null)
      })
    });
  }

  ngOnInit() {
    this.formService.initializeForm(this.form);

    this.route.paramMap.subscribe(params => {
      const state: any = history.state;
      if (state.applicationId) {
        this.applicationId = state.applicationId.toString();
        this.getDataForSelectedApplication(state.applicationId);
        this.getCommentsForSelectedApplication(state.applicationId);
        this.getZuschussDocuments();
        this.dataService.getContractDocuments(this.applicationId).subscribe((result) => {
          this.selectedFiles = result;
        });
      }
    });
  }

  resetConsent() {
    const consentsGroup = this.form.get('consent') as FormGroup;
    setTimeout(() => {
      if (consentsGroup) {
        Object.keys(consentsGroup.controls).forEach((key: string) => {
          const control = consentsGroup.get(key);
          control?.setValue(false);
        });
      }
    }, 100)
  }

  getZuschussDocuments(): any {
    this.dataService.getZuschussDocuments(this.applicationId).subscribe((result) => {
      this.fundingFiles = result;
    });
  }

  returnStateValue(): string {
    if (this.applicationData) {
      switch (this.applicationData?.sponsoringValue?.application_state_label) {
        case applicationStateEnum.APPROVED_ADD_CONTRACT:
          return this.applicationData?.sponsoringValue?.application_state_label;
        case applicationStateEnum.CHECK_OD:
          return this.applicationData?.sponsoringValue?.application_state_label;
        case applicationStateEnum.CHECK_RD:
          return this.applicationData?.sponsoringValue?.application_state_label;
        case applicationStateEnum.CHECK_GLVKF:
          return this.applicationData?.sponsoringValue?.application_state_label;
        case applicationStateEnum.CONTRACT_ADDED:
          return this.applicationData?.sponsoringValue?.application_state_label;
        case applicationStateEnum.ZUSCHUSS:
          return this.applicationData?.sponsoringValue?.application_state_label;
        default:
          return this.applicationData?.sponsoringValue?.application_state;
      }
    } else {
      return '';
    }
  }

  returnClickEvent(methodName?: string): void {
    if (methodName === 'switchToEditMode') {
      this.switchToEditMode()
    }
    if (methodName === 'openAddContract') {
      this.router.navigate(['/contract-documents'], {state: {applicationId: this.applicationId}});
    }
    if (methodName === 'openZuschussDocuments') {
      this.router.navigate(['/zuschuss-documents'], {state: {applicationId: this.applicationId}});
    }
  }

  switchToEditMode(): void {
    this.editMode = !this.editMode;
    if (this.editMode) {
      this.resetConsent();
    }
  }

  openZuschussDocuments(): void {
    this.router.navigate(['/zuschuss-documents'], {state: {applicationId: this.applicationId}});
  }

  showUploadPart(): boolean {
    return this.applicationData?.sponsoringValue?.application_state === applicationStateEnum.OPEN ||
      this.applicationData?.sponsoringValue?.application_state === applicationStateEnum.CORRECTION_NEEDED ||
      this.applicationData?.sponsoringValue?.application_state === applicationStateEnum.CORRECTION_CHECK;
  }

  // to be Defined
  showRechnungCopy(): boolean {
    return false;
  }

  isFormEditable(section?: string): boolean {
    return section !== 'sponsoringValue' && this.applicationData?.sponsoringValue?.application_state === applicationStateEnum.CORRECTION_NEEDED && this.editMode;
  }

  getDataForSelectedApplication(applicationId: string): void {
    this.dataService.getApplicationById(applicationId).subscribe((result) => {
      this.applicationData = result;
    });
  }

  getCommentsForSelectedApplication(applicationId: string): void {
    this.dataService.getCommentsForApplicationById(applicationId).subscribe((result) => {
      this.comments = result;
    });
  }

  getCommentsForSection(section: string): Array<{ authorName: string; created_at: string; content: string }> | null {
    if (this.applicationData?.application_state === applicationStateEnum.OPEN) {
      return null;
    }
    let result: any;

    if (this.comments['comments']) {
      Object.entries(this.comments['comments']).forEach(([key, value]) => {
        if (key === section) {
          result = value;
        }
      });
    }
    return result;
  }

  navigateToDashboard(): void {
    this.router.navigate(['/dashboard']);
  }

  showActionCard(): boolean {
    return !(
      this.applicationData?.sponsoringValue.application_state === applicationStateEnum.OD_CHECK_ZUSCHUSS
    );
  }
  showSponsoringDocument(): boolean {
    const keys = Object.keys(this.selectedFiles.documents)
    return keys.includes('contract') || keys.includes('signed_contract');
  }

  changeState(action: string): void {
    this.dataService.changeStateOfApplication(this.applicationId, action, '').subscribe({
      next: () => {
        this.navigateToDashboard();
      },
      error: (err) => {
        console.error('Status konnte nicht geändert werden', err);
      }
    });
  }

  updateApplication(state: string) {
    const formData = this.formService.getFormData();
    const dataArray = this.form.get('dataUpload') as FormArray;
    const dataToUpload = this.fileUploadService.getData();
    dataToUpload.forEach(dataItem => {
      dataArray.push(this.fb.control(dataItem));
    });
    formData.dataUpload = dataToUpload;
    this.workAroundConvertZeroValuesToNull(formData);


    this.dataService.putApplication(this.applicationId, formData).subscribe({
      next: () => {
        this.changeState(state);
        this.formService.removeFormData();
      },
      error: (err) => {
        console.error('An error occurred:', err);
      }
    });
  }

  workAroundConvertZeroValuesToNull(formData: any): void {
    // todo  Backend anpassen, dass auch string 0,00 akzeptiert wird
      Object.keys(formData.sponsoringValue).forEach(key => {
        if (formData.sponsoringValue[key] === "0,00") {
          formData.sponsoringValue[key] = null;
        }
      });
  }

  isCorrectionButtonEnabled(consentsCorrect: boolean): void {
    this.allConsentsCorrect = consentsCorrect
  }

  saveDocumentsForApplication(): void {
    const files = this.fileUploadService.getData();
    this.dataService.uploadDocuments(this.applicationId, files).subscribe((result) => {
      this.fileUploadService.clearData();
      this.dataService.getContractDocuments(this.applicationId).subscribe((result) => {
        this.selectedFiles = result;
      });
    });
  }

  trackByIndex = (index: number): number => {
    return index;
  };

  getFunding(): string {
    return this.applicationData?.sponsoringValue?.funding_amount ? this.currencyFormat.formatToCurrency(this.applicationData?.sponsoringValue?.funding_amount) : '';
  }

  isFundingReduced(): boolean {
    return this.applicationData?.sponsoringValue?.is_reduced_funding_amount;
  }

  isDisabledSponsoringContractWhenStateNoContact(): boolean {
    return this.applicationData?.sponsoringValue?.application_state_label === applicationStateEnum.APPROVED_ADD_CONTRACT && !this.editMode;
  }

  protected readonly Object = Object;
}
