import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell, MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow, MatRowDef, MatTable, MatTableDataSource
} from "@angular/material/table";
import {AuthenticationService} from "../../core/services/authentication.service";
import {ApplicationDto} from "../../api/applicationDto";
import {Subscription} from "rxjs";
import {environment} from "../../../environments/environment";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatTable,
    MatHeaderCellDef,
    NgIf
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit, OnDestroy {
  displayedColumns: string [] = ['name', 'role', 'link'];
  dataSource = new MatTableDataSource<ApplicationDto>();
  isProduction = environment.apiPath === 'https://mein-regionales-sponsoring.ergo.de'

  private subscriptions = new Subscription();

  constructor(public authenticationService: AuthenticationService, private authenficationService: AuthenticationService) {
    // noop
  }

  ngOnInit() {
    this.authenficationService.deleteToken();
    if(this.isProduction) {
      this.authenficationService.ssoLogin();
    } else {
      const rolesForLoginSub =  this.authenticationService.getRolesForLoginPage().subscribe((roles) => {
        this.dataSource = roles;
      });
      this.subscriptions.add(rolesForLoginSub);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
