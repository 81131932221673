export enum applicationStateEnum {
  // Applicant
  OPEN = 'Antrag eingereicht',
  CORRECTION_NEEDED = 'Korrektur erforderlich',

//VKF
  NEU = 'Neu',
  CORRECTION_VP = 'Korrektur bei VP',

  //rd
  CHECK_VKF = 'In Prüfung - VKF',

  //allgemein
  CHECK_RD = 'In Prüfung - RD-Leiter',
  CORRECTION_CHECK = 'Korrekturprüfung',
  CHECK_GLVKF = 'In Prüfung - GLVKF',
  APPROVED_ADD_CONTRACT = 'Genehmigt/Vertrag einreichen',
  CONTRACT_ADDED = 'Vertrag eingereicht',
  APPROVAL_NO_CONTRACT = 'Genehmigt/Vertrag fehlt',
  CONTRACT_CORRECTIONS = 'Unterschriebener Vertrag auf Korrektur zurückgegeben',
  APPROVED_NO_ZUSCHUSS = 'Genehmigt ohne Zuschuss',
  REJECTED_VKF = 'Abgelehnt durch VKF',
  REJECTED_RD = 'Abgelehnt durch RD-Leiter',
  REJECTED_GLVKF = 'Abgelehnt durch GLVKF',
  ZUSCHUSS_UPLOADED = 'Zuschuss Dokumente hochgeladen von Antragsteller',
  ZUSCHUSS_CORRECTIONS = 'Zuschuss Dokumente auf Korrektur zurückgegeben',
  ZUSCHUSS_CORRECTIONSSECOND = 'ZuschussDocumentOverview Dokumente auf Korrektur zurückgegeben',

  APPROVED_GLVKF = "Genehmigt durch GLVKF",
  ZUSCHUSS = 'Zuschussprüfung',
  CHECK_OD = 'In Prüfung - Vorprüfer OD',
  CHECK_OD_LEAD = 'In Prüfung - OD-Leiter',
  APPROVED_OD = 'Empfohlen durch Vorprüfer OD',
  APPROVED_OD_LEAD = 'Empfohlen durch OD-Leiter',

  ZUSCHUSS_APPROVED_GLVKF = "Zuschuss-Freigabe durch GLVKF",
  ZUSCHUSS_REJECTED_GLVKF = "Zuschuss-Ablehnung durch GLVKF",

  DONE_WITH_FUNDING = "Genehmigt mit Zuschuss",
  DONE_WITH_FUNDING_REDUCED = "Genehmigt mit Zuschuss mit Kürzung",
  IN_WORK_ZUSCHUSS = "VKF prüft Zuschuss Empfehlungen",

  OD_CHECK_ZUSCHUSS = "Zuschuss Dokumente akzeptiert",
  OD_CHECK_ZUSCHUSS_REDUCED = "Zuschuss Dokumente akzeptiert mit Kürzung",
  REJECTED_OD = 'Abgelehnt durch Vorprüfer OD',
  REJECTED_OD_LEAD = 'Abgelehnt durch OD-Leiter',



// to be tested:
  // alle abgelehnt
  // korrektur bei vertragsprüfung // abgelehnt vertragsprüfung
  // Zuschuss korrektur // zuschuss abgelehnt










  CONTRACT_CHECK = 'Vertragsprüfung',


  ZUSCHUSS_ACCEPTED = 'Unterschriebener Vertrag akzeptiert',

  DONE = 'Abgeschlossen',









  CHECK_DONE_VKF = 'Geprüft durch VKF',

  APPROVED_RD_LEITER = "Genehmigt durch RD-Leiter",
  APPROVED_VKF = "Sponsoring Daten genehmigt durch VKF",
  REJECTED_RD_LEITER =  "Abgelehnt durch RD-Leiter",

  SPONSORING_APPROVED_VKF =  "Sponsoring Daten genehmigt durch VKF",

  CONTRACT_SIGNED = "Vertrag wurde unterschrieben eingereicht von Antragsteller"
}
