<ng-container id="navigation">
    <div class="navigation">
      <ng-container *ngIf="this.cookieService.get('user-role').toUpperCase() === UserRoleEnum.VKF  || this.cookieService.get('user-role').toUpperCase() === UserRoleEnum.APPLICANT">
        <a  class="font-c2" [routerLink]="['/dashboard']" routerLinkActive="active">Dashboard</a>
        <a  class="font-c2" [routerLink]="['/sponsored-overview']" routerLinkActive="active">Gesponserte</a>
      </ng-container>
      <ng-container *ngIf="this.cookieService.get('user-role').toUpperCase()=== UserRoleEnum.VKF">
       <a  class="font-c2" [routerLink]="['/controlling']" routerLinkActive="active">Reporting & Controlling</a>
       <a  class="font-c2" [routerLink]="['/applications-overview']" routerLinkActive="active">Anträge</a>
       <a  class="font-c2" [routerLink]="['/applicant-overview']" routerLinkActive="active">Antragsteller</a>
      </ng-container>
    </div>
</ng-container>
