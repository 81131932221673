import {ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MatCard, MatCardContent, MatCardTitle} from "@angular/material/card";
import {DatePipe, JsonPipe, KeyValuePipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {isObject, translate, TranslocoDirective} from "@ngneat/transloco";
import {MatDialog} from "@angular/material/dialog";
import {EditFormDialogComponent} from "../edit-form-dialog/edit-form-dialog.component";
import {FormService} from "../../services/form-service/form.service";
import {MatIcon} from "@angular/material/icon";
import {FileTypePipe} from "../../pipes/fileTypePipe";
import {IconTextButtonComponent} from "../button/icon-text-button/icon-text-button.component";
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import {IconService} from "../../services/IconService";
import {CurrencyFormatDirective} from "../../directives/currency-directive";


interface FormData {
  key: string;
  value: any;
}

@Component({
  selector: 'app-key-value-card-component',
  standalone: true,
  imports: [
    MatCard,
    MatCardTitle,
    MatCardContent,
    NgForOf,
    TranslocoDirective,
    NgIf,
    MatIcon,
    IconTextButtonComponent,
    ReactiveFormsModule,
    KeyValuePipe,
    NgClass,
    JsonPipe,
  ],
  providers: [FileTypePipe, CurrencyFormatDirective],
  templateUrl: './key-value-card-component.component.html',
  styleUrl: './key-value-card-component.component.scss'
})
export class KeyValueCardComponentComponent implements OnInit, OnChanges {
  @Input() title: string = 'Key-Value Table';
  @Input() extended: boolean = true;
  @Input() requestedFormGroupName = '';
  @Input() isEditable = false;
  @Input() comment = false;
  @Input() data: any;
  @Input() serverData: any;
  @Input() marcCorrectionArea = false;

  form = this.formService.buildForm();
  commentForm = this.formService.commentForm;
  isCardOpen: boolean = true;

  fieldsToDisable = [
    'sponsoringDuration',
    'id',
    'funding_amount',
    'reduced_amount_reason',
    'application_state',
    'application_state_label',
    'is_reduced_funding_amount',
    'created_at'
  ];

  constructor(public formService: FormService,
              public fileTypePipe: FileTypePipe,
              public datePipe: DatePipe,
              public iconService: IconService,
              private formatToCurrency: CurrencyFormatDirective,
              private cdr: ChangeDetectorRef,
              public dialog: MatDialog) {
    this.formService.initializeForm(this.form);
  }

  ngOnInit() {
    if (this.data) {
      this.formService.initializeFormFromData(this.data);
      this.cdr.detectChanges();
      // @ts-ignore
      // this.form.get(this.requestedFormGroupName).setValue(this.data);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.formService.initializeForm(this.form);
    if (changes) {
      this.getFormControlsAndValues();
    }
  }

  getFlattenedFormValues() {
    const flattenObject = (obj: any, parentKey: string = '', result: any = {}) => {
      for (let key in obj) {
        if (this.serverData?.sponsoringValue?.sponsoringDuration === 1) {
          this.fieldsToDisable.push('sponsoringValueGrossAmountSecondYear');
          this.fieldsToDisable.push('sponsoringValueNetAmountSecondYear');
          if (this.serverData?.sponsoringValue?.taxFree) {
            this.fieldsToDisable.push('sponsoringValueGrossAmountFirstYear');
          }
        } else if (this.serverData?.sponsoringValue?.taxFree) {
          this.fieldsToDisable.push('sponsoringValueGrossAmountFirstYear');
          this.fieldsToDisable.push('sponsoringValueGrossAmountSecondYear');
        }
        if (this.serverData?.entitlements?.hospitality.isHospitality === false) {
          this.fieldsToDisable.push('hospitalityService');
        }

        if (!this.fieldsToDisable.includes(key)) {
          if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            const newKey = parentKey ? `${parentKey}.${key}` : key;

            if (Array.isArray(value)) {
              value.forEach((item, index) => {
                if (item && typeof item === 'object') {
                  flattenObject(item, `${newKey}[${index}]`, result);
                } else {
                  result[`${newKey}[${index}]`] = item;
                }
              });
            } else if (value && typeof value === 'object') {
              flattenObject(value, newKey, result);
            } else {
              result[newKey] = value;
            }
          }
        }
      }
      const sortedEntries = this.sortList(result);
      return Object.entries(sortedEntries);
    };
    return flattenObject(this.data);
  }


  sortList(result: any) {
    const formGroup = this.form.get(this.requestedFormGroupName) as FormGroup;
    const order = Object.keys(formGroup.controls);
    const entries = result ? Object.entries(result) : [];

    entries.sort((a, b) => {
      const keyA = a[0];
      const keyB = b[0];

      const baseKeyA = keyA.split(/[\[.]/)[0];
      const baseKeyB = keyB.split(/[\[.]/)[0];

      const indexA = order.indexOf(baseKeyA);
      const indexB = order.indexOf(baseKeyB);

      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
      }
      if (indexA === -1 && indexB !== -1) return 1;
      if (indexB === -1 && indexA !== -1) return -1;
      return 0;
    });

    return Object.fromEntries(entries);
  }

  getFormControlsAndValues(): any {
    if (this.form) {
      const valuesList: { key: string; value: any }[] = [];
      const entries = Object.entries(this.form.get(this.requestedFormGroupName)?.value || {});

      const processEntries = (entries: [string, any][], parentFormGroup: string = ''): void => {
        entries.forEach(([key, value]) => {
          const fullKey = parentFormGroup ? `${parentFormGroup}.${key}` : key;

          if (this.form.get('sponsoringValue.sponsoringDuration')?.value === 1) {
            this.fieldsToDisable.push('sponsoringValueGrossAmountSecondYear');
            this.fieldsToDisable.push('sponsoringValueNetAmountSecondYear');
            if (this.form.get('sponsoringValue.taxFree')?.value) {
              this.fieldsToDisable.push('sponsoringValueGrossAmountFirstYear');
            }
          } else if (this.form.get('sponsoringValue.taxFree')?.value) {
            this.fieldsToDisable.push('sponsoringValueGrossAmountFirstYear');
            this.fieldsToDisable.push('sponsoringValueGrossAmountSecondYear');
          }
          if (this.form.get('entitlements.hospitality.isHospitality')?.value === false) {
            this.fieldsToDisable.push('hospitalityService');
          }

          if (this.fieldsToDisable.includes(fullKey)) {
            return;
          }
          if (isObject(value)) {
            processEntries(Object.entries(value), fullKey);
          } else if (Array.isArray(value)) {
            value.forEach((arrayValue, index) => {
              const arrayKey = `${fullKey}-${index + 1}`;
              valuesList.push({key: arrayKey, value: arrayValue});
            });
          } else {
            valuesList.push({key: fullKey, value: value});
          }
        });
      };
      processEntries(entries);
      return valuesList;
    }
  }

  openEditDialog(enterAnimationDuration: string, exitAnimationDuration: string) {
    const dialogRef = this.dialog.open(EditFormDialogComponent, {
      width: '920px',
      height: '75vh',
      enterAnimationDuration,
      exitAnimationDuration,
      data: this.data && this.localStorage.getItem('form-data') === null ?
        {value: this.data, name: this.requestedFormGroupName, serverData: this.serverData} :
        {name: this.requestedFormGroupName, serverData: null},
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.form = result;
        this.formService.initializeForm(this.form);
        this.getFormControlsAndValues();
        this.cdr.detectChanges();
      }
    });
  }

  formattedValue(itemValue: any, item: any) {
    let formattedValue = null;
    if (this.fileTypePipe.transform(itemValue) === 'date') {
      return this.datePipe.transform(itemValue, 'dd.MM.YYYY');
    }
    if (this.getCurrencyFields().includes(item['key'])) {
      if(itemValue){
        itemValue = parseFloat(itemValue.toString().replace(/\./g, '').replace(',', '.'));
        if (!isNaN(itemValue)) {
          formattedValue = new Intl.NumberFormat('de-DE', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }).format(itemValue);

          return formattedValue;
        }
      }
    }

    if (itemValue === false) {
      return translate('common.' + 'false');
    } else if (itemValue === true) {
      return translate('common.' + 'true');
    }
    return formattedValue ? formattedValue  : itemValue;
  }

  translateDataKeys(data: any) {
    const parts = data.split(/[\[\]]/);
    const taxFree = this.serverData?.sponsoringValue?.taxFree;

    if (parts.length > 1 && parts[0] === 'services') {
      return translate('formFieldMapping.services.default', {count: (parseInt(parts[1]) + 1)})
    }
    if (parts.includes('sponsoringValueGrossAmountFirstYear')
      || parts.includes('sponsoringValueNetAmountFirstYear')
      || parts.includes('sponsoringValueNetAmountSecondYear')
      || parts.includes('sponsoringValueGrossAmountSecondYear')) {
      if (this.serverData.sponsoringValue.sponsoringDuration === 2) {
        if (taxFree) {
          return translate('formFieldMapping.sponsoringValueTwoYears.taxFree.' + data);
        } else {
          return translate('formFieldMapping.sponsoringValueTwoYears.' + data);
        }
      } else {
        if (taxFree) {
          return translate('formFieldMapping.sponsoringValueOneYear.taxFreeFirstYear')
        } else {
          return translate('formFieldMapping.sponsoringValueOneYear.' + data);
        }
      }
    } else {
      return translate('formFieldMapping.' + data)
    }
  }

  translateKeys(item: any) {
    const splitKey = item.split('-');
    if (splitKey.length > 1) {
      return translate('formFieldMapping.' + splitKey[0] + '.default', {count: splitKey[1]});
    }

    if (item === 'sponsoringValueGrossAmountFirstYear'
      || item === 'sponsoringValueNetAmountFirstYear'
      || item === 'sponsoringValueNetAmountSecondYear'
      || item === 'sponsoringValueGrossAmountSecondYear') {
      return this.returnTextForSponsoringValue(item);
    } else {
      return translate('formFieldMapping.' + item);
    }
  }

  returnTextForSponsoringValue(item: any) {
    const sponsoringDuration = this.form.get('sponsoringValue.sponsoringDuration')?.value;
    const taxFree = this.form.get('sponsoringValue.taxFree')?.value;
    if (sponsoringDuration === 2) {
      if (taxFree) {
        return translate('formFieldMapping.sponsoringValueTwoYears.taxFree.' + item);
      } else {
        return translate('formFieldMapping.sponsoringValueTwoYears.' + item);
      }
    } else {
      if (taxFree) {
        return translate('formFieldMapping.sponsoringValueOneYear.taxFreeFirstYear')
      } else {
        return translate('formFieldMapping.sponsoringValueOneYear.' + item);
      }
    }
  }

  getFormUnit(field: string | unknown): string {
    if (field === 'sponsoringDuration') {
      return this.form.get('sponsoringValue.sponsoringDuration')?.value ? 'Jahr' : 'Jahre'
    }
    if (
      field === 'sponsoringValueGrossAmountFirstYear' ||
      field === 'sponsoringValueNetAmountFirstYear' ||
      field === 'sponsoringValueGrossAmountSecondYear' ||
      field === 'sponsoringValueNetAmountSecondYear' ||
      field === 'rateAmount' ||
      field === 'zuschuss' ||
      field === 'hospitality.hospitalityValue'
    ) {
      return '€'
    }
    return ''
  }

  //todo muss anders gelöst werden war eine schnelle Lösung
  getCurrencyFields(): Array<string> {
    return [
      'sponsoringValueGrossAmountFirstYear',
      'sponsoringValueNetAmountFirstYear',
      'sponsoringValueGrossAmountSecondYear',
      'sponsoringValueNetAmountSecondYear',
      'hospitality.hospitalityValue',
      'zuschuss'
    ]
  }

  closeCard(): void {
    this.isCardOpen = !this.isCardOpen;
  }

  protected readonly localStorage = localStorage;
  protected readonly JSON = JSON;
}
