<ng-container *transloco="let t">
  <ng-container *ngIf="isPdf">
    <div class="pdf">
      <div class="media-box">
        <div class="pdfBox">
          <p class="font-c2">{{t('common.pdf')}}</p>
          <app-icons [iconSize]="'medium'" [iconName]="'download-icon'" (click)="downloadDocument()"></app-icons>
        </div>
      </div>
      <div class="descriptionPart">
        <app-icon-text-button
          (click)="openFile()"
          [textColor]="'red'"
          [buttonSecondary]="true"
          [buttonText]="label"
          [iconName]="'arrow-right'" [iconSize]="'medium'"></app-icon-text-button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="isText">
    <div class="media-box textBox">
      <ng-container *ngTemplateOutlet="textContent"></ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="isVideo">
    <p class="font-c1 box-margin-right">{{t('dashboard.mediaPart.videoTitle')}}</p>
    <div class="media-box video-container">
      <video controls class="img-fluid" >
        <source src="assets/videos/{{videoName}}" type="video/mp4">
      </video>
    </div>
    <div class="descriptionPart">
      <p class="font-c2">{{t('dashboard.mediaPart.videoDescription')}}</p>
      <app-icon-text-button
        (click)="openVideo()"
        [textColor]="'red'"
        [buttonSecondary]="true"
        [buttonText]="t('buttons.show')"
        [iconName]="'arrow-right'" [iconSize]="'medium'"></app-icon-text-button>
    </div>
  </ng-container>
</ng-container>



