import { Component } from '@angular/core';
import {IconsComponent} from "../../../shared/components/icons/icons.component";
import {TranslocoModule} from "@ngneat/transloco";
import {IconTextButtonComponent} from "../../../shared/components/button/icon-text-button/icon-text-button.component";
import {MediaBoxComponent} from "../../../shared/components/media-box/media-box.component";
import {MatCard, MatCardContent} from "@angular/material/card";
import {NgForOf} from "@angular/common";

@Component({
  selector: 'app-dashboard-how-to-media',
  standalone: true,
  imports: [
    IconsComponent, TranslocoModule, IconTextButtonComponent, MediaBoxComponent, MatCard, MatCardContent, NgForOf
  ],
  templateUrl: './dashboard-how-to-media.component.html',
  styleUrl: './dashboard-how-to-media.component.scss'
})
export class DashboardHowToMediaComponent {
  // noop
  protected readonly Object = Object;
}
