import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslocoDirective} from "@ngneat/transloco";
import {
  IconTextButtonComponent
} from "../../../../shared/components/button/icon-text-button/icon-text-button.component";
import {DataService} from "../../../../core/services/data.service";
import {ActivatedRoute, Router} from "@angular/router";
import {IconsComponent} from "../../../../shared/components/icons/icons.component";
import {DatePipe, NgForOf, NgIf} from "@angular/common";
import {Subscription} from "rxjs";
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell, MatHeaderCellDef,
  MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef,
  MatTable,
  MatTableDataSource
} from "@angular/material/table";
import {SponsoredOverviewDto} from "../../../../api/sponsoredOverviewDto";
import {
  ApplicationStateComponentComponent
} from "../../../../shared/components/application-state-component/application-state-component.component";
import {MatPaginator} from "@angular/material/paginator";
import {TableSortComponent} from "../../../../shared/utils/table-sort/table-sort.component";
import {ApplicantDetailviewTableComponent} from "./applicant-detailview-table/applicant-detailview-table.component";
import {
  SponsoredDetailViewTable
} from "../../sponsored-overview-component/sponsored-detail-view-table/sponsored-detail-view-table";
import {MatCard, MatCardContent} from "@angular/material/card";
import {SponsoredOverviewDtoTableData} from "../../../../api/sponsoredOverviewDtoTableData";

@Component({
  selector: 'app-applicant-detailview-component',
  standalone: true,
  imports: [
    TranslocoDirective,
    IconTextButtonComponent,
    IconsComponent,
    NgIf,
    MatTable,
    ApplicationStateComponentComponent,
    DatePipe,
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatPaginator,
    MatRow,
    MatRowDef,
    TableSortComponent,
    MatHeaderCellDef,
    ApplicantDetailviewTableComponent,
    SponsoredDetailViewTable,
    MatCard,
    MatCardContent,
    NgForOf
  ],
  templateUrl: './applicant-detailview-component.component.html',
  styleUrl: './applicant-detailview-component.component.scss'
})
export class ApplicantDetailviewComponentComponent implements OnInit, OnDestroy {
  private routeSubscription!: Subscription;
  currentPage = 1;

  displayedColumns: string [] = ['responsiblePersonal', 'start', 'end', 'sponsoringValue', 'state'];
  dataSource = new MatTableDataSource<SponsoredOverviewDto>();
  applicantId = '';
  vpName = '';
  careerLevel = '';
  paginationInformation: {total: number, current: number} = {total: 0, current: 0};

  allDataForSelectedApplicant: SponsoredOverviewDtoTableData | null = null;
  partnerCard: Array<{key: string, value: string}> = [];
  addressCard: Array<{key: string, value: string}> = [];

  constructor( private router: Router, private dataService: DataService,  private route: ActivatedRoute) {}

  navigateToApplicantOverview(): void {
    this.router.navigate(['/applicant-overview']);
  }

  ngOnInit() {
    this.routeSubscription = this.route.paramMap.subscribe(params => {
      const state: any = history.state;
      if (state.applicantId) {
        this.applicantId = state.applicantId;
        this.getApplicantDataForSelectedId(state.applicantId);
      }
    });
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  getApplicantDataForSelectedId(sponsoredId: string) {
    this.dataService.getApplicantDataForId(sponsoredId).subscribe((result) => {
      this.initializeData(result);
    });
  }

  private initializeData(data: any): void {
    this.allDataForSelectedApplicant = data.application_list;
    this.applicantId = data.id;
    this.paginationInformation = {total: data.application_list.total, current: data.application_list.current};
    this.vpName = data.firstName + ' ' + data.lastName;
    this.careerLevel = data.career_level

    this.partnerCard.push(
      {key: 'Vertrieb', value: 'ERGO AO'},
      {key: 'RD + Nummer', value: data.firstName + ' ' + data.lastName + ' ' +  data.rd_number}
    );
    this.addressCard.push(
      {key: 'Personalnummer', value: data.personal_number},
      {key: 'Adresse', value: data.street_name + ' ' + data.street_number + '<br>' + data.zip + ' ' + data.city},
    )
  }
}
