<ng-container>
  <div class="spinner-container" *ngIf="!applicationData?.sponsoringValue || isLoading">
    <mat-spinner></mat-spinner>
  </div>

  <ng-container *ngIf="applicationData?.sponsoringValue && !isLoading">
    <ng-container *transloco="let t">
      <app-wizard-header [applicationId]="this.applicationId"
        [viewMode]="true" [status]="this.applicationData?.sponsoringValue?.application_state_label"
                         [title]="editMode ?
                     t('wizardTitles.vkfOverviewCheckTitle',
                     {organisation: this.applicationData?.sponsored?.sponsoredName})
                     : t('wizardTitles.vkfOverviewTitle',
                     {organisation: this.applicationData?.sponsored?.sponsoredName})">
      </app-wizard-header>

      <div class="container">
        <div *ngIf="!editMode else editModeTemplate">
          <mat-card>
            <h3 [innerHTML]="
            this.stateTextService.returnTextForVkf(returnStateValue(), handleFundingText(), '', '', isFundingReduced(), applicationData?.financialAssistance?.zuschuss ).title"></h3>
            <p class="element-space"
               [innerHTML]="this.stateTextService.returnTextForVkf(returnStateValue(), handleFundingText(), applicationData?.sponsoringValue?.created_at, this.getFunding(), isFundingReduced(), applicationData?.financialAssistance?.zuschuss).content"></p>
            <ng-container
              *ngIf="
              applicationData?.financialAssistance?.zuschuss &&
              ((isSponsoringValueMore5000() && isFundingMore5000() && applicationData?.sponsoringValue?.application_state === applicationStateEnum.APPROVED_GLVKF)  ||
              applicationData?.sponsoringValue?.application_state === applicationStateEnum.ZUSCHUSS_APPROVED_GLVKF)">
              <app-glvkf-funding-selection (selectedFundingOption)="getSelectedFundingOption($event)"></app-glvkf-funding-selection>
            </ng-container>


            <div class="buttonGroup">
              <ng-container
                *ngFor="let button of this.stateTextService.returnTextForVkf(returnStateValue(), handleFundingText()).button; let i = index; trackBy: trackByIndex">
                <app-text-button
                  [isButtonReadOnly]="isHeaderButtonDisabled(button.disabled)"
                  (click)="switchMode(button.disabled, i)"
                  [buttonText]="button.text"
                  [isPrimaryButton]="isButtonPrimary(i)">
                </app-text-button>
              </ng-container>
            </div>
          </mat-card>
        </div>
        <app-zuschuss-confirmation-component *ngIf="fundingPositionAfterUpdate() === 4" [applicationId]="applicationId"></app-zuschuss-confirmation-component>


        <ng-container id="funding-documents" *ngIf="Object.keys(fundingFiles.documents).length > 0">
          <app-file-viewer [downloadedFiles]="fundingFiles" [titleIsAccordionStyle]="false"
                           [title]="'Umsatzsteuerbefreiung des Gesponserten'"
                           [purpose]="['Umsatzsteuerbefreiung']"></app-file-viewer>
          <app-file-viewer [downloadedFiles]="fundingFiles" [titleIsAccordionStyle]="false"
                           [title]="'Belegfotos aller werblichen Maßnahmen (Leistungsbestandteile)'"
                           [purpose]="['Leistungsbestandteile']"></app-file-viewer>
          <app-file-viewer [downloadedFiles]="fundingFiles" [titleIsAccordionStyle]="false" [title]="'Rechnungskopien'"
                           [purpose]="['Rechnungskopien']"></app-file-viewer>
          <app-file-viewer [downloadedFiles]="fundingFiles" [titleIsAccordionStyle]="false"
                           [title]="'Zahlungsnachweise über gesamte Brutto-Sponsoring-Summe '"
                           [purpose]="['Zahlungsnachweise']"></app-file-viewer>
          <app-file-viewer [downloadedFiles]="fundingFiles" [titleIsAccordionStyle]="false" [title]="'Sonstige Dateien'"
                           [purpose]="['sonstiges_zuschuss']"></app-file-viewer>
        </ng-container>

        <ng-container *ngIf="showSponsoringDocument()" id="sponsoring-vertrag">
          <app-file-viewer [downloadedFiles]="selectedFiles" [title]="'Sponsoring-Vertrag'"
                           [showSubTitle]="false"
                           [purpose]="['contract', 'signed_contract', 'attachment']"></app-file-viewer>
        </ng-container>

        <ng-container>
          <app-vkf-confirmation-component [applicationId]="applicationId"></app-vkf-confirmation-component>
          <app-zuschuss-confirmation-component *ngIf="fundingPositionAfterUpdate() === 3" [applicationId]="applicationId"></app-zuschuss-confirmation-component>
          <app-glvkf-confirmation-component [applicationId]="applicationId"></app-glvkf-confirmation-component>
          <app-zuschuss-confirmation-component *ngIf="fundingPositionAfterUpdate() === 2" [applicationId]="applicationId"></app-zuschuss-confirmation-component>
          <app-rd-confirmation-component [applicationId]="applicationId"></app-rd-confirmation-component>
          <app-zuschuss-confirmation-component *ngIf="fundingPositionAfterUpdate() === 1" [applicationId]="applicationId"></app-zuschuss-confirmation-component>


          <app-file-viewer *ngIf="!editMode"
                           [downloadedFiles]="selectedFiles" [title]="'Dokumente'" [showSubTitle]="false"
                           [purpose]="['application_form', 'contract_draft']"></app-file-viewer>
        </ng-container>


        <form [formGroup]="commentForm">
          <div *ngFor="let section of sections">
            <ng-container>
              <ng-container *ngIf="section.id === 'financialAssistance'; else defaultKeyValues">
                <app-financial-key></app-financial-key>
              </ng-container>
              <ng-template #defaultKeyValues>
                <app-key-value-card-component *ngIf="section.id!=='dataUpload'"
                                              [isEditable]="false"
                                              [title]="t(section.title)"
                                              [requestedFormGroupName]="section.id"
                                              [data]="applicationData?.[section.dataKey]"
                                              [serverData]="applicationData">
                </app-key-value-card-component>

                <app-file-viewer *ngIf="section.id ==='dataUpload'" [downloadedFiles]="selectedFiles"
                                 [title]="'Daten-Upload'" [showSubTitle]="false"
                                 [purpose]="['sonstiges_antrag']"></app-file-viewer>


              </ng-template>
              <ng-container>
                <ng-container *ngIf="editMode && showCommentField(section.id)" [formGroupName]="'comments'">
                  <h5>Kommentar</h5>
                  <textarea [formControlName]="section.id"
                            placeholder="min. 10 Zeichen"
                            class="col-lg-12 section-space-default commentFieldStyle">
              </textarea>
                  <h5 *ngIf="getCommentsForSection(section.id)">Historie der Kommentare</h5>
                  <ng-container *ngFor="let comment of getCommentsForSection(section.id)">
                    <div class="col-lg-12 element-space">
                      <h5 class="col-lg-12"><b>Von ERGO Sponsoring-Manager</b>  {{ comment.authorName }}
                        am {{ comment.created_at | date:'dd.MM.yyyy' }}</h5>
                      <p class="font-c2">{{ comment.content }}</p>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
          <app-file-viewer *ngIf="editMode " [downloadedFiles]="selectedFiles" [title]="'Antragsansicht'"
                           [showSubTitle]="false" [purpose]="['application_form']"></app-file-viewer>
          <app-file-viewer *ngIf="editMode" [downloadedFiles]="selectedFiles" [title]="'Vertragsentwurf'"
                           [showSubTitle]="false" [purpose]="['contract_draft']"></app-file-viewer>
        </form>

        <app-funding-amount *ngIf="editMode && applicationData?.financialAssistance?.zuschuss" (funding_data)="handleFundingData($event)" (isFundingRulesImplemented)="handleApprovedButtonDisabled($event)"></app-funding-amount>

        <ng-container *ngIf="!editMode">
          <div class="section-space-default">
            <h4>Interne Anmerkungen</h4>

            <textarea [(ngModel)]="internalNote"
                      placeholder="min. 10 Zeichen"
                      class="col-lg-12 element-space commentFieldStyle" [minlength]="10">
            </textarea>

            <app-text-button [isPrimaryButton]="true" [buttonText]="'Speichern'"
                             (click)="saveInteranlNote(internalNote)"></app-text-button>
          </div>

          <div>
            <h4 *ngIf="applicationData?.internalNotes.length > 0">Historie der Internen Anmerkungen</h4>
            <ng-container *ngFor="let note of applicationData?.internalNotes">
              <div class="col-lg-12 element-space">
                <h5 class="col-lg-12"><b>Von ERGO Sponsoring-Manager</b>  {{ note.authorName }}
                  am {{ note.created_at | date:'dd.MM.yyyy' }}</h5>
                <p class="font-c2">{{ note.note }}</p>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <div *ngIf="editMode">
          <div class="col-lg-12 decisionButton">
            <app-text-button [isPrimaryButton]="false" [buttonText]="t('buttonText.rejectApplication')"
                             (click)="openModalForFurtherInformation('rejected')"></app-text-button>
            <app-text-button [isPrimaryButton]="true"
                             [isButtonReadOnly]="correctionsButtonisReadOnly"
                             [buttonText]="t('buttonText.correctionNeeded')"
                             (click)="changeStateToCorrectionsNeeded('correctionNeeded')"></app-text-button>
            <app-text-button [isPrimaryButton]="true" [isButtonReadOnly]="isAprrovedButtonDisabled"
                             [buttonText]="t('buttonText.acceptApplication')"
                             (click)="changeStateToCorrectionsNeeded('accepted')"></app-text-button>
          </div>
        </div>

        <ng-template #editModeTemplate>
          <app-hint [hintDescription]="t('vkf.detailView.hint')"></app-hint>
          <app-hint [hintDescription]="t('vkf.detailView.hintComment')"></app-hint>
        </ng-template>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
