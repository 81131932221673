import {Component, Input} from '@angular/core';
import {NgClass} from "@angular/common";
import {applicationStateEnum} from "../../../api/enum/stateEnum";

@Component({
  selector: 'app-application-state-component',
  standalone: true,
  imports: [
    NgClass
  ],
  templateUrl: './application-state-component.component.html',
  styleUrl: './application-state-component.component.scss'
})
export class ApplicationStateComponentComponent {
  @Input() state: applicationStateEnum = applicationStateEnum.OPEN;

  constructor() {
  }

  getCssForState(): string {
    switch(this.state) {
      case applicationStateEnum.OPEN: return 'open';
      case applicationStateEnum.NEU: return 'open';

      case applicationStateEnum.CORRECTION_NEEDED: return 'correctionNeeded';
      case applicationStateEnum.CORRECTION_VP: return 'correctionNeeded';

      case applicationStateEnum.CORRECTION_CHECK: return 'inWork';
      case applicationStateEnum.CHECK_DONE_VKF: return 'inWork';

      case applicationStateEnum.APPROVED_ADD_CONTRACT: return 'accepted';
      case applicationStateEnum.APPROVAL_NO_CONTRACT: return 'accepted';

      case applicationStateEnum.REJECTED_RD: return 'rejected';
      case applicationStateEnum.REJECTED_OD: return 'zuschuss';
      case applicationStateEnum.REJECTED_GLVKF: return 'rejected';
      case applicationStateEnum.REJECTED_VKF: return 'rejected';


      case applicationStateEnum.CONTRACT_ADDED: return 'send';
      case applicationStateEnum.CONTRACT_CHECK: return 'send';
      case applicationStateEnum.ZUSCHUSS: return 'zuschuss';

      case applicationStateEnum.CHECK_RD: return 'inWork';
      case applicationStateEnum.APPROVED_OD: return 'inWork';
      case applicationStateEnum.APPROVED_OD_LEAD: return 'inWork';
      case applicationStateEnum.CHECK_OD: return 'inWork';
      case applicationStateEnum.CHECK_OD_LEAD: return 'inWork';
      case applicationStateEnum.CHECK_VKF: return 'inWork';
      case applicationStateEnum.CHECK_GLVKF: return 'inWork';

      case applicationStateEnum.DONE: return 'final';
      default: return '';
    }
  }
}
