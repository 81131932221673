<ng-container *transloco="let t">
  <div class="spinner-container" *ngIf="!applicationData">
    <mat-spinner></mat-spinner>
  </div>
  <ng-container *ngIf="applicationData">
    <app-wizard-header [viewMode]="true" [status]="this.applicationData?.sponsoringValue?.application_state_label"
                       [title]="editMode ?
                     t('wizardTitles.vkfOverviewCheckTitle',
                     {organisation: this.applicationData?.sponsored.sponsoredName})
                     : t('wizardTitles.vkfOverviewTitle',
                     {organisation: this.applicationData?.sponsored.sponsoredName})
">
    </app-wizard-header>
    <div class="container">
      <mat-card>
        <app-icons class="icon" *ngIf="this.applicationData?.sponsoringValue?.application_state === applicationStateEnum.REJECTED_RD_LEITER" [iconName]="'rejection-icon'" [iconSize]="'large'"
                   [circleIcon]="true"></app-icons>
        <app-icons class="icon" *ngIf="this.applicationData?.sponsoringValue?.application_state === applicationStateEnum.APPROVED_RD_LEITER" [iconName]="'check-icon'" [iconSize]="'large'"
                   [circleIcon]="true"></app-icons>

        <ng-container *ngIf="this.stateTextService.returnTextForRDLeiter(this.applicationData?.sponsoringValue?.application_state_label).title !== ''">

        </ng-container>
        <h3 [innerHTML]="this.stateTextService.returnTextForRDLeiter(this.applicationData?.sponsoringValue?.application_state).title"></h3>
        <p class="element-space" [innerHTML]=" this.stateTextService.returnTextForRDLeiter(this.applicationData?.sponsoringValue?.application_state, applicationData?.sponsoringValue?.sponsoringStartDate).content"></p>
      </mat-card>


      <app-file-viewer [downloadedFiles]="selectedFiles"
                       [title]="'Dokumente'" [showSubTitle]="false"
                       [purpose]="['application_form', 'contract_draft']"></app-file-viewer>
      <form [formGroup]="form">
        <div *ngFor="let section of sections">
          <ng-container *ngIf="section.id === 'financialAssistance'; else defaultKeyValues">
            <app-financial-key></app-financial-key>
          </ng-container>

          <ng-template #defaultKeyValues>

            <app-file-viewer *ngIf="section.id === 'dataUpload'"
                             [downloadedFiles]="selectedFiles"
                             [title]="'Daten-Upload'" [showSubTitle]="false"
                             [purpose]="['sonstiges_antrag']">
            </app-file-viewer>

            <app-key-value-card-component
              *ngIf="section.id !== 'dataUpload'"
              [isEditable]="false"
              [title]="t(section.title)"
              [requestedFormGroupName]="section.id"
              [data]="applicationData?.[section.dataKey]"
              [serverData]="applicationData">
            </app-key-value-card-component>
          </ng-template>

        </div>
      </form>

      <ng-container *ngIf="(this.applicationData?.sponsoringValue?.application_state === applicationStateEnum.CHECK_VKF || this.applicationData?.sponsoringValue?.application_state === applicationStateEnum?.SPONSORING_APPROVED_VKF)
      else navigateDashboard">
        <div class="col-lg-12 buttonGroupSave">
          <app-text-button [isPrimaryButton]="false" [buttonText]="t('buttonText.rejectApplicationRD')"
                           (click)="openRejectionModal('rejected')"></app-text-button>
          <app-text-button [isPrimaryButton]="true" [buttonText]="t('buttonText.acceptApplicationRD')"
                           (click)="acceptSponsoring('accepted')"></app-text-button>
        </div>
      </ng-container>
      <ng-template #navigateDashboard>
        <div class="col-lg-12 buttonGroup">
          <app-text-button [isPrimaryButton]="true" [buttonText]="t('buttonText.dashboard')"
                           (click)="navigateToDashboard()"></app-text-button>
        </div>
      </ng-template>
    </div>
  </ng-container>

</ng-container>
