<div *ngIf="this.form && this.form.get('sponsoringValue.sponsoringDuration')?.value === 2">
  <ng-container [formGroup]="form" *transloco="let t">
    <div class="item" >
      <div class="key-value-pair row">
        <div class="key col-lg-6">{{ t('formFieldMapping.financialAssistance.payment.labelSecondYear')}}</div>
        <div class="value col-lg-6">
          {{t('formFieldMapping.financialAssistance.payment.' + (this.form.get('financialAssistance.secondYear.fullPayed')?.value  ? 'fullPayed' : 'payedInRates')) }}</div>
      </div>
    </div>
    <div class="item" *ngIf="!this.form.get('financialAssistance.secondYear.fullPayed')?.value ">
      <div class="key-value-pair row">
        <div class="key col-lg-6">
          {{ t('formFieldMapping.financialAssistance.payment.rateAmount') }}
        </div>
        <div class="value col-lg-6" >{{ this.form.get('financialAssistance.secondYear.rateAmount')?.value }}</div>
        </div>
      </div>


    <ng-container *ngFor="let rate of this.form.get('financialAssistance.secondYear.rates')?.value; let i = index">
      <div class="item">
        <h5 *ngIf="!this.form.get('financialAssistance.secondYear.fullPayed')?.value">{{t('formFieldMapping.financialAssistance.rates.' + (i+1)) }}</h5>

        <div *ngIf="!this.form.get('financialAssistance.secondYear.fullPayed')?.value" class="key-value-pair row">
          <div  class="key col-lg-6">{{t('formFieldMapping.financialAssistance.grossAmount')}}</div>
          <div class="value col-lg-6">{{ formatSponsoringValue(rate.value) }} €</div>
        </div>
        <div class="key-value-pair row">
          <div class="key col-lg-6">{{assistanceDateLabel(this.form.get('financialAssistance.secondYear.fullPayed')?.value)}}</div>
          <div class="value col-lg-6">{{ rate.date | date: 'dd.MM.YYYY'}}</div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

