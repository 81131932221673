<ng-container *transloco="let t" [formGroup]="form">
    <h3 class="subtitle">{{t('wizard.financial.subtitle2')}}</h3>
    <ng-container formGroupName="financialAssistance" id="zuschuss-radio">
      <p class="font-c2">{{ t('wizard.financial.zuschuss.application') }}</p>
      <mat-radio-group formControlName="zuschuss" (change)="changeZuschuss($event)">
        <mat-radio-button [value]="true">{{ t('common.yes') }}</mat-radio-button>
        <mat-radio-button [value]="false">{{ t('common.no') }}</mat-radio-button>
      </mat-radio-group>
    </ng-container>
    <app-hint *ngIf="!editMode" [hintDescription]="t('wizard.financial.zuschuss.hint')"></app-hint>
</ng-container>
