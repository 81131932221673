import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {BehaviorSubject, map, Observable} from "rxjs";
import {API_URLS} from "../../api/_api_config";
import {ApplicationDto} from "../../api/applicationDto";
import {CookieService} from "ngx-cookie-service";
import {environment} from "../../../environments/environment";
import {ApplicationResponse} from "../../pages/dashboard/dashboard.component";
import {UserRoleEnum} from "../../api/enum/userRoleEnum";

@Injectable({
  providedIn: 'root'
})

export class DataService {
  public role = new BehaviorSubject<string>('');

  constructor(private http: HttpClient, private cookieService: CookieService) {
    // changes
  }

  backendURL = environment.apiPath;

  getApplicationById(applicationId: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', this.cookieService.get('user-token'));
    return this.http.get<ApplicationDto>(`${this.backendURL + API_URLS.GET_APPLICATION_BY_ID}${applicationId}`, {headers})
  }

  getAllApplicationsForDashboard(pagination?: number, sorting?: {column: string, direction:string}): Observable<ApplicationResponse> {
    const headers = new HttpHeaders().set('Authorization', this.cookieService.get('user-token').toString());

    const url = pagination ? this.backendURL + API_URLS.GET_ALL_APPLICATIONS_FOR_DASHBOARD + `?page=${pagination}` : this.backendURL + API_URLS.GET_ALL_APPLICATIONS_FOR_DASHBOARD;
    const urlSorted = sorting ?  url + `&sortBy=${sorting?.column}&order=${sorting?.direction}` : url;

    return this.http.get<any>(urlSorted, { headers }).pipe(
      map(response => ({
        applications: response.data,
        total: response.total
      }))
    );
  }

  postApplication(application: any) {
    const headers = new HttpHeaders().set('Authorization', this.cookieService.get('user-token').toString());
    const url = this.backendURL + API_URLS.POST_APPLICATION;
    return this.http.post<ApplicationDto>(url, application, { headers });
  }

  putApplication(application_ID: string, application: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', this.cookieService.get('user-token').toString());
    const url = this.backendURL + API_URLS.PUT_APPLICATION.replace('{application_id}', application_ID);
    return this.http.put<any>(url, {application_ID, ...application}, { headers });
  }

  getAllNotifications() {
    const headers = new HttpHeaders().set('Authorization', this.cookieService.get('user-token').toString() );
    return this.http.get<any>(`${this.backendURL + API_URLS.GET_NOTIFICATIONS}`, {headers})
  }

  deleteNotifications(notificationID: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', this.cookieService.get('user-token').toString());
    const url = this.backendURL + API_URLS.DELETE_NOTIFICATIONS.replace('{notification_ID}', notificationID);
    return this.http.delete<any>(url, { headers });
  }

  getUser(): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', this.cookieService.get('user-token').toString() );
    return this.http.get<any>(`${this.backendURL + API_URLS.GET_USER}`, {headers})
  }

  getUserRole(): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', this.cookieService.get('user-token').toString() );
    return this.http.get<any>(`${this.backendURL + API_URLS.GET_ROLE}`, {headers})
  }

  postComment(application_ID: string, comment: object): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', this.cookieService.get('user-token').toString());
    const url = this.backendURL + API_URLS.POST_COMMENT.replace('{application_ID}', application_ID);
    return this.http.post<any>(url, comment, { headers });
  }

  getCommentsForApplicationById(application_Id: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization',  this.cookieService.get('user-token').toString() );
    const url = this.backendURL + API_URLS.POST_COMMENT.replace('{application_ID}', application_Id);
    return this.http.get<any>(url, {headers})
  }


  changeStateOfApplication(application_ID: string, state: string, rejectionReason: string | null): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', this.cookieService.get('user-token').toString());
    const url = this.backendURL + API_URLS.PUT_UPDATE_STATE.replace('{application_ID}', application_ID);
    return this.http.put<any>(url, {state, rejectionReason}, { headers });
  }

  uploadDocuments(application_ID: string, documents: Array<any>): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', this.cookieService.get('user-token').toString());
    const url = this.backendURL + API_URLS.POST_DOCUMENTS.replace('{application_ID}', application_ID);
    return this.http.post<any>(url, {dataUpload: documents}, { headers });
  }

  deleteDocuments(document_id: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', this.cookieService.get('user-token').toString());
    const url = this.backendURL + API_URLS.DELETE_DOCUMENTS.replace('{document_id}', document_id);
    return this.http.delete<any>(url, { headers });
  }

  getContractDocuments(application_ID: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', this.cookieService.get('user-token').toString());
    const url = this.backendURL + API_URLS.GET_CONTRACT_BY_APPLICATION_ID.replace('{application_id}', application_ID);
    return this.http.get<any>(url, { headers });
  }

  downloadSelectedDocumentByDocumentId(document_id: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', this.cookieService.get('user-token').toString());
    const url = this.backendURL + API_URLS.DOWNLOAD_DOCUMENT_CONTENT.replace('{document_id}', document_id);
    return this.http.get(url, { headers, responseType: 'blob' });
  }

  //
  getZuschussDocuments(application_ID: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', this.cookieService.get('user-token').toString());
    const url = this.backendURL + API_URLS.GET_FUNDING_DOCUMENTS.replace('{application_id}', application_ID);
    return this.http.get<any>(url, { headers });
  }

  postZuschussAmount(application_Id: string, funding: object): Observable<any> {
    const headers = new HttpHeaders().set('Authorization',  this.cookieService.get('user-token').toString() );
    const url = this.backendURL + API_URLS.POST_FUNDING_AMOUNT.replace('{application_id}', application_Id);
    return this.http.post<any>(url, funding, {headers})
  }

  getConfirmations(confirmation_id: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization',  this.cookieService.get('user-token').toString() );
    const url = this.backendURL + API_URLS.GET_CONFIRMATIONS.replace('{confirmation_id}', confirmation_id);
    return this.http.get<any>(url, {headers})
  }


  getStateHistory(application_Id: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization',  this.cookieService.get('user-token').toString() );
    const url = this.backendURL + API_URLS.GET_STATUS_HISTORY.replace('{application_ID}', application_Id);
    return this.http.get<any>(url, {headers})
  }

  postInternalNotes(application_Id: string, note: object): Observable<any> {
    const headers = new HttpHeaders().set('Authorization',  this.cookieService.get('user-token').toString() );
    const url = this.backendURL + API_URLS.POST_ITERNAL_NOTE.replace('{application_ID}', application_Id);
    return this.http.post<any>(url, note, {headers})
  }

  postInternalNoteApplicantOverview(sponsored_id: string, note: object): Observable<any> {
    const headers = new HttpHeaders().set('Authorization',  this.cookieService.get('user-token').toString() );
    const url = this.backendURL + API_URLS.SAVE_INTERNAL_NOTE_SPONSORED_OVERVIEW.replace('{sponsored_id}', sponsored_id);
    return this.http.post<any>(url, note, {headers})
  }

  getAllSponsoredData(pagination?: number, sorting?: {column: string, direction:string}): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', this.cookieService.get('user-token').toString());
    const url = pagination ? API_URLS.GET_ALL_SPONSORED_DATA + `?page=${pagination}` : API_URLS.GET_ALL_SPONSORED_DATA + `?page=${1}` ;
    const urlSorted = sorting ? url + `&sortBy=${sorting?.column}&order=${sorting?.direction}` : url
    return this.http.get<any>(`${this.backendURL + urlSorted}`, {headers})
  }

  getAllApplicationsOverview(pagination?: number, sorting?: {column: string, direction:string}): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', this.cookieService.get('user-token').toString());
    const url = pagination ? API_URLS.GET_ALL_APPLICATIONS_FOR_OVERVIEW + `?page=${pagination}` : API_URLS.GET_ALL_APPLICATIONS_FOR_OVERVIEW + `?page=${1}` ;
    const urlSorted = sorting ? url + `&sortBy=${sorting?.column}&order=${sorting?.direction}` : url
    return this.http.get<any>(`${this.backendURL + urlSorted}`, {headers})
  }

  getAllApplicantOverviewData(pagination?: number, sorting?: {column: string, direction:string}): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', this.cookieService.get('user-token').toString());
    const url = pagination ? API_URLS.GET_ALL_APPLICANTS_OVERVIEW + `?page=${pagination}` : API_URLS.GET_ALL_APPLICANTS_OVERVIEW + `?page=${1}` ;
    const urlSorted = sorting ? url + `&sortBy=${sorting?.column}&order=${sorting?.direction}` : url
    return this.http.get<any>(`${this.backendURL + urlSorted}`, {headers})
  }

  getApplicantDataForId(applicantId: string, pagination?: number, sorting?: {column: string, direction:string}): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', this.cookieService.get('user-token').toString());
    const selectedUrl = this.backendURL + API_URLS.GET_APPLICANT_OVERVIEW_DATA_FOR_ID.replace('{applicant_id}', applicantId);

    const url = pagination ? selectedUrl + `?page=${pagination}` : selectedUrl + `?page=${1}` ;
    const urlSorted = sorting ? url + `&sortBy=${sorting?.column}&order=${sorting?.direction}` : url
    return this.http.get<any>(`${urlSorted}`, {headers})
  }

  getSponsoredDataForId(sponsoringId: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization',  this.cookieService.get('user-token').toString() );
    const url = this.backendURL + API_URLS.GET_SPONSORED_DATA_FOR_ID.replace('{sponsored_id}', sponsoringId);
    return this.http.get<any>(url, {headers})
  }

  getControllingExcelExport(): Observable<any> {
    const headers = new HttpHeaders().set('Authorization',  this.cookieService.get('user-token').toString() );
    const url = this.backendURL + API_URLS.EXCEL_EXPORT;
    return this.http.get<any>(url, {headers})
  }

  getControllingKPI(): Observable<any> {
    const headers = new HttpHeaders().set('Authorization',  this.cookieService.get('user-token').toString() );
    const url = this.backendURL + API_URLS.CONTROLLING_KPI;
    return this.http.get<any>(url, {headers})
  }
}
