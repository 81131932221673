import { NativeDateAdapter } from '@angular/material/core';

export class GermanDateAdapter extends NativeDateAdapter {
  override parse(value: any): Date | null {
    if (typeof value === 'string' && value.indexOf('.') > -1) {
      const str = value.split('.');
      if (str.length < 2 || isNaN(+str[0]) || isNaN(+str[1]) || isNaN(+str[2])) {
        return null;
      }

      let year = str[2];
      if (year.length === 2) {
        year = '20' + year;
      }

      return new Date(Number(year), Number(str[1]) - 1, Number(str[0]), 12);
    }

    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }
}
