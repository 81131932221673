import {Component, OnInit} from '@angular/core';
import {WizardHeaderComponent} from "../../sponsoring-wizard/wizard-header/wizard-header.component";
import {WizardService} from "../../../shared/services/wizard-service/wizard.service";
import {StateTextService} from "../../../shared/services/state-text-service/state-text.service";
import {FormService} from "../../../shared/services/form-service/form.service";
import {DataService} from "../../../core/services/data.service";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {FileUploadService} from "../../../shared/components/file-uploader/file-uploader-service";
import {applicationStateEnum} from "../../../api/enum/stateEnum";
import {FileViewerComponent} from "../../../shared/components/file-viewer/file-viewer.component";
import {DatePipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {TextButtonComponent} from "../../../shared/components/button/text-button/text-button.component";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatError, MatFormField} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {IconService} from "../../../shared/services/IconService";
import {RejectionModalComponent} from "../Modal/rejection-modal/rejection-modal.component";

@Component({
  selector: 'app-contract-check-component',
  standalone: true,
  imports: [
    WizardHeaderComponent,
    FileViewerComponent,
    DatePipe,
    NgForOf,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    TextButtonComponent,
    MatProgressSpinner,
    NgClass
  ],
  templateUrl: './contract-check-component.component.html',
  styleUrl: './contract-check-component.component.scss'
})
export class ContractCheckComponentComponent implements OnInit {
  applicationId = '';
  applicationData: any = null;
  selectedFiles: Array<any>=[];
  contractCheckComment: any;

  comments: { [key: string]: Array<{ author: string; date: string; sonstiges: string }> } = {};

  constructor(public wizardService: WizardService,
              public stateTextService: StateTextService,
              public formService: FormService,
              public dataService: DataService,
              public router: Router,
              public fileUploadService: FileUploadService,
              private fb: FormBuilder,
              private route: ActivatedRoute,
              private iconService: IconService,
              public dialog: MatDialog
  ) {}

  ngOnInit() {
    const state: any = history.state;
    if (state.applicationId) {
      this.applicationId = state.applicationId.toString();
      this.getGeneratedDocumentsForApplication(this.applicationId);
      this.getDataForSelectedApplication(this.applicationId);
      this.getCommentsForContract(this.applicationId);
    }
  }

  getGeneratedDocumentsForApplication(applicationId: string): any {
    this.dataService.getContractDocuments(this.applicationId).subscribe((result) => {
      this.selectedFiles = result;
    });
  }

  getCommentsForContract(applicationId: string): any {
    this.dataService.getCommentsForApplicationById(applicationId).subscribe((result) => {
      this.comments = result;
    });
  }

  getCommentsForSection(section: string): Array<{ authorName: string; created_at: string; content: string }> | null {
    let result: any;
    if(this.comments['comments']){
      Object.entries(this.comments['comments']).forEach(([key, value]) => {
        if(key === section){
          result = value;
        }
      });
    }
    return result;
  }

  getDataForSelectedApplication(applicationId: string): void {
    this.dataService.getApplicationById(applicationId).subscribe((result) => {
      this.applicationData = result;
    });
  }

  rejectContract(): void {
    const dialogRef = this.dialog.open(RejectionModalComponent, {
      width: '920px',
      height:'max-content',
    });
    dialogRef.afterClosed().subscribe( (rejectionReason) =>  {
      if(rejectionReason){
        this.changeStateOfApplication(this.applicationId, 'rejected', rejectionReason);
        this.navigateToDashboard();
      }
    });
  }

  correctionsNeeded(): void {
      const postCommentRequest = {
        contractCheck: {
          comments: [this.contractCheckComment],
        },
      }
      this.dataService.postComment(this.applicationId, postCommentRequest).subscribe({
        next: () => {
          this.dataService.changeStateOfApplication(this.applicationId, 'correctionNeeded', '').subscribe({
            next: () => {
              this.navigateToDashboard();
            },
            error: (err) => {
              console.error('Status konnte nicht geändert werden', err);
            }
          });
          this.navigateToDashboard();
        }
      })
  }

  acceptContract(): void{
    this.changeStateOfApplication(this.applicationId, 'accepted', '');
  }

  changeStateOfApplication(applicationId: string, action:string, rejectionReason:string | null): void {
    this.dataService.changeStateOfApplication(this.applicationId, action, rejectionReason).subscribe({
      next: () => {
        this.navigateToDashboard();
      },
      error: (err) => {
        console.error('Status konnte nicht geändert werden', err);
      }
    });
  }

  navigateToDashboard() {
    this.router.navigate(['/dashboard']);
  }

  isCorrectionsButtonReadonly(): boolean {
    return !this.contractCheckComment || this.contractCheckComment.length < 10;
  }

  protected readonly applicationStateEnum = applicationStateEnum;
  protected readonly JSON = JSON;
}
