import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input, OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {WizardService} from "../../../shared/services/wizard-service/wizard.service";
import {FormService} from "../../../shared/services/form-service/form.service";
import {TextButtonComponent} from "../../../shared/components/button/text-button/text-button.component";
import {TranslocoDirective} from "@ngneat/transloco";
import {WizardHeaderComponent} from "../wizard-header/wizard-header.component";
import {WizardFooterComponent} from "../wizard-footer/wizard-footer.component";
import {MatCheckbox} from "@angular/material/checkbox";
import {FormBuilder, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {StepperComponent} from "../../../shared/components/stepper/stepper.component";
import {MatError} from "@angular/material/form-field";
import {NgClass, NgIf} from "@angular/common";
import {ValidationService} from "../../../shared/services/validation-service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-wizard-sponsoring-consent',
  standalone: true,
  imports: [
    TextButtonComponent,
    TranslocoDirective,
    WizardHeaderComponent,
    WizardFooterComponent,
    MatCheckbox,
    ReactiveFormsModule,
    StepperComponent,
    MatError,
    NgIf,
    NgClass
  ],
  templateUrl: './consent.component.html',
  styleUrl: './consent.component.scss'
})
export class ConsentComponent implements OnInit, OnDestroy, OnChanges {
  @Input() wizardView = true;
  @Output() checkboxValid = new EventEmitter<boolean>();
  @Input() form: FormGroup =  this.formService.buildForm();

  private subscriptions = new Subscription();

  constructor(public wizardService: WizardService,
              public validationService: ValidationService,
              public formService: FormService,
              private cdRef: ChangeDetectorRef,
              private fb: FormBuilder) {
    // noop
  }

  ngOnInit(): void {
    this.formService.initializeForm(this.form);
    const checkboxSub = this.form.get('consent')?.valueChanges.subscribe(() => {
      this.isCheckboxValid();
    });
    this.subscriptions.add(checkboxSub)
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['form']) {
      this.formService.initializeForm(this.form);
      this.cdRef.detectChanges();
    }
  }

  checkboxError(controlName: string): string {
    const formControl = this.form.get('consent.' + controlName);
    if(formControl?.touched && formControl?.hasError('mustBeTrue')) {
      return 'error'
    }
    return ''
  }

  isCheckboxValid(): void {
    const consents: any = this.form.get('consent')?.value;

    for (const key in consents) {
      if (key !== 'gremium' && consents[key] !== true) {
        this.checkboxValid.emit(false);
        return;
      }
    }
    this.checkboxValid.emit(true);
  }
}
