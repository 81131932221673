import {Component, OnInit} from '@angular/core';
import {WizardService} from "../../../shared/services/wizard-service/wizard.service";
import {FormService} from "../../../shared/services/form-service/form.service";
import {TranslocoModule} from "@ngneat/transloco";
import {WizardHeaderComponent} from "../wizard-header/wizard-header.component";
import {WizardFooterComponent} from "../wizard-footer/wizard-footer.component";
import {
  ApplicationStateComponentComponent
} from "../../../shared/components/application-state-component/application-state-component.component";
import {DatePipe} from "@angular/common";
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow, MatRowDef, MatTable
} from "@angular/material/table";
import {
  KeyValueCardComponentComponent
} from "../../../shared/components/key-value-card-component/key-value-card-component.component";
import {StepperComponent} from "../../../shared/components/stepper/stepper.component";
import {HintComponent} from "../../../shared/components/hint/hint.component";

@Component({
  selector: 'app-wizard-sponsoring-confirmation',
  standalone: true,
  imports: [TranslocoModule, WizardHeaderComponent, WizardFooterComponent, ApplicationStateComponentComponent, DatePipe, MatCell, MatCellDef, MatColumnDef, MatHeaderCell, MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef, MatTable, KeyValueCardComponentComponent, StepperComponent, HintComponent],
  templateUrl: './confirmation.component.html',
  styleUrl: './confirmation.component.scss'
})
export class ConfirmationComponent implements OnInit {
  form = this.formService.buildForm();

  constructor(public wizardService: WizardService, public formService: FormService) {
    // noop
  }

  ngOnInit() {
    this.formService.initializeForm(this.form);
  }
}
