import { Component } from '@angular/core';
import {TextButtonComponent} from "../../../shared/components/button/text-button/text-button.component";
import {Router} from "@angular/router";
import {NavigationComponent} from "../../../shared/components/navigation/navigation.component";
import {AuthenticationService} from "../../../core/services/authentication.service";
import {environment} from "../../../../environments/environment";
import {NgClass, NgIf} from "@angular/common";

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    TextButtonComponent,
    NavigationComponent,
    NgIf,
    NgClass
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  isProduction = environment.apiPath === 'https://mein-regionales-sponsoring.ergo.de'

  constructor(private router: Router, private authenticationService: AuthenticationService) {
    // noop
  };

  navigateToDashboard():void {
    localStorage.removeItem('form-data');
    this.router.navigate(['/dashboard']);
  }

  logout(): void {
    this.authenticationService.deleteToken();
  }
}
