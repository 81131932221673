import {Component, Input, input} from '@angular/core';
import {MatButtonModule} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {IconService} from "../../../services/IconService";
import {NgClass, NgIf} from "@angular/common";

@Component({
  selector: 'app-icon-text-button',
  standalone: true,
  imports: [MatButtonModule, MatIcon, NgIf, NgClass],
  templateUrl: './icon-text-button.component.html',
  styleUrl: './icon-text-button.component.scss'
})
export class IconTextButtonComponent {
  @Input() buttonSecondary = false;
  @Input() buttonLeft = false;
  @Input() buttonText = '';
  @Input() iconSize = '';
  @Input() iconName = '';
  @Input() textColor = '';
  @Input() iconColor = '';
  @Input() backgroundColor = '';


  @Input() isDisabled = false;
  @Input() class = '';


  constructor(private iconService: IconService) {
    // service muss da sein
  }
}
