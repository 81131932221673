import {Component, OnDestroy, OnInit} from "@angular/core";
import {DataService} from "../../core/services/data.service";
import {IconTextButtonComponent} from "../../shared/components/button/icon-text-button/icon-text-button.component";
import {AsyncPipe, NgForOf, NgIf, NgTemplateOutlet} from "@angular/common";
import {TranslocoModule} from "@ngneat/transloco";
import {IconsComponent} from "../../shared/components/icons/icons.component";
import {Alert} from "../../shared/components/alert/alert";
import {AddNewApplicationComponent} from "./new-application/add-new-application.component";
import {ApplicationOverviewTableComponent} from "./application-overview/application-overview-table.component";
import {HintComponent} from "../../shared/components/hint/hint.component";
import {DashboardHowToMediaComponent} from "./how-to-media/dashboard-how-to-media.component";
import {MatCard} from "@angular/material/card";
import {LoginComponent} from "../login/login.component";
import {StepperComponent} from "../../shared/components/stepper/stepper.component";
import {MatPaginator, MatPaginatorIntl, PageEvent} from "@angular/material/paginator";
import {NotificationDto} from "../../api/notificationDto";
import {ApplicationBasicDto} from "../../api/applicationBasicDto";
import {CustomMatPaginatorIntl} from "../../shared/utils/custom-paginator-intl";
import {Subscription} from "rxjs";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {TextButtonComponent} from "../../shared/components/button/text-button/text-button.component";
import {
  SponsoredDetailViewTable
} from "../sub-pages/sponsored-overview-component/sponsored-detail-view-table/sponsored-detail-view-table";


export interface ApplicationResponse {
  applications: ApplicationBasicDto[];
  total: number;
}

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    IconTextButtonComponent,
    NgIf,
    TranslocoModule,
    AsyncPipe,
    NgTemplateOutlet,
    IconsComponent,
    Alert,
    AddNewApplicationComponent,
    ApplicationOverviewTableComponent,
    HintComponent,
    DashboardHowToMediaComponent,
    MatCard,
    Alert,
    LoginComponent,
    StepperComponent,
    NgForOf,
    MatPaginator,
    MatProgressSpinner,
    TextButtonComponent,
    SponsoredDetailViewTable
  ],
  providers: [{provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl}],

  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})

export class DashboardComponent implements OnInit, OnDestroy {
  allApplications: Array<ApplicationBasicDto> = [];
  allNotifications: Array<NotificationDto> = [];

  totalApplications = 0;

  currentPage = 1;
  userRole = '';
  isLoading = false;
  sortOrder : {column: string, direction: string} = {column: '', direction: ''};


  private subscriptions = new Subscription();

  constructor(public dataService: DataService) {
    // noop
  }

  ngOnInit() {
    localStorage.removeItem('form-data');
    this.getUserRole();
    this.getBasicApplications(1)

    const notificationSub = this.dataService.getAllNotifications().subscribe((result) => {
      this.allNotifications = result;
    });
    this.subscriptions.add(notificationSub);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getBasicApplications(page: number, sorting?: { column: string, direction: string }) {
    this.isLoading = true;
    const applicationsDataSub = this.dataService.getAllApplicationsForDashboard(page, sorting).subscribe((result: ApplicationResponse) => {
      this.allApplications = result.applications;
      this.totalApplications = result.total;
      this.isLoading = false;
    });
    this.subscriptions.add(applicationsDataSub);
  }

  onPageChange(event: PageEvent) {
    this.isLoading = true;
    const pageIndex = event.pageIndex + 1;
    const applicationsDataSub = this.dataService.getAllApplicationsForDashboard(pageIndex, this.sortOrder).subscribe((result: ApplicationResponse) => {
      this.allApplications = result.applications;
      this.totalApplications = result.total;
      this.isLoading = false;
    });
    this.subscriptions.add(applicationsDataSub);
  }

  sortValues(event: { column: string, direction: string }): void {
    const applicationsDataSub = this.dataService.getAllApplicationsForDashboard(this.currentPage, event).subscribe((result: ApplicationResponse) => {
      this.allApplications = result.applications;
      this.totalApplications = result.total;
      this.isLoading = false;
    });
    this.subscriptions.add(applicationsDataSub);
    this.sortOrder = event;
  }

  private getUserRole(): void {
   this.isLoading = true;
   const userRoleSub =  this.dataService.getUserRole().subscribe((result) => {
     this.isLoading = false;
     this.userRole = result.role;
    });
   this.subscriptions.add(userRoleSub);
  }

  protected readonly JSON = JSON;
}
