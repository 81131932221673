import {Component, OnInit} from '@angular/core';
import {XlsxExportService} from "../../../shared/services/xlsx-export/xlsx-export.service";
import {DataService} from "../../../core/services/data.service";
import {TextButtonComponent} from "../../../shared/components/button/text-button/text-button.component";
import {Subscription} from "rxjs";
import {IconTextButtonComponent} from "../../../shared/components/button/icon-text-button/icon-text-button.component";
import {NgForOf} from "@angular/common";
import {ControllingKPIDto} from "../../../api/controllingKPIDto";
import {MatCard, MatCardContent, MatCardTitle} from "@angular/material/card";
import {TranslocoDirective} from "@ngneat/transloco";

@Component({
  selector: 'app-controlling',
  standalone: true,
  imports: [
    TextButtonComponent,
    IconTextButtonComponent,
    NgForOf,
    MatCard,
    MatCardTitle,
    MatCardContent,
    TranslocoDirective
  ],
  templateUrl: './controlling.component.html',
  styleUrl: './controlling.component.scss'
})
export class ControllingComponent implements OnInit {
  dataToExport: any;
  fileName: string = '';
  kpiCards: ControllingKPIDto = {} as ControllingKPIDto;


  private subscriptions = new Subscription();
  protected readonly Object = Object;

  constructor(private excelExportService: XlsxExportService, private dataService: DataService) {
    // noop
  }

  ngOnInit() {
    const kpiSub = this.dataService.getControllingKPI().subscribe((result) => {
      this.kpiCards = result;
    });

    const excelSub = this.dataService.getControllingExcelExport().subscribe((result) => {
      this.dataToExport = result;
      this.fileName = this.dataToExport[0]['Vorname Vertriebspartner'] + '_' + this.dataToExport[0]['Nachname Vertriebspartner'] + this.dataToExport[0]['Personalnummer Vertriebspartner'];
    });

    this.subscriptions.add(excelSub);
    this.subscriptions.add(kpiSub);
  }

  exportExcel(): void {
    this.excelExportService.buttonClicked(this.dataToExport, this.fileName);
  }

  showUnit(key: string): string {
    const euroFields = [
      'sponsoringSumTotalCurrentYear',
      'fundingSumTotalLastYear',
      'sponsoringSumTotalLastYear',
      'sponsoringSumGreater20000',
      'acceptedFundingCurrentYear'
    ];

    return euroFields.includes(key) ? '€' : '';
  }
}
