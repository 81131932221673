import {Component, OnInit} from '@angular/core';
import {
  KeyValueCardComponentComponent
} from "../../../shared/components/key-value-card-component/key-value-card-component.component";
import {TextButtonComponent} from "../../../shared/components/button/text-button/text-button.component";
import {TranslocoDirective} from "@ngneat/transloco";
import {WizardHeaderComponent} from "../../sponsoring-wizard/wizard-header/wizard-header.component";
import {WizardService} from "../../../shared/services/wizard-service/wizard.service";
import {FormService} from "../../../shared/services/form-service/form.service";
import {DataService} from "../../../core/services/data.service";
import {ActivatedRoute, Router} from "@angular/router";
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule
} from "@angular/forms";
import {MatCard} from "@angular/material/card";
import {StateTextService} from "../../../shared/services/state-text-service/state-text.service";
import {NgForOf, NgIf} from "@angular/common";
import {FileViewerComponent} from "../../../shared/components/file-viewer/file-viewer.component";
import {MatDialog} from "@angular/material/dialog";
import {applicationStateEnum} from "../../../api/enum/stateEnum";
import {IconsComponent} from "../../../shared/components/icons/icons.component";
import {
  FinancialKeyComponent
} from "../../../shared/components/financial-assistance-table-component/financial-key/financial-key.component";
import {
  GlvkfConfirmationComponentComponent
} from "../glvkf-confirmation-component/glvkf-confirmation-component.component";
import {RdConfirmationComponentComponent} from "../rd-confirmation-component/rd-confirmation-component.component";
import {
  ZuschussConfirmationComponentComponent
} from "../zuschuss-confirmation-component/zuschuss-confirmation-component.component";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MediaBoxComponent} from "../../../shared/components/media-box/media-box.component";


@Component({
  selector: 'app-od-vorpruefer-detailoverview',
  standalone: true,
  imports: [
    KeyValueCardComponentComponent,
    TextButtonComponent,
    TranslocoDirective,
    WizardHeaderComponent,
    ReactiveFormsModule,
    MatCard,
    NgForOf,
    NgIf,
    FormsModule,
    FileViewerComponent,
    IconsComponent,
    FinancialKeyComponent,
    GlvkfConfirmationComponentComponent,
    RdConfirmationComponentComponent,
    ZuschussConfirmationComponentComponent,
    MatProgressSpinner,

    MediaBoxComponent,
  ],
  templateUrl: './od-vorpruefer-detailoverview.component.html',
  styleUrl: './od-vorpruefer-detailoverview.component.scss'
})
export class OdVorprueferDetailoverviewComponent implements OnInit {
  form = this.formService.buildForm();
  editMode = false;
  applicationId = '';
  applicationData: any = null;
  fundingFiles: {documents: object} = {documents: {}}

  sections = [
    { id: 'sponsoringValue', title: 'keyValueTitles.sponsoringValue', dataKey: 'sponsoringValue' },
    { id: 'applicant', title: 'keyValueTitles.applicant', dataKey: 'applicant' },
    { id: 'sponsored', title: 'keyValueTitles.sponsored', dataKey: 'sponsored' },
    { id: 'financialAssistance', title: 'keyValueTitles.financialAssistance', dataKey: 'financialAssistance' },
    { id: 'entitlements', title: 'keyValueTitles.entitlements', dataKey: 'entitlements' },
    { id: 'dataUpload', title: 'keyValueTitles.dataUpload', dataKey: 'dataUpload' }
  ];

  constructor(public wizardService: WizardService,
              public stateTextService: StateTextService,
              public formService: FormService,
              public dataService: DataService,
              public router: Router,
              private fb: FormBuilder,
              private route: ActivatedRoute,
              public dialog: MatDialog
  ) {
  }

  selectedFiles: Array<any>=[];

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const state: any = history.state;
      if(state.applicationId){
        this.applicationId = state.applicationId.toString();
        this.getDataForSelectedApplication(state.applicationId);
        this.getZuschussDocuments();
        this.getGeneratedDocumentsForApplication(state.applicationId);
      }
    });
  }

  getZuschussDocuments(): any {
    this.dataService.getZuschussDocuments(this.applicationId).subscribe((result) => {
      this.fundingFiles = result;
    });
  }

  getGeneratedDocumentsForApplication(applicationId: string): any {
    this.dataService.getContractDocuments(this.applicationId).subscribe((result) => {
      this.selectedFiles = result;
    });
  }

  getDataForSelectedApplication(applicationId: string): void {
    this.dataService.getApplicationById(applicationId).subscribe((result) => {
      this.applicationData = result;
      this.form.patchValue(result);
    });
  }

  changeState(reason: '', action: string): void {
    this.dataService.changeStateOfApplication(this.applicationId, action, reason).subscribe({
      next: () => {
        this.navigateToDashboard();
      },
      error: (err) => {
        console.error('Status konnte nicht geändert werden', err);
      }
    });
  }

  navigateToDashboard(): void {
    this.router.navigate(['/dashboard']);
  }



  protected readonly applicationStateEnum = applicationStateEnum;
    protected readonly JSON = JSON;
}
