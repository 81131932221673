<ng-container *transloco="let t">
  <div class="spinner-container" *ngIf="!applicationData">
    <mat-spinner></mat-spinner>
  </div>
  <ng-container *ngIf="applicationData">
    <app-wizard-header
      [viewMode]="true"
      [status]="this.applicationData?.sponsoringValue?.application_state_label"
      [descriptionOrganisation]="editMode ? this.applicationData?.sponsored.sponsoredName : ''"
      [title]="editMode ? t('wizardTitles.vkfEditTitle') : t('wizardTitles.vkfOverviewTitle', {organisation: this.applicationData?.sponsored.sponsoredName})">
    </app-wizard-header>
    <div class="container">
      <div *ngIf="!editMode">
        <app-special-case-cards [applicationId]="applicationId"
                                [applicationData]="applicationData"></app-special-case-cards>
        <mat-card *ngIf="showActionCard()">
          <h3 [innerHTML]="this.stateTextService.returnTextForApplicant(this.returnStateValue(), '', '', isFundingReduced()).title"></h3>
          <p class="element-space"
             [innerHTML]="this.stateTextService.returnTextForApplicant(this.returnStateValue(),  '', this.getFunding(), this.applicationData?.sponsoringValue?.is_reduced_funding_amount).content"></p>

          <div class="buttonGroup">
            <ng-container
              *ngFor="let button of this.stateTextService.returnTextForApplicant(this.returnStateValue()).button; trackBy:trackByIndex">
              <app-text-button
                [isButtonReadOnly]="button.disabled"
                (click)="returnClickEvent(button.clickEvent)"
                [buttonText]="button.text"
                [isPrimaryButton]="button.primary">
              </app-text-button>
            </ng-container>
          </div>
        </mat-card>
      </div>
      <app-zuschuss-confirmation-component [applicationId]="applicationId"></app-zuschuss-confirmation-component>
      <ng-container id="funding-documents" *ngIf="fundingFiles && fundingFiles.documents">
        <app-file-viewer [downloadedFiles]="fundingFiles" [titleIsAccordionStyle]="false"
                         [title]="'Umsatzsteuerbefreiung des Gesponserten'"
                         [purpose]="['Umsatzsteuerbefreiung']"></app-file-viewer>
        <app-file-viewer [downloadedFiles]="fundingFiles" [titleIsAccordionStyle]="false"
                         [title]="'Belegfotos aller werblichen Maßnahmen (Leistungsbestandteile)'"
                         [purpose]="['Leistungsbestandteile']"></app-file-viewer>
        <app-file-viewer [downloadedFiles]="fundingFiles" [titleIsAccordionStyle]="false" [title]="'Rechnungskopien'"
                         [purpose]="['Rechnungskopien']"></app-file-viewer>
        <app-file-viewer [downloadedFiles]="fundingFiles" [titleIsAccordionStyle]="false"
                         [title]="'Zahlungsnachweise über gesamte Brutto-Sponsoring-Summe '"
                         [purpose]="['Zahlungsnachweise']"></app-file-viewer>
        <app-file-viewer [downloadedFiles]="fundingFiles" [titleIsAccordionStyle]="false" [title]="'Sonstige Dateien'"
                         [purpose]="['sonstiges_zuschuss']"></app-file-viewer>
      </ng-container>

      <ng-container id="sponsoring-vertrag" *ngIf="showSponsoringDocument()">
        <app-file-viewer [titleIsAccordionStyle]="true" [downloadedFiles]="selectedFiles" [title]="'Sponsoring-Vertrag'"
                         [showSubTitle]="false"
                         [purpose]="['contract', 'signed_contract', 'attachment']"></app-file-viewer>
      </ng-container>

      <ng-container id="hints">
        <app-hint
          *ngIf=" !editMode && this.applicationData?.sponsoringValue?.application_state === applicationStateEnum.CORRECTION_NEEDED || this.applicationData?.sponsoringValue?.application_state === applicationStateEnum.OPEN"
          [hintDescription]="'Bitte beachten Sie, dass Sie zusätzliche Dateien am Ende der Seite hinzufügen können, falls weitere Anpassungen notwendig sind.'">
        </app-hint>
      </ng-container>

      <ng-container id="confirmations">
        <app-vkf-confirmation-component [applicationId]="applicationId"></app-vkf-confirmation-component>
        <app-glvkf-confirmation-component [applicationId]="applicationId"></app-glvkf-confirmation-component>
        <app-rd-confirmation-component [applicationId]="applicationId"></app-rd-confirmation-component>
      </ng-container>

      <ng-container id="form">
        <form [formGroup]="form">
          <app-file-viewer *ngIf="!editMode" [downloadedFiles]="selectedFiles" [title]="'Dokumente'"
                           [titleIsAccordionStyle]="true" [showSubTitle]="false"
                           [purpose]="['application_form', 'contract_draft']"></app-file-viewer>

          <div *ngFor="let section of sections" [class]="section.id">
            <ng-container>
              <ng-container *ngIf="section.id === 'financialAssistance'; else defaultKeyValues">
                <app-financial-key
                  [marcCorrectionArea]="!!(editMode && getCommentsForSection(section.id))"
                  [isEditable]="isFormEditable()"
                ></app-financial-key>
              </ng-container>

              <ng-template #defaultKeyValues>
                <app-key-value-card-component *ngIf="section.id !== 'dataUpload'"
                                              [isEditable]="isFormEditable(section.id)"
                                              [marcCorrectionArea]="!!(editMode && getCommentsForSection(section.id))"
                                              [title]="t(section.title)"
                                              [data]="applicationData?.[section.dataKey]"
                                              [serverData]="applicationData"
                                              [requestedFormGroupName]="section.dataKey">
                </app-key-value-card-component>
              </ng-template>
              <div *ngIf="section.id === 'dataUpload'"
                   [ngClass]="!!(editMode && getCommentsForSection(section.id)) ? 'marc':''">
                <app-file-viewer [downloadedFiles]="selectedFiles"
                                 [titleIsAccordionStyle]="true"
                                 [title]="'Daten-Upload'"
                                 [purpose]="['sonstiges_antrag']"></app-file-viewer>
                <ng-container *ngIf="showUploadPart()">
                  <!--*ngIf="this.applicationData?.sponsoringValue?.application_state !== applicationData?.appapplicationStateEnum?.APPROVED_VKF">-->
                  <app-file-uploader *ngIf="showRechnungCopy()"
                                     [applicationId]="applicationId"
                                     [purpose]="'Rechnungskopien'"
                                     [description]="'Rechnungskopien'">
                  </app-file-uploader>
                  <app-file-uploader [applicationId]="applicationId"
                                     [purpose]="'sonstiges_antrag'"
                                     [description]="t('common.other')"></app-file-uploader>
                </ng-container>
              </div>

              <div *ngIf="section.id === 'dataUpload' " class="row element-space">
                <div class="col-lg-12 addDataButton" *ngIf="showUploadPart()">
                  <app-text-button
                    (click)="saveDocumentsForApplication()"
                    [isPrimaryButton]="true"
                    [isButtonReadOnly]="fileUploadService.getData().length === 0"
                    buttonText="Weitere Datei(en) speichern"></app-text-button>
                </div>
              </div>
              <div class="col-lg-12 comment-section">
                <h5 *ngIf="getCommentsForSection(section.id)">Kommentare</h5>
                <ng-container *ngFor="let comment of getCommentsForSection(section.id)">
                  <div class="col-lg-12 element-space">
                    <h5 class=" col-lg-12"><b>Von ERGO Sponsoring-Manager</b>  {{ comment.authorName }}
                      am {{ comment.created_at | date:'dd.MM.yyyy' }}</h5>
                    <p class="font-c2">{{ comment.content }}</p>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </form>
      </ng-container>

      <ng-container id="consent">
        <app-wizard-sponsoring-consent [form]="form" (checkboxValid)="isCorrectionButtonEnabled($event)" *ngIf="editMode" [wizardView]="false"></app-wizard-sponsoring-consent>
      </ng-container>

      <ng-container id="media-box">
        <div *ngIf="!editMode" class="col-lg-12 textBox element-space">
          <app-media-box [isText]="true" [textContent]="mediaBoxContent"></app-media-box>
        </div>
      </ng-container>

      <ng-container id="callToActionButtons" *ngIf="editMode">
        <div class="col-lg-12 buttonGroupBetween">
          <app-text-button [isPrimaryButton]="false" [buttonText]="t('buttonText.dashboard')"
                           (click)="navigateToDashboard()"></app-text-button>
          <app-text-button [isPrimaryButton]="true" [buttonText]="t('buttonText.correctionAdded')"
                           [isButtonReadOnly]="!allConsentsCorrect"
                           (click)="updateApplication('accepted')"></app-text-button>
        </div>
      </ng-container>
    </div>

    <ng-template #mediaBoxContent>
      <app-icons class="media-group" [iconColor]="'red'" [iconSize]="'large'" [iconName]="'question-icon'"></app-icons>
      <h5 class="text-center">{{ t('dashboard.mediaPart.helpTitle') }}</h5>
      <a href="mailto:sponsoring.regional@ergo.de"><p
        class="font-c3 text-center">{{ t('dashboard.mediaPart.helpDescription') }}</p></a>
    </ng-template>
  </ng-container>
</ng-container>
