import {Component, Inject} from '@angular/core';
import {FormBuilder, FormsModule} from "@angular/forms";
import {IconTextButtonComponent} from "../../../../shared/components/button/icon-text-button/icon-text-button.component";
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from "@angular/material/datepicker";
import {MatError, MatFormField, MatSuffix} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/autocomplete";
import {MatRadioButton, MatRadioGroup} from "@angular/material/radio";
import {MatSelect} from "@angular/material/select";
import {NgForOf, NgIf, NgSwitchCase} from "@angular/common";
import {TextButtonComponent} from "../../../../shared/components/button/text-button/text-button.component";
import {TranslocoDirective} from "@ngneat/transloco";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-message-modal',
  standalone: true,
  imports: [
    FormsModule,
    MatIcon,
    TextButtonComponent,
    TranslocoDirective,
    NgIf
  ],
  templateUrl: './message-modal.component.html',
  styleUrl: './message-modal.component.scss'
})
export class MessageModalComponent {
  rejectionReason = '';
  title: string = this.data.text !== 'specialCase' ?  'Grund der Ablehnung' : 'Begründung'
  buttonText: string = this.data.text !== 'specialCase' ?  'Vertrag ablehnen' : 'Absenden'
  content: string = this.data.text !== 'specialCase' ?  'Bitte geben Sie den Grund der Ablehnung des Antrags an' : 'Bitte geben Sie den Grund an, wieso Sie dieses Sponsoring nicht empfehlen'

  constructor(public dialogRef: MatDialogRef<MessageModalComponent>,
              public fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  rejectContract() {
    this.dialogRef.close(this.rejectionReason);
  }

  closeDialog() {
    this.dialogRef.close('cancel');
  }
}
