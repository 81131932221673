import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconsComponent } from "../../components/icons/icons.component";
import { IconService } from "../../services/IconService";

@Component({
  selector: 'app-table-sort',
  standalone: true,
  imports: [
    IconsComponent
  ],
  templateUrl: './table-sort.component.html',
  styleUrls: ['./table-sort.component.scss']
})
export class TableSortComponent {
  @Output() sortDirection = new EventEmitter();
  @Input() columnName: string = '';

  sortState: { [key: string]: string } = {};

  constructor(private iconService: IconService) {}

  sortTable(column: string): void {
    this.sortState[column] = this.sortState[column] === 'asc' ? 'desc' : 'asc';
    const sortDirection = this.sortState[column];
    this.sortDirection.emit({direction: sortDirection, column: column});
  }

}
