import {Component, OnInit} from '@angular/core';
import {WizardService} from "../../../shared/services/wizard-service/wizard.service";
import {WizardHeaderComponent} from "../wizard-header/wizard-header.component";
import {TranslocoModule} from "@ngneat/transloco";
import {ReactiveFormsModule} from "@angular/forms";

import {NgIf} from "@angular/common";
import {SponsoringValueOneYearComponent} from "./sponsoring-value-one-year/sponsoring-value-one-year.component";
import {SponsoringValueTwoYearsComponent} from "./sponsoring-value-two-years/sponsoring-value-two-years.component";
import {WizardFooterComponent} from "../wizard-footer/wizard-footer.component";
import {FormService} from "../../../shared/services/form-service/form.service";
import {StepperComponent} from "../../../shared/components/stepper/stepper.component";

@Component({
  selector: 'app-wizard-sponsoring-value',
  standalone: true,
  imports: [
    WizardHeaderComponent,
    TranslocoModule,
    ReactiveFormsModule,
    NgIf,
    SponsoringValueOneYearComponent,
    SponsoringValueTwoYearsComponent,
    WizardFooterComponent,
    StepperComponent
  ],
  templateUrl: './sponsoring-value.component.html',
  styleUrl: './sponsoring-value.component.scss'
})
export class SponsoringValueComponent implements OnInit{
  form = this.formService.buildForm();

  constructor(public wizardService: WizardService, public formService: FormService) {
    // noop
  }

  ngOnInit() {
    this.formService.initializeForm(this.form);
  }

  isSponsoringDurationOneYear(): boolean {
    const duration = this.form.get('sponsoringValue.sponsoringDuration')?.value;
    return duration === 1;
  }
}
