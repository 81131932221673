import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {
    ApplicationStateComponentComponent
} from "../../../../../shared/components/application-state-component/application-state-component.component";
import {DatePipe} from "@angular/common";
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell, MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow, MatRowDef, MatTable, MatTableDataSource
} from "@angular/material/table";
import {MatPaginator, MatPaginatorIntl, PageEvent} from "@angular/material/paginator";
import {TableSortComponent} from "../../../../../shared/utils/table-sort/table-sort.component";
import {SponsoredOverviewDto} from "../../../../../api/sponsoredOverviewDto";
import {Router} from "@angular/router";
import {DataService} from "../../../../../core/services/data.service";
import {elementAt} from "rxjs";
import {SponsoredOverviewDtoTableData} from "../../../../../api/sponsoredOverviewDtoTableData";
import {CustomMatPaginatorIntl} from "../../../../../shared/utils/custom-paginator-intl";

@Component({
  selector: 'app-applicant-detailview-table',
  standalone: true,
  imports: [
    ApplicationStateComponentComponent,
    DatePipe,
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatPaginator,
    MatRow,
    MatRowDef,
    MatTable,
    TableSortComponent,
    MatHeaderCellDef
  ],
  providers: [{provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl}],
  templateUrl: './applicant-detailview-table.component.html',
  styleUrl: './applicant-detailview-table.component.scss'
})
export class ApplicantDetailviewTableComponent implements OnChanges, OnInit {
  @Input() allData: any;
  @Input() applicantId: string = '';
  paginationInformation: {total: number, current: number} = {total: 0, current: 0};
  @Output() pageChanged: EventEmitter<any> = new EventEmitter();
  @Output() sortingChanged: EventEmitter<any> = new EventEmitter();

  currentPage = 1;
  sortOrder : {column: string, direction: string} = {column: '', direction: ''};
  copy_id = '';

  displayedColumns: string [] = ['sponsoredName', 'start', 'end', 'sponsoringValue', 'state'];
  dataSource = new MatTableDataSource<SponsoredOverviewDto>();
  constructor(private router: Router, private dataService: DataService) {}

  ngOnInit(): void{
    this.copy_id = this.applicantId;
  }

  ngOnChanges() {
    if(this.allData){
      this.dataSource = new MatTableDataSource(this.allData.data);
    }
  }

  setSponsoringValue(element: any): string {
    let value = '';
    if(element.taxFree) {
      if(element.sponsoringValueNetAmountSecondYear) {
        value =  `1. Jahr: ${element.sponsoringValueNetAmountFirstYear} € <br> 2. Jahr: ${element.sponsoringValueNetAmountSecondYear} €`
      } else {
        value =  `1. Jahr: ${element.sponsoringValueNetAmountFirstYear} €`
      }
    } else {
      if(element.sponsoringValueGrossAmountSecondYear) {
        value =  `1. Jahr: ${element.sponsoringValueGrossAmountFirstYear} € <br> 2. Jahr: ${element.sponsoringValueGrossAmountSecondYear} €`
      } else {
        value =  `1. Jahr: ${element.sponsoringValueGrossAmountFirstYear} €`
      }
    }
    return value;
  }

  onPageChange(event: PageEvent) {
    const pageIndex = event.pageIndex + 1;
    this.loadMoreData(pageIndex);
  }

  editApplication(row: any) {
    this.router.navigate(['/detailview'], {state: {applicationId: row.id}});
  }


  sortValues(event: { column: string, direction: string }): void {
    this.dataService.getApplicantDataForId(this.copy_id, 1, event).subscribe((result) => {
      this.sortOrder = event;
      if(result){
        this.paginationInformation = result.total;
        this.loadMoreData(this.currentPage);
      }
    });
  }

  loadMoreData(page: number): void {
    this.dataService.getApplicantDataForId(this.copy_id, page, this.sortOrder).subscribe((result) => {
      this.dataSource = new MatTableDataSource(result.application_list.data);
    });
  }

  protected readonly JSON = JSON;
}
