import {Component, Input} from '@angular/core';
import {NgIf, NgTemplateOutlet} from "@angular/common";
import {IconsComponent} from "../icons/icons.component";
import {translate, TranslocoModule} from "@ngneat/transloco";
import {IconTextButtonComponent} from "../button/icon-text-button/icon-text-button.component";

@Component({
  selector: 'app-media-box',
  standalone: true,
  imports: [
    NgIf,
    IconsComponent,
    TranslocoModule,
    NgTemplateOutlet,
    IconTextButtonComponent
  ],
  templateUrl: './media-box.component.html',
  styleUrl: './media-box.component.scss'
})
export class MediaBoxComponent {
  @Input() isVideo = false;
  @Input() videoName = '';
  @Input() label = '';
  @Input() isPdf = false;
  @Input() isText = false;
  @Input() textContent: any;
  @Input() pdfName: string = '';


  openVideo() {
    const videoUrl = 'assets/videos/' + translate('downloadLinks.howToVideo');
    window.open(videoUrl, '_blank');
  }

  openFile() {
    window.open("assets/documents/" + this.pdfName);
  }


  downloadDocument() {
    const pdfFilePath = "assets/documents/" + this.pdfName;

    const link = document.createElement('a');
    link.href = pdfFilePath;
    link.download =  this.label;
    link.click();
  }

  protected readonly open = open;
}
