import { Component, Input, OnChanges, OnInit, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { FormService } from "../../../services/form-service/form.service";
import { ReactiveFormsModule } from "@angular/forms";
import {translate, TranslocoDirective} from "@ngneat/transloco";
import { DatePipe, NgForOf, NgIf } from "@angular/common";

@Component({
  selector: 'app-first-year',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslocoDirective,
    NgIf,
    NgForOf,
    DatePipe
  ],
  templateUrl: './first-year.component.html',
  styleUrls: ['./first-year.component.scss']
})
export class FirstYearComponent implements OnChanges, OnInit {
  @Input() isEdited = false;
  @Input() data: any;

  form = this.formService.buildForm();
  isSponsoringTwoYears = this.form.get('sponsoringValue.sponsoringDuration')?.value === 2;

  constructor(private formService: FormService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    if (!this.data) {
      this.formService.initializeForm(this.form);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.data && changes) {
      this.form = this.formService.initializeFormFromData(this.data);
    }
  }

  updateData(newForm: any) {
    this.form = newForm;
    this.cdr.detectChanges();
  }

  assistanceDateLabel(fullPayed: boolean){
    if(fullPayed){
      return translate('formFieldMapping.financialAssistance.date.full');
    } else {
      return translate('formFieldMapping.financialAssistance.date.rate');
    }
  }

  formatSponsoringValue(value: string) {
    if(value){
      const item = value.replace(/\./g, '').replace(',', '.');
      const numericValue = parseFloat(item);

      if (!isNaN(numericValue)) {
        return new Intl.NumberFormat('de-DE', {
          style: 'decimal',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(numericValue);
      } else {
        return '';
      }
    } else {
      return ''
    }
  }

  protected readonly JSON = JSON;
}
