
<div *ngIf="!applicationData" class="spinner-container">
  <mat-spinner></mat-spinner>
</div>

<ng-container id="contact-check" *ngIf="applicationData">
  <div class="container-wizard container">
    <app-wizard-header [viewMode]="true" [status]="this.applicationData?.sponsoringValue.application_state_label"
                       [descriptionOrganisation]="applicationData?.sponsored.sponsoredName"
                       [title]="'Prüfung von ' + applicationData?.sponsored?.sponsoredName">
    </app-wizard-header>
    <app-file-viewer
                     [titleIsAccordionStyle]="true"
                     [downloadedFiles]="selectedFiles" [title]="'Sponsoring-Vertrag'"
                     [showSubTitle]="false"
                     [purpose]="['contract', 'signed_contract']"></app-file-viewer>

    <div class="col-lg-12 contract-comments">
      <h5>Kommentar</h5>
      <textarea [(ngModel)]="contractCheckComment"
                placeholder="min. 10 Zeichen"
                class="col-lg-12 commentTextField" [minlength]="10"></textarea>
    </div>

    <div class="comment-history col-lg-12" [ngClass]="isCorrectionsButtonReadonly() ? 'invalid' : ''">
      <h5 *ngIf="getCommentsForSection('contractCheck')">Historie der Kommentare</h5>
      <ng-container *ngFor="let comment of getCommentsForSection('contractCheck')">
        <div class="col-lg-12 element-space">
          <h5 class="col-lg-12"><b>Von ERGO Sponsoring-Manager</b>  {{ comment.authorName }}
            am {{ comment.created_at | date:'dd.MM.yyyy' }}</h5>
          <p class="font-c2">{{ comment.content }}</p>
        </div>
      </ng-container>
    </div>

    <div class="col-lg-12 decisionButton">
      <app-text-button [isPrimaryButton]="false" [buttonText]="'Vertrag ablehnen'"
                       (click)="rejectContract()"></app-text-button>
      <app-text-button [isPrimaryButton]="true"
                       [isButtonReadOnly]="isCorrectionsButtonReadonly()"
                       [buttonText]="'Rückgabe zur Korrektur'"
                       (click)="correctionsNeeded()"></app-text-button>
      <app-text-button [isPrimaryButton]="true" [buttonText]="'Vertrag akzeptieren'"
                       (click)="acceptContract()"></app-text-button>
    </div>
  </div>
</ng-container>
