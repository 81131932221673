<div class="container-wizard container" *transloco="let t">

  <div class="spinner-container" *ngIf="!applicationData">
    <mat-spinner></mat-spinner>
  </div>

  <ng-container *ngIf="applicationData">
    <app-wizard-header [viewMode]="true" [status]="this.applicationData?.sponsoringValue.application_state_label"
                       [descriptionOrganisation]="applicationData?.sponsored.sponsoredName"
                       [title]="'Vertrag einreichen'">
    </app-wizard-header>
    <h4 class="element-space" [innerHTML]="'Ihr Sponsoring-Antrag wurde genehmigt. Um die Genehmigung abzuschließen führen Sie bitte die folgenden Schritte durch:'"></h4>


    <div class="step1">
      <h4 class="readHeader">1. Vertag herunterladen</h4>
      <app-file-viewer class="viewerForContractDocuments" [titleIsAccordionStyle]="false" [downloadedFiles]="selectedFiles" [title]="''" [purpose]="['contract']"></app-file-viewer>
    </div>
    <div class="step2">
      <h4 class="readHeader" >2. Vertag handschriftlich unterschreiben</h4>
      <p class="element-space border" [innerHTML]="'Bitte beachten Sie, dass Sie keine handschriftlichen Änderungen hinzufügen dürfen, da der Vertrag sonst unwirksam ist.<br>Bei Fragen wenden Sie sich bitte an das Gruppenpostfach'">.</p>
    </div>
    <div class="step3">
      <h4  class="readHeader">3.Vertrag unterschrieben hochladen</h4>
    </div>

    <app-file-viewer [downloadedFiles]="selectedFiles" [title]="''" [purpose]="['signed_contract']"></app-file-viewer>
    <app-file-uploader [showExample]="false" description="Unterschriebener Vertrag" [purpose]="'signed_contract'"></app-file-uploader>

    <ng-container *ngFor="let comment of getCommentsForSection('contractCheck')">
      <div class="col-lg-12 element-space-top element-space">
        <h5 class="col-lg-12"><b>Von ERGO Sponsoring-Manager</b>  {{ comment.authorName }}
          am {{ comment.created_at | date:'dd.MM.yyyy' }}</h5>
        <p class="font-c2">{{ comment.content }}</p>
      </div>
    </ng-container>

    <div class="col-lg-12 save element-space-top">
      <app-text-button
        [isButtonReadOnly]="false"
        (click)="uploadDocumentsAndChangeState('accepted')"
        [buttonText]="'Vertrag einreichen'"
        [isPrimaryButton]="true">
      </app-text-button>
    </div>
  </ng-container>
</div>


