import {Component, OnChanges, OnInit} from '@angular/core';
import {TranslocoDirective} from "@ngneat/transloco";
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef, MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef,
  MatTable,
  MatTableDataSource
} from "@angular/material/table";
import {TableSortComponent} from "../../../shared/utils/table-sort/table-sort.component";
import {SponsoredDto} from "../../../api/sponsoredDto";
import {MatPaginator, MatPaginatorIntl, PageEvent} from "@angular/material/paginator";
import {DataService} from "../../../core/services/data.service";
import {DatePipe, NgIf} from "@angular/common";
import {
  ApplicationStateComponentComponent
} from "../../../shared/components/application-state-component/application-state-component.component";
import {ActivatedRoute, Router} from "@angular/router";
import {CustomMatPaginatorIntl} from "../../../shared/utils/custom-paginator-intl";
import {EmptyStateGeneralComponent} from "../../../shared/components/empty-state-general/empty-state-general.component";

@Component({
  selector: 'app-applications-overview-component',
  standalone: true,
  imports: [
    TranslocoDirective,
    MatTable,
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    TableSortComponent,
    MatHeaderCellDef,
    MatPaginator,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    DatePipe,
    ApplicationStateComponentComponent,
    NgIf,
    EmptyStateGeneralComponent
  ],
  providers: [{provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl}],
  templateUrl: './applications-overview-component.component.html',
  styleUrl: './applications-overview-component.component.scss'
})
export class ApplicationsOverviewComponentComponent implements OnInit {
  totalApplications = 0;
  currentPage = 1;
  sortOrder : {column: string, direction: string} = {column: '', direction: ''};

  displayedColumns: string [] = ['applicant', 'application', 'createdAt', 'state'];
  dataSource = new MatTableDataSource<SponsoredDto>();

  constructor(private dataService: DataService, private router: Router) {
  }


  ngOnInit() {
    this.dataService.getAllApplicationsOverview().subscribe((result) => {
      this.initializeDataSource(result.data);
      this.totalApplications = result.total;
    });
  }

  loadApplications(pagination: number) {
    this.dataService.getAllApplicationsOverview(pagination, this.sortOrder).subscribe((result) => {
      this.totalApplications = result.total;
      this.initializeDataSource(result.data);
    });
  }


  sortValues(event: { column: string, direction: string }): void {
    this.dataService.getAllApplicationsOverview(1, event).subscribe((result) => {
      if(result){
        this.totalApplications = result.total;
        this.sortOrder = event;
        this.initializeDataSource(result.data);
      }
    });
  }

  onPageChange(event: PageEvent) {
    const pageIndex = event.pageIndex + 1;
    this.loadApplications(pageIndex);
  }

  initializeDataSource(data: Array<SponsoredDto>): void {
    this.dataSource = new MatTableDataSource(data)
  }

  showApplication(row: any) {
    this.router.navigate(['/detailview'], {state: {applicationId: row.id}});
  }


}
