import { Directive, HostListener } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[appNumericInput]'
})
export class NumericFieldDirective {

  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent): void {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ','];

    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
    }

    if (event.key === ',' && (event.target as HTMLInputElement).value.includes(',')) {
      event.preventDefault();
    }
  }

  @HostListener('input', ['$event'])
  onInput(event: any): void {
    event.target.value = event.target.value.replace(/\./g, '');
  }
}
