import {Routes} from '@angular/router';
import {DashboardComponent} from "./pages/dashboard/dashboard.component";
import {SponsoringDurationComponent} from "./pages/sponsoring-wizard/sponsoring-duration/sponsoring-duration.component";
import {SponsoringValueComponent} from "./pages/sponsoring-wizard/sponsoring-value/sponsoring-value.component";
import {SponsoringStartDateComponent} from "./pages/sponsoring-wizard/sponsoring-start-date/sponsoring-start-date.component";
import {SponsoringEndDateComponent} from "./pages/sponsoring-wizard/sponsoring-end-date/sponsoring-end-date.component";
import {ConfirmationComponent} from "./pages/sponsoring-wizard/confirmation/confirmation.component";
import {ApplicantInformationComponent} from "./pages/sponsoring-wizard/applicant-information/applicant-information.component";
import {SponsoredInformationComponent} from "./pages/sponsoring-wizard/sponsored-information/sponsored-information.component";
import {FinancialAssistanceComponent} from "./pages/sponsoring-wizard/financial-assistance/financial-assistance.component";
import {EntitlementsComponent} from "./pages/sponsoring-wizard/entitlements/entitlements.component";
import {ConsentComponent} from "./pages/sponsoring-wizard/consent/consent.component";
import {SponsoringDataUploadComponent} from "./pages/sponsoring-wizard/sponsoring-data-upload/sponsoring-data-upload.component";
import {ConclusionComponent} from "./pages/sponsoring-wizard/conclusion/conclusion.component";
import {NextStepsComponent} from "./pages/sponsoring-wizard/next-steps/next-steps.component";
import {LoginComponent} from "./pages/login/login.component";
import {
  DetailOverviewPagesComponentComponent
} from "./pages/detail-overview-pages/detail-overview-pages-component/detail-overview-pages-component.component";
import {
  ContactDocumentsComponentComponent
} from "./pages/detail-overview-pages/contact-documents-component/contact-documents-component.component";
import {
  ZuschussDocumentUploadComponent
} from "./pages/detail-overview-pages/zuschuss-document-upload/zuschuss-document-upload-component";
import {
  ContractCheckComponentComponent
} from "./pages/detail-overview-pages/contract-check-component/contract-check-component.component";
import {
  ZuschussDocumentOverview
} from "./pages/detail-overview-pages/zuschuss-document-overview/zuschuss-document-overview";
import {SponsoredOverview} from "./pages/sub-pages/sponsored-overview-component/sponsored-overview";
import {
  SponsoredDetailView
} from "./pages/sub-pages/sponsored-overview-component/sponsored-detail-view/sponsored-detail-view";
import {ApplicantOverviewComponent} from "./pages/sub-pages/applicant-overview/applicant-overview-component";
import {
  ApplicationsOverviewComponentComponent
} from "./pages/sub-pages/applications-overview-component/applications-overview-component.component";
import {
  ApplicantDetailviewComponentComponent
} from "./pages/sub-pages/applicant-overview/applicant-detailview-component/applicant-detailview-component.component";
import {ControllingComponent} from "./pages/sub-pages/controlling/controlling.component";


export const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'login', component: LoginComponent },
  { path: 'duration', component: SponsoringDurationComponent},
  { path: 'sponsoring-value', component: SponsoringValueComponent},
  { path: 'start-date', component: SponsoringStartDateComponent},
  { path: 'end-date', component: SponsoringEndDateComponent},
  { path: 'confirmation', component: ConfirmationComponent},
  { path: 'applicant', component: ApplicantInformationComponent},

  { path: 'sponsored', component: SponsoredInformationComponent},
  { path: 'financial-assistance', component: FinancialAssistanceComponent},
  { path: 'entitlements', component: EntitlementsComponent},
  { path: 'consent', component: ConsentComponent},
  { path: 'file-upload', component: SponsoringDataUploadComponent},
  { path: 'conclusion', component: ConclusionComponent},
  { path: 'next-steps', component: NextStepsComponent},

  { path: 'zuschuss-documents', component: ZuschussDocumentUploadComponent },
  { path: 'zuschuss-overview', component: ZuschussDocumentOverview },
  { path: 'contract-documents', component: ContactDocumentsComponentComponent },
  { path: 'contract-check', component: ContractCheckComponentComponent },
  { path: 'detailview', component: DetailOverviewPagesComponentComponent},

  { path: 'sponsored-overview', component: SponsoredOverview},
  { path: 'sponsored-detail-view', component: SponsoredDetailView},
  { path: 'applicant-overview', component: ApplicantOverviewComponent},
  { path: 'applicant-detail-view', component: ApplicantDetailviewComponentComponent},
  { path: 'applications-overview', component: ApplicationsOverviewComponentComponent},
  { path: 'controlling', component: ControllingComponent},
];
