import {Component, Input} from '@angular/core';
import {IconTextButtonComponent} from "../../../shared/components/button/icon-text-button/icon-text-button.component";
import {TranslocoModule} from "@ngneat/transloco";
import {WizardService} from "../../../shared/services/wizard-service/wizard.service";
import {NgIf} from "@angular/common";
import {HintComponent} from "../../../shared/components/hint/hint.component";
import {NavigationComponent} from "../../../shared/components/navigation/navigation.component";
import {
  ApplicationStateComponentComponent
} from "../../../shared/components/application-state-component/application-state-component.component";
import {applicationStateEnum} from "../../../api/enum/stateEnum";
import {Router} from "@angular/router";

@Component({
  selector: 'app-wizard-header',
  standalone: true,
  imports: [
    IconTextButtonComponent,
    TranslocoModule,
    NgIf,
    HintComponent,
    NavigationComponent,
    ApplicationStateComponentComponent
  ],
  templateUrl: './wizard-header.component.html',
  styleUrl: './wizard-header.component.scss'
})
export class WizardHeaderComponent {
  @Input() title = '';
  @Input() descriptionOrganisation = '';
  @Input() status = applicationStateEnum.OPEN;
  @Input() subtitle = '';
  @Input() currentComponent = '';
  @Input() viewMode = false ;

  protected readonly applicationStateEnum = applicationStateEnum;

  constructor(private router: Router, public wizardService: WizardService) {
    // noop
  }

  navigateToDashboard(): void {
    localStorage.removeItem('form-data');
    this.router.navigate(['/dashboard']);
  }

}
