<ng-container *transloco="let t" [formGroup]="form">
  <app-stepper *ngIf="!editMode"></app-stepper>

  <div class="container-wizard">
    <app-wizard-header *ngIf="!editMode" [title]="t('wizard.financial.title')"></app-wizard-header>

    <div class="container" formGroupName="financialAssistance">
      <h3 class="subtitle">{{ t('wizard.financial.subtitle1') }}</h3>

      <div formGroupName="firstYear">
        <div class="row">
          <mat-card class="col-lg-6 col-md-8">
            <mat-card-title class="title">
              <div class="font-cs">Die von Ihnen eingegebene Sponsoring-Summe</div>
            </mat-card-title>
            <mat-card-content>
              <p class="font-c2s">{{ getSponsoringValue('firstYear') }} € </p>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-lg-12 element-space">
          <p class="font-c2">
            {{
              form.get('sponsoringValue.sponsoringDuration')?.value === 1 ?
                'Wie wird die Sponsoring-Summe ausgezahlt?' : 'Wie wird die Sponsoring-Summe im ersten Jahr ausgezahlt?'
            }}</p>
          <mat-radio-group formControlName="fullPayed" (change)="changePaymentMethod($event, 'firstYear', 1)">
            <mat-radio-button [value]="true"><p>{{ t('wizard.financial.payment.full') }}</p></mat-radio-button>
            <mat-radio-button [value]="false"><p>{{ t('wizard.financial.payment.rate') }}</p></mat-radio-button>
          </mat-radio-group>
        </div>
        <ng-container id="firstYearRateAmount">
          <div class="row" *ngIf="!this.form.get('financialAssistance.firstYear.fullPayed')?.value">
            <label class="font-c2 col-lg-12">In wievielen Raten?</label>
            <mat-form-field appearance="fill" class="col-lg-2">
              <mat-select [value]="this.form.get('financialAssistance.firstYear.rateAmount')?.value"
                          (selectionChange)="onSelectRate($event.value, 'firstYear')">
                <mat-option *ngFor="let rate of rates" [value]="rate">
                  {{ rate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </ng-container>

        <ng-container id="firstYearRates">
          <div formArrayName="rates">
            <div *ngFor="let control of listOfRatesFirstYear.controls; let i = index" [formGroupName]="i">
              <div class="col-lg-12 two-element-row element-space">
                <mat-form-field *ngIf="this.form.get('financialAssistance.firstYear.fullPayed')?.value === false"
                                class="col-lg-6">
                  <label class="font-c2">{{ t('formFieldMapping.rate.label.' + (i + 1)) }}</label>
                  <input type="text" placeholder="xxx,xx €" matInput formControlName="value" appCurrencyFormat appNumericInput>
                  <mat-error class="font-c3"
                             *ngIf="this.form.get('financialAssistance.firstYear.rates.' + i + '.value')?.touched &&
                  this.form.get('financialAssistance.firstYear.rates.' + i + '.value')?.invalid">{{ t('error.required') }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="col-lg-6">
                  <label class="font-c2">{{
                      this.form.get('financialAssistance.firstYear.fullPayed')?.value === true ?
                        t('formFieldMapping.rateDate.label.fullPayed') :
                        t('formFieldMapping.rateDate.label.' + (i + 1))
                    }}
                  </label>
                  <input placeholder="TT-MM-YYYY" matInput [matDatepicker]="picker" formControlName="date">
                  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-error class="font-c3"
                             *ngIf="
                             this.form.get('financialAssistance.firstYear.rates.' + i + '.date')?.touched &&
                              this.form.get('financialAssistance.firstYear.rates.' + i + '.date')?.invalid">{{ t('error.required') }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <mat-error class="multiple-field-error element-space"
                 *ngIf="this.form.get('financialAssistance.firstYear.rates')?.touched && this.validationService.validateSponsoringRatesTotal(form, 'firstYear')">
        <p class="font-c2">Die Raten stimmen nicht mit der Gesamtsumme überein. Bitte passen Sie die Raten so an, dass
          sie die Gesamtsumme ergeben.</p>
      </mat-error>

      <ng-container *ngIf="this.form.get('sponsoringValue.sponsoringDuration')?.value === 2">
        <div formGroupName="secondYear">
          <div class="col-lg-12 element-space-top">
            <mat-card class="col-lg-6">
              <mat-card-title class="title">
                <div class="font-cs">Die von Ihnen eingegebene Sponsoring-Summe</div>
              </mat-card-title>
              <mat-card-content>
                <p class="font-c2s">{{ getSponsoringValue('secondYear') }} €</p>
              </mat-card-content>
            </mat-card>
          </div>

          <p class="font-c2">Wie wird die Sponsoring-Summe im zweiten Jahr ausgezahlt?</p>
          <mat-radio-group formControlName="fullPayed" (change)="changePaymentMethod($event, 'secondYear', 2)">
            <mat-radio-button [value]="true"><p>{{ t('wizard.financial.payment.full') }}</p></mat-radio-button>
            <mat-radio-button [value]="false"><p>{{ t('wizard.financial.payment.rate') }}</p></mat-radio-button>
          </mat-radio-group>


          <ng-container id="secondYearRateAmount"
                        *ngIf="!this.form.get('financialAssistance.secondYear.fullPayed')?.value">
            <div class="col-lg-12 section-space-default element-space-top"
                 *ngIf="!this.form.get('financialAssistance.secondYear.fullPayed')?.value">
              <label class="font-c2 col-lg-12 element-space element-space">In wievielen Raten?</label>
              <mat-form-field appearance="fill" class="col-lg-2">
                <mat-select [value]="this.form.get('financialAssistance.secondYear.rateAmount')?.value"
                            (selectionChange)="onSelectRate($event.value, 'secondYear')">
                  <mat-option *ngFor="let rate of rates" [value]="rate">
                    {{ rate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </ng-container>

          <ng-container id="secondYearRates">
            <div formArrayName="rates">
              <div *ngFor="let control of listOfRatesSecondYear.controls; let i = index" [formGroupName]="i">
                <div class="col-lg-12 two-element-row element-space-top element-space">
                  <mat-form-field *ngIf="this.form.get('financialAssistance.secondYear.fullPayed')?.value === false"
                                  class="col-lg-6">
                    <label class="font-c2">{{ t('formFieldMapping.rate.label.' + (i + 1)) }}</label>
                    <input type="text" placeholder="xxx,xx €" matInput formControlName="value" appCurrencyFormat appNumericInput>
                    <mat-error class="font-c3"
                               *ngIf="this.form.get('financialAssistance.secondYear.rates.' + i + '.value')?.touched &&
                  this.form.get('financialAssistance.secondYear.rates.' + i + '.value')?.invalid">{{ t('error.required') }}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field class="col-lg-6">

                    <label class="font-c2">{{
                        this.form.get('financialAssistance.secondYear.fullPayed')?.value === true ?
                          t('formFieldMapping.rateDate.label.fullPayed') :
                          t('formFieldMapping.rateDate.label.' + (i + 1))
                      }}</label>

                    <input placeholder="TT-MM-YYYY" matInput [matDatepicker]="picker" formControlName="date">
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error class="font-c3"
                               *ngIf="
                             this.form.get('financialAssistance.secondYear.rates.' + i + '.date')?.touched &&
                              this.form.get('financialAssistance.secondYear.rates.' + i + '.date')?.invalid">{{ t('error.required') }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <mat-error class="multiple-field-error element-space"
                       *ngIf="this.form.get('financialAssistance.secondYear.rates')?.touched && this.validationService.validateSponsoringRatesTotal(form, 'secondYear')">
              <p class="font-c2">Die Raten stimmen nicht mit der Gesamtsumme überein. Bitte passen Sie die Raten so an,
                dass sie die Gesamtsumme ergeben.</p>
            </mat-error>
          </ng-container>

        </div>
      </ng-container>

      <!-- Other parts -->
      <div id="zuschuss" class="element-space">
        <h3 class="subtitle">{{ t('wizard.financial.subtitle2') }}</h3>
        <ng-container id="zuschuss-radio">
          <p class="font-c2">{{ t('wizard.financial.zuschuss.application') }}</p>
          <mat-radio-group formControlName="zuschuss" (change)="changeZuschuss($event)">
            <mat-radio-button [value]="true">{{ t('common.yes') }}</mat-radio-button>
            <mat-radio-button [value]="false">{{ t('common.no') }}</mat-radio-button>
          </mat-radio-group>
        </ng-container>
      </div>
      <app-hint *ngIf="!editMode" [hintDescription]="t('wizard.financial.zuschuss.hint')"></app-hint>

      <ng-container id="repetition">
        <h3 class="subtitle sectionSpaceDefaultTop">{{ t('wizard.financial.subtitle3') }}</h3>
        <ng-container id="repetition-radio" class="row">
          <div class="col-lg-12">
            <p class="font-c2">{{ t('wizard.financial.repeat.repetition') }}</p>

            <mat-radio-group class="col-lg-12" formControlName="repetition" (change)="changeRepetition($event)">
              <mat-radio-button [value]="true"><p class="noMargin">{{ t('common.yes') }}</p></mat-radio-button>
              <mat-radio-button [value]="false"><p class="noMargin">{{ t('common.no') }}</p></mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="col-lg-12">
            <mat-form-field class="col-lg-4" *ngIf="this.form.get('financialAssistance.repetition')?.value">
              <label class="font-c2">{{ t('wizard.financial.repeat.date') }}</label>
              <input type="number" placeholder="YYYY" matInput [formControlName]="'repetitionYear'">
              <mat-error
                *ngIf="this.validationService.isFieldInvalid('financialAssistance.repetitionYear', form)">
                {{ this.form.get('financialAssistance.repetitionYear')?.hasError('required') ? t('error.required') : t('error.invalidDigitLength', {length: 4}) }}
              </mat-error>
            </mat-form-field>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <app-wizard-footer *ngIf="!editMode" [newForm]="form"
                       [splitForm]="
                     this.validationService.isFinancialAssistanceInvalid(form,
                     this.form.get('sponsoringValue.sponsoringDuration')?.value === 1, true)"
                       [otherErrors]="this.validationService.validateSponsoringRatesTotal(form, 'firstYear') ||
                     this.validationService.validateSponsoringRatesTotal(form, 'secondYear')">
    </app-wizard-footer>
    <div *ngIf="editMode" class="col-lg-12 buttonModal">
      <app-text-button [isButtonReadOnly]="this.isFormInvalid(form)"
                      [isPrimaryButton]="true" buttonText="Übernehmen" (click)="saveInEditMode(form)"></app-text-button>
    </div>
  </div>
</ng-container>
