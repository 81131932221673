import {Component, OnInit} from '@angular/core';
import {TranslocoDirective} from "@ngneat/transloco";
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef, MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef,
  MatTable,
  MatTableDataSource
} from "@angular/material/table";
import {TableSortComponent} from "../../../shared/utils/table-sort/table-sort.component";
import {DataService} from "../../../core/services/data.service";
import {MatPaginator, MatPaginatorIntl, PageEvent} from "@angular/material/paginator";
import {ApplicantOverviewDto} from "../../../api/applicantOverviewDto";
import {Router} from "@angular/router";
import {CustomMatPaginatorIntl} from "../../../shared/utils/custom-paginator-intl";

@Component({
  selector: 'app-applicant-overview',
  standalone: true,
  imports: [
    TranslocoDirective,
    MatTable,
    MatHeaderCell,
    MatHeaderCellDef,
    TableSortComponent,
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatPaginator,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef
  ],
  providers: [{provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl}],
  templateUrl: './applicant-overview-component.html',
  styleUrl: './applicant-overview-component.scss'
})
export class ApplicantOverviewComponent implements OnInit {
  totalApplications = 0;
  currentPage = 1;
  sortOrder : {column: string, direction: string} = {column: '', direction: ''};

  displayedColumns: string [] = ['applicant', 'address', 'contact', 'sponsoredNumber'];
  dataSource = new MatTableDataSource<ApplicantOverviewDto>();

  constructor(private dataService: DataService, private router: Router) {
  }

  ngOnInit() {
    this.dataService.getAllApplicantOverviewData().subscribe((result) => {
      this.initializeDataSource(result.data);
      this.totalApplications = result.total;
    });
  }

  loadApplications(pagination: number) {
    this.dataService.getAllApplicantOverviewData(pagination, this.sortOrder).subscribe((result) => {
      this.totalApplications = result.total;
      this.initializeDataSource(result.data);
    });
  }

  sortValues(event: { column: string, direction: string }): void {
    this.dataService.getAllApplicantOverviewData(1, event).subscribe((result) => {
      this.sortOrder = event;
      if(result){
        this.totalApplications = result.total;
        this.initializeDataSource(result.data);
      }
    });
  }

  openDetailForSponsored(row: any) {

    this.router.navigate(['/applicant-detail-view'], {state: {applicantId: row.id}});
  }

  onPageChange(event: PageEvent) {
    const pageIndex = event.pageIndex + 1;
    this.loadApplications(pageIndex);
  }

  initializeDataSource(data: Array<ApplicantOverviewDto>): void {
    this.dataSource = new MatTableDataSource(data)
  }

  protected readonly JSON = JSON;
}
