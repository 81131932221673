<ng-container *transloco="let t">
  <form [formGroup]="form">
    <div class="zuschuss-amount section-space-default">
      <h3 class="subtitle element-space">Zuschussbetrag</h3>

      <div class="row element-space">
        <label class="font-c2 col-lg-12">Kürzung</label>
        <mat-radio-group class="radio-button" formControlName="reduction">
          <mat-radio-button [value]="true"><p>{{t('common.yes')}}</p></mat-radio-button>
          <mat-radio-button [value]="false"><p>{{t('common.no')}}</p></mat-radio-button>
        </mat-radio-group>
      </div>

      <mat-form-field class="full-with">
        <label class="font-c2">Maximal möglicher Zuschussbetrag in Euro brutto</label>
        <input type="text" min="0" matInput formControlName="funding_amount"
               appCurrencyFormat appNumericInput>
      </mat-form-field>

      <mat-form-field class="full-with">
        <label class="font-c2">Information</label>
        <textarea class="commentFieldStyle" matInput formControlName="reduction_reason"></textarea>
      </mat-form-field>
    </div>
  </form>
</ng-container>

