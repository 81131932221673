
<mat-card *ngIf="rdData?.status_history.glvkf" class="col-lg-12">
  <mat-card-title class="title">
    <div>GLVKF</div>
    <button (click)="closeCard()">
      <mat-icon class="icon-arrow" [svgIcon]="isCardOpen ? 'open-accordion' : 'close-accordion'"></mat-icon>
    </button>
  </mat-card-title>
  <mat-card-content *ngIf="isCardOpen">
    <ng-container *ngFor="let item of getKeyValuePairs(rdData?.status_history.glvkf)">
      <div class="item">
        <div class="key-value-pair row">
          <div class="key col-lg-6">{{ item.key }}</div>
          <div class="value col-lg-6">{{ item.value }}</div>
        </div>
      </div>
    </ng-container>

  </mat-card-content>
</mat-card>
