import {Component, Input, OnInit} from '@angular/core';
import {TranslocoDirective} from "@ngneat/transloco";
import {
  ApplicationStateComponentComponent
} from "../../../shared/components/application-state-component/application-state-component.component";
import {DatePipe, NgIf} from "@angular/common";
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell, MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow, MatRowDef, MatTable, MatTableDataSource
} from "@angular/material/table";
import {ApplicationDto} from "../../../api/applicationDto";
import {Router} from "@angular/router";
import {DataService} from "../../../core/services/data.service";
import {SponsoredDto} from "../../../api/sponsoredDto";
import {MatPaginator, MatPaginatorIntl, PageEvent} from "@angular/material/paginator";
import {ApplicationResponse} from "../../dashboard/dashboard.component";
import {IconTextButtonComponent} from "../../../shared/components/button/icon-text-button/icon-text-button.component";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {elementAt} from "rxjs";
import {IconsComponent} from "../../../shared/components/icons/icons.component";
import {TableSortComponent} from "../../../shared/utils/table-sort/table-sort.component";
import {CustomMatPaginatorIntl} from "../../../shared/utils/custom-paginator-intl";

@Component({
  selector: 'app-sponsored-overview',
  standalone: true,
  imports: [
    TranslocoDirective,
    ApplicationStateComponentComponent,
    DatePipe,
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatTable,
    MatHeaderCellDef,
    MatPaginator,
    IconTextButtonComponent,
    MatProgressSpinner,
    NgIf,
    IconsComponent,
    TableSortComponent,
    TableSortComponent
  ],
  providers: [{provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl}],
  templateUrl: './sponsored-overview.html',
  styleUrl: './sponsored-overview.scss'
})
export class SponsoredOverview implements OnInit {
  totalApplications = 0;
  currentPage = 1;
  isLoading = false;
  sortOrder : {column: string, direction: string} = {column: '', direction: ''};

  displayedColumns: string [] = ['name', 'address'];
  dataSource = new MatTableDataSource<SponsoredDto>();

  constructor(private router: Router, private dataService: DataService) {
    // noop
  }

  ngOnInit(): void {
    this.dataService.getAllSponsoredData().subscribe((result) => {
      if(result){
        this.totalApplications = result.total;
        this.initializeDataSource(result.data);
      }
    });
  }

  initializeDataSource(data: Array<SponsoredDto>): void {
    this.dataSource = new MatTableDataSource(data)
  }

  onPageChange(event: PageEvent) {
    const pageIndex = event.pageIndex + 1;
    this.loadMore(pageIndex);
  }

  navigateToDashboard() {
    this.router.navigate(['/dashboard']);
  }

  openDetailForSponsored(row: any) {
    this.router.navigate(['/sponsored-detail-view'], {state: {sponsoredId: row.id}});
  }

  sortValues(event: { column: string, direction: string }): void {
    this.dataService.getAllSponsoredData(1, event).subscribe((result) => {
      this.sortOrder = event;
      if(result){
        this.totalApplications = result.total;
        this.initializeDataSource(result.data);
      }
    });
  }

  private loadMore(page: number): void {
    this.isLoading = true;
    this.dataService.getAllSponsoredData(page, this.sortOrder).subscribe((result) => {
      this.totalApplications = result.total;
      this.initializeDataSource(result.data);
      this.isLoading = false;
    });
  }
}
