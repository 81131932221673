import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  MatCellDef,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderRowDef,
  MatRowDef,
  MatTable,
  MatTableDataSource,
  MatTableModule
} from "@angular/material/table";
import {DatePipe} from "@angular/common";
import {Router} from "@angular/router";
import {
  ApplicationStateComponentComponent
} from "../../../shared/components/application-state-component/application-state-component.component";
import {TableSortComponent} from "../../../shared/utils/table-sort/table-sort.component";
import {ApplicationBasicDto} from "../../../api/applicationBasicDto";

@Component({
  selector: 'app-application-overview-table',
  standalone: true,
  imports: [
    MatTable,
    MatColumnDef,
    MatHeaderCellDef,
    MatCellDef,
    MatHeaderRowDef,
    MatRowDef,
    MatTableModule,
    DatePipe,
    ApplicationStateComponentComponent,
    TableSortComponent,
  ],
  templateUrl: './application-overview-table.component.html',
  styleUrl: './application-overview-table.component.scss'
})

export class ApplicationOverviewTableComponent implements OnInit {
  @Input()
  set allApplications(allApplications: ApplicationBasicDto[]) {
    this.isLoading = true;
    this._allApplications = allApplications;
    this.initializeDataSource();
  }
  get allApplications(): ApplicationBasicDto[] {
    return this._allApplications;
  }
  @Output() sortingChanged: EventEmitter<any> = new EventEmitter();
  isLoading: boolean = false;
  displayedColumns: string [] = ['application', 'date', 'state'];
  dataSource = new MatTableDataSource<ApplicationBasicDto>();

  private _allApplications: ApplicationBasicDto[] = [];

  constructor(private router: Router) {
    //noop
  }

  ngOnInit() {
    this.initializeDataSource();
  }

  initializeDataSource(): void {
    this.dataSource = new MatTableDataSource(this.allApplications);
    this.isLoading = false;
  }

  async editApplication(row: any) {
    await this.router.navigate(['/detailview'], {state: {applicationId: row.id}});
  }

  sortValues(event: { column: string, direction: string }): void {
    this.sortingChanged.emit(event);
  }
}
