<ng-container *transloco="let t">
  <div class="element-space radio-group">
    <!-- wir wollten kein neuen Endpunkt deshalb mit accepted usw. -->
    <mat-radio-group (change)="changeFundingOption($event)">
      <mat-radio-button [value]="'accepted'"><p>{{ t('funding.accepted') }}</p></mat-radio-button>
      <mat-radio-button [value]="'correctionNeeded'"><p>{{ t('funding.correctionNeeded') }}</p></mat-radio-button>
      <mat-radio-button [value]="'rejected'"><p>{{ t('funding.rejected') }}</p></mat-radio-button>
    </mat-radio-group>
  </div>
</ng-container>

