import {Component, OnInit} from '@angular/core';
import {WizardHeaderComponent} from "../../sponsoring-wizard/wizard-header/wizard-header.component";
import {WizardService} from "../../../shared/services/wizard-service/wizard.service";
import {StateTextService} from "../../../shared/services/state-text-service/state-text.service";
import {FormService} from "../../../shared/services/form-service/form.service";
import {DataService} from "../../../core/services/data.service";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {translate, TranslocoDirective, TranslocoPipe} from "@ngneat/transloco";
import {HintComponent} from "../../../shared/components/hint/hint.component";
import {FileUploaderComponent} from "../../../shared/components/file-uploader/file-uploader.component";
import {TextButtonComponent} from "../../../shared/components/button/text-button/text-button.component";
import {IconsComponent} from "../../../shared/components/icons/icons.component";
import {MediaBoxComponent} from "../../../shared/components/media-box/media-box.component";
import {FileViewerComponent} from "../../../shared/components/file-viewer/file-viewer.component";
import {FileUploadService} from "../../../shared/components/file-uploader/file-uploader-service";
import {applicationStateEnum} from "../../../api/enum/stateEnum";
import {DatePipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {InformationModalComponent} from "../Modal/Information-modal/information-modal.component";

@Component({
  selector: 'app-zuschuss-documents-component',
  standalone: true,
  imports: [
    WizardHeaderComponent,
    TranslocoDirective,
    HintComponent,
    FileUploaderComponent,
    TextButtonComponent,
    IconsComponent,
    TranslocoPipe,
    MediaBoxComponent,
    FileViewerComponent,
    DatePipe,
    NgForOf,
    NgIf,
    NgClass
  ],
  templateUrl: './zuschuss-document-upload-component.html',
  styleUrl: './zuschuss-document-upload-component.scss'
})
export class ZuschussDocumentUploadComponent implements OnInit{
  form = this.formService.buildForm();

  applicationId = '';
  applicationData: any = null;
  selectedFiles: Array<any>=[];
  comments: { [key: string]: Array<{ author: string; date: string; sonstiges: string }> } = {};


  constructor(public wizardService: WizardService,
              public stateTextService: StateTextService,
              public formService: FormService,
              public dataService: DataService,
              public router: Router,
              public fileUploaderService: FileUploadService,
              public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.formService.initializeForm(this.form);

    const state: any = history.state;
    if (state.applicationId) {
      this.applicationId = state.applicationId.toString();
      this.getGeneratedDocumentsForApplication();
      this.getDataForSelectedApplication(this.applicationId);
      this.getCommentsForContract(this.applicationId);
    } else {
      console.error('applicationId ist nicht im Router-State vorhanden. ');
    }
  }

  getGeneratedDocumentsForApplication(): any {
    this.dataService.getZuschussDocuments(this.applicationId).subscribe((result) => {
      this.selectedFiles = result;
    });
  }

  getDataForSelectedApplication(applicationId: string): void {
    this.dataService.getApplicationById(applicationId).subscribe((result) => {
      this.applicationData = result;
    });
  }

  getCommentsForContract(applicationId: string): any {
    this.dataService.getCommentsForApplicationById(applicationId).subscribe((result) => {
      this.comments = result;
    });
  }

  getCommentsForSection(section: string): Array<{ authorName: string; created_at: string; content: string }> | null {
    let result: any;
    if(this.comments['comments']){
      Object.entries(this.comments['comments']).forEach(([key, value]) => {
        if(key === section){
          result = value;
        }
      });
    }
    return result;
  }

  onClickSave(): void {
      const dialogRef = this.dialog.open(InformationModalComponent, {
        width: '920px',
        height:'max-content',
        data: translate('common.documentSaved')
      });
      dialogRef.afterClosed().subscribe(() =>  {
        this.uploadDocuments();
        this.navigateToDashboard();
      });
  }

  onClickChangeState(action: string): void {
    if(this.applicationData?.sponsoringValue?.application_state === applicationStateEnum.ZUSCHUSS_UPLOADED) {
      this.uploadDocuments();
      this.navigateToDashboard();
    } else {
      this.dataService.changeStateOfApplication(this.applicationId, action, '').subscribe({
        next: () => {
          this.uploadDocuments();
          this.navigateToDashboard();
        },
        error: (err) => {
          console.error('Status konnte nicht geändert werden', err);
        }
      });
    }
  }

  private uploadDocuments(): void {
    const files = this.fileUploaderService.getData();
    this.dataService.uploadDocuments(this.applicationId, files).subscribe((result) => {
      this.applicationData = result;
      this.fileUploaderService.clearData();
    });
  }

  navigateToDashboard() {
    this.router.url === '/detailview' ? this.navigateToDashboard() : this.router.navigate(['/detailview'], {state: {applicationId: this.applicationId}});
  }
  protected readonly applicationStateEnum = applicationStateEnum;
}
