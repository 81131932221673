import {Component, Input, OnInit} from '@angular/core';
import {IconsComponent} from "../icons/icons.component";
import {TranslocoModule} from "@ngneat/transloco";
import {DataService} from "../../../core/services/data.service";
import {DatePipe} from "@angular/common";
import {MatCard} from "@angular/material/card";
import {MatButton} from "@angular/material/button";
import {applicationStateEnum} from "../../../api/enum/stateEnum";
import {Router} from "@angular/router";

@Component({
  selector: 'app-alert',
  standalone: true,
  imports: [
    IconsComponent,
    TranslocoModule,
    DatePipe,
    MatCard,
    MatButton
  ],
  templateUrl: './alert.html',
  styleUrl: './alert.scss'
})
export class Alert{
  @Input() notification: any;

  constructor(private dataService: DataService, private router: Router,) {}

  marcNotificationAsRead(): void {
    this.dataService.deleteNotifications(this.notification.id).subscribe({
      next: () => {
          this.router.navigate(['/detailview'], {state: {applicationId: this.notification.application_id}});
      },
      error: error => {
        console.log(error);
      }
    });
  }
}
