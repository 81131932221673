<ng-container *transloco="let t">
  <app-stepper></app-stepper>
  <div class="container-wizard">
    <app-wizard-header [title]="t('wizard.sponsoringConfirmation.title')"></app-wizard-header>

    <div id="sponsoring-confirmation" class="row">
      <div class="container">
        <app-key-value-card-component [title]="t('wizard.sponsoringConfirmation.tableHeader')"
                                      [requestedFormGroupName]="'sponsoringValue'"
                                      [extended]="false"
        ></app-key-value-card-component>
        <app-hint [hintDescription]="t('wizard.sponsoringConfirmation.hint')"></app-hint>
      </div>
    </div>
    <app-wizard-footer textInput="Ja, die Angaben sind korrekt"></app-wizard-footer>

  </div>
</ng-container>
