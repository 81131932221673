<ng-container *transloco="let t">
  <div class="spinner-container" *ngIf="!applicationData">
    <mat-spinner></mat-spinner>
  </div>
  <ng-container *ngIf="applicationData">
    <app-wizard-header [viewMode]="true"
                       [status]="this.applicationData?.sponsoringValue?.application_state_label"
                       [title]="editMode ?
                     t('wizardTitles.vkfOverviewCheckTitle',
                     {organisation: this.applicationData?.sponsored.sponsoredName})
                     : t('wizardTitles.vkfOverviewTitle',
                     {organisation: this.applicationData?.sponsored.sponsoredName})
">
    </app-wizard-header>
    <div class="container">
      <mat-card *ngIf="showInfoBox()">
        <app-icons class="icon" *ngIf="this.applicationData?.sponsoringValue?.application_state === applicationStateEnum.REJECTED_GLVKF || this.applicationData?.sponsoringValue?.application_state === applicationStateEnum.ZUSCHUSS_REJECTED_GLVKF" [iconName]="'rejection-icon'" [iconSize]="'large'"
                   [circleIcon]="true"></app-icons>
        <app-icons class="icon" *ngIf="this.applicationData?.sponsoringValue?.application_state === applicationStateEnum.APPROVED_GLVKF || this.applicationData?.sponsoringValue?.application_state === applicationStateEnum.ZUSCHUSS_APPROVED_GLVKF" [iconName]="'check-icon'" [iconSize]="'large'"
                   [circleIcon]="true"></app-icons>

        <ng-container
          *ngIf="
          applicationData?.sponsoringValue?.application_state === applicationStateEnum.APPROVED_GLVKF ||
          applicationData?.sponsoringValue?.application_state === applicationStateEnum.CHECK_GLVKF ||
          applicationData?.sponsoringValue?.application_state === applicationStateEnum.ZUSCHUSS_APPROVED_GLVKF ||
          applicationData?.sponsoringValue?.application_state === applicationStateEnum.IN_WORK_ZUSCHUSS else labelTemplate"
        >
          <h3 [innerHTML]="this.stateTextService.returnTextForGLVKF(this.applicationData?.sponsoringValue?.application_state).title"></h3>
          <p class="element-space" [innerHTML]="this.stateTextService.returnTextForGLVKF(this.applicationData?.sponsoringValue?.application_state, applicationData?.sponsoringValue?.sponsoringStartDate).content"></p>
        </ng-container>

        <ng-template #labelTemplate>
          <h3 [innerHTML]="this.stateTextService.returnTextForGLVKF(this.applicationData?.sponsoringValue?.application_state_label).title"></h3>
          <p class="element-space" [innerHTML]="this.stateTextService.returnTextForGLVKF(this.applicationData?.sponsoringValue?.application_state_label, applicationData?.sponsoringValue?.sponsoringStartDate).content"></p>
        </ng-template>

      </mat-card>


      <app-zuschuss-confirmation-component [applicationId]="applicationId"></app-zuschuss-confirmation-component>
      <ng-container id="funding-documents">
        <app-file-viewer [downloadedFiles]="fundingFiles" [titleIsAccordionStyle]="false" [title]="'Umsatzsteuerbefreiung des Gesponserten'" [purpose]="['Umsatzsteuerbefreiung']"></app-file-viewer>
        <app-file-viewer [downloadedFiles]="fundingFiles" [titleIsAccordionStyle]="false"  [title]="'Belegfotos aller werblichen Maßnahmen (Leistungsbestandteile)'" [purpose]="['Leistungsbestandteile']"></app-file-viewer>
        <app-file-viewer [downloadedFiles]="fundingFiles" [titleIsAccordionStyle]="false"  [title]="'Rechnungskopien'" [purpose]="['Rechnungskopien']"></app-file-viewer>
        <app-file-viewer [downloadedFiles]="fundingFiles" [titleIsAccordionStyle]="false"  [title]="'Zahlungsnachweise über gesamte Brutto-Sponsoring-Summe '" [purpose]="['Zahlungsnachweise']"></app-file-viewer>
        <app-file-viewer [downloadedFiles]="fundingFiles" [titleIsAccordionStyle]="false"  [title]="'Sonstige Dateien'" [purpose]="['sonstiges_zuschuss']"></app-file-viewer>
      </ng-container>
      <ng-container>
        <app-glvkf-confirmation-component [applicationId]="applicationId"></app-glvkf-confirmation-component>
        <app-rd-confirmation-component [applicationId]="applicationId"></app-rd-confirmation-component>
        <app-file-viewer *ngIf="!editMode"
                         [downloadedFiles]="selectedFiles" [title]="'Dokumente'" [showSubTitle]="false" [purpose]="['application_form', 'contract_draft']"></app-file-viewer>
      </ng-container>

      <form [formGroup]="form">
        <div *ngFor="let section of sections">
          <ng-container>

            <ng-container *ngIf="section.id === 'financialAssistance'; else defaultKeyValues">
              <app-financial-key></app-financial-key>
            </ng-container>

            <ng-template #defaultKeyValues>
              <app-file-viewer *ngIf="section.id ==='dataUpload'"  [downloadedFiles]="selectedFiles" [title]="'Daten-Upload'" [showSubTitle]="false" [purpose]="['sonstiges_antrag']"></app-file-viewer>

              <app-key-value-card-component
                *ngIf="section.id !== 'dataUpload'"
                [isEditable]="false"
                [title]="t(section.title)"
                [requestedFormGroupName]="section.id"
                [data]="applicationData?.[section.dataKey]"
                [serverData]="applicationData">
              </app-key-value-card-component>
            </ng-template>
          </ng-container>


        </div>
      </form>
      <ng-container *ngIf="showActionButtons()">
        <div class="col-lg-12 buttonGroupSave">
          <app-text-button [isPrimaryButton]="false"
                           [buttonText]="
                           (this.applicationData?.sponsoringValue?.application_state_label === applicationStateEnum.CHECK_GLVKF &&
                           this.applicationData?.sponsoringValue?.application_state === applicationStateEnum.IN_WORK_ZUSCHUSS)
                             ?
                           t('buttonText.rejectFundingGLVKF') :
                           t('buttonText.rejectApplicationGLVKF')"
                           (click)="openRejectionModal('rejected')"></app-text-button>
          <app-text-button [isPrimaryButton]="true"
                           [buttonText]="
                           (this.applicationData?.sponsoringValue?.application_state_label === applicationStateEnum.CHECK_GLVKF &&
                           this.applicationData?.sponsoringValue?.application_state === applicationStateEnum.IN_WORK_ZUSCHUSS) ?
                           t('buttonText.acceptFundingGLVKF'):
                           t('buttonText.acceptApplicationGLVKF')"
                           (click)="acceptSponsoring('accepted')"></app-text-button>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
