<ng-container id="controlling" *transloco="let t">
  <div class="container">
    <h1 class="col-lg-12 element-space" [innerHTML]="'Reporting und Controlling'"></h1>

    <div class="row icon-row element-space">
      <div class="col text-end">
        <app-icon-text-button
          [iconSize]="'large'"
          [iconColor]="'whiteIcon'"
          [iconName]="'download-icon'"
          [buttonText]="'CSV-Datei exportieren'"
          (click)="exportExcel()">
        </app-icon-text-button>
      </div>
    </div>

    <div class="row g-4">
      <ng-container *ngFor="let kpiElement of Object.entries(kpiCards)">
        <div class="col-12 col-md-6 col-lg-3 d-flex">
          <mat-card class="kpi-card flex-fill d-flex flex-column">
            <mat-card-content class="flex-fill d-flex flex-column justify-content-between">
              <p class="font-c2">{{ t('controlling.' + kpiElement[0] + '.label') }}</p>
              <h4 class="redText">{{ kpiElement[1] }} {{ showUnit(kpiElement[0]) }}</h4>
              <p class="font-c2">{{ t('controlling.' + kpiElement[0] + '.description') }}</p>
            </mat-card-content>
          </mat-card>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
