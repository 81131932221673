import {HttpInterceptorFn} from '@angular/common/http';

export const csrfTokenInterceptor: HttpInterceptorFn = (req, next) => {
  const xsrfToken = document.cookie
    .split('; ')
    .find(row => row.startsWith('XSRF-TOKEN='))
    ?.split('=')[1];

  if (xsrfToken) {
    // Add XSRF-TOKEN to headers if available in cookies
      const clonedRequest = req.clone({
        headers: req.headers.set('X-XSRF-TOKEN', xsrfToken) // Add XSRF-TOKEN without removing existing headers
      });

    return next(clonedRequest);
  } else {
    // Continue without changing the request if no token is found
    return next(req);
  }
};
