import {ChangeDetectionStrategy, Component} from '@angular/core';
import {TranslocoDirective} from "@ngneat/transloco";
import {ReactiveFormsModule} from "@angular/forms";
import {MatFormField} from "@angular/material/form-field";
import {MatInput, MatInputModule} from "@angular/material/input";
import {NgIf} from "@angular/common";
import {HintComponent} from "../../../../shared/components/hint/hint.component";
import {MatRadioButton, MatRadioChange, MatRadioGroup} from "@angular/material/radio";
import {WizardService} from "../../../../shared/services/wizard-service/wizard.service";
import {TextButtonComponent} from "../../../../shared/components/button/text-button/text-button.component";
import {WizardFooterComponent} from "../../wizard-footer/wizard-footer.component";
import {FormService} from "../../../../shared/services/form-service/form.service";
import {ValidationService} from "../../../../shared/services/validation-service";
import {CurrencyFormatDirective} from "../../../../shared/directives/currency-directive";
import {NumericFieldDirective} from "../../../../shared/directives/numeric-field-directive";

@Component({
  selector: 'app-sponsoring-value-two-years',
  standalone: true,
  imports: [
    MatFormField,
    MatInputModule,
    NgIf,
    TranslocoDirective,
    ReactiveFormsModule,
    HintComponent,
    MatRadioGroup,
    MatRadioButton,
    MatInput,
    TextButtonComponent,
    WizardFooterComponent,
    CurrencyFormatDirective,
    NumericFieldDirective
  ],
  templateUrl: './sponsoring-value-two-years.component.html',
  styleUrl: './sponsoring-value-two-years.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SponsoringValueTwoYearsComponent {
  form = this.formService.buildForm();

  constructor( public wizardService: WizardService, public formService: FormService, public validationService: ValidationService) {
    this.formService.initializeForm(this.form);
    this.disableFormControl();
  }

  changeTaxOption(event: MatRadioChange): void {
    this.disableFormControl();
    this.formService.setFormData(this.form);
  }

  disableFormControl(): void {
    if (this.form.get('sponsoringValue.taxFree')?.value) {
      this.form.get('sponsoringValue.sponsoringValueGrossAmountFirstYear')?.disable();
      this.form.get('sponsoringValue.sponsoringValueGrossAmountFirstYear')?.setValue(null);
      this.form.get('sponsoringValue.sponsoringValueGrossAmountSecondYear')?.disable();
      this.form.get('sponsoringValue.sponsoringValueGrossAmountSecondYear')?.setValue(null);
    } else {
      this.form.get('sponsoringValue.sponsoringValueGrossAmountFirstYear')?.enable();
      this.form.get('sponsoringValue.sponsoringValueGrossAmountSecondYear')?.enable();
    }
  }
}
