import {ApplicationConfig} from '@angular/core';
import {provideRouter} from '@angular/router';
import {routes} from './app.routes';
import {provideHttpClient, withFetch, withInterceptors} from '@angular/common/http';
import {TranslocoHttpLoader} from './transloco-loader';
import {provideTransloco} from '@ngneat/transloco';
import {provideAnimations} from "@angular/platform-browser/animations";
import {MAT_DATE_LOCALE, provideNativeDateAdapter} from "@angular/material/core";
import {DatePipe} from "@angular/common";
import {csrfTokenInterceptor} from "./debug-csrftoken.interceptor";

export const appConfig: ApplicationConfig = {
  providers: [
    DatePipe,
    {provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
    provideAnimations(),
    provideRouter(routes),
    provideHttpClient(withInterceptors([csrfTokenInterceptor])),
    provideHttpClient(withFetch(), withInterceptors([csrfTokenInterceptor])),
    provideNativeDateAdapter(),
    provideTransloco({
      config: {
        availableLangs: ['de'],
        defaultLang: 'de',
      },
      loader: TranslocoHttpLoader
    })]
};
