
<ng-container *transloco="let t">
  <div *ngIf="!applicationData" class="spinner-container">
    <mat-spinner></mat-spinner>
  </div>
  <ng-container *ngIf="applicationData">
    <app-wizard-header [viewMode]="true"
                       [applicationId]="applicationId"
                       [status]="this.applicationData?.sponsoringValue?.application_state_label"
                       [title]="'Prüfung von ' + this.applicationData?.sponsored.sponsoredName">
    </app-wizard-header>
    <div class="container">
      <mat-card>
        <app-icons class="icon" *ngIf="this.applicationData?.sponsoringValue?.application_state === applicationStateEnum.REJECTED_OD_LEAD" [iconName]="'rejection-icon'" [iconSize]="'large'"
                   [circleIcon]="true"></app-icons>
        <app-icons class="icon" *ngIf="this.applicationData?.sponsoringValue?.application_state === applicationStateEnum.APPROVED_OD_LEAD" [iconName]="'check-icon'" [iconSize]="'large'"
                   [circleIcon]="true"></app-icons>

        <h3 [innerHTML]="this.stateTextService.returnTextForODLead(this.applicationData?.sponsoringValue?.application_state).title"></h3>
        <p class="element-space" [innerHTML]="this.stateTextService.returnTextForODLead(this.applicationData?.sponsoringValue?.application_state, applicationData?.sponsoringValue?.sponsoringStartDate).content"></p>
      </mat-card>

      <ng-container>
        <app-zuschuss-confirmation-component [applicationId]="applicationId"></app-zuschuss-confirmation-component>

        <ng-container id="funding-documents">
          <app-file-viewer [downloadedFiles]="fundingFiles" [titleIsAccordionStyle]="false" [title]="'Umsatzsteuerbefreiung des Gesponserten'" [purpose]="['Umsatzsteuerbefreiung']"></app-file-viewer>
          <app-file-viewer [downloadedFiles]="fundingFiles" [titleIsAccordionStyle]="false"  [title]="'Belegfotos aller werblichen Maßnahmen (Leistungsbestandteile)'" [purpose]="['Leistungsbestandteile']"></app-file-viewer>
          <app-file-viewer [downloadedFiles]="fundingFiles" [titleIsAccordionStyle]="false"  [title]="'Rechnungskopien'" [purpose]="['Rechnungskopien']"></app-file-viewer>
          <app-file-viewer [downloadedFiles]="fundingFiles" [titleIsAccordionStyle]="false"  [title]="'Zahlungsnachweise über gesamte Brutto-Sponsoring-Summe '" [purpose]="['Zahlungsnachweise']"></app-file-viewer>
          <app-file-viewer [downloadedFiles]="fundingFiles" [titleIsAccordionStyle]="false"  [title]="'Sonstige Dateien'" [purpose]="['sonstiges_zuschuss']"></app-file-viewer>
        </ng-container>

        <app-glvkf-confirmation-component [applicationId]="applicationId"></app-glvkf-confirmation-component>
        <app-rd-confirmation-component [applicationId]="applicationId"></app-rd-confirmation-component>
        <app-file-viewer *ngIf="!editMode"
                         [downloadedFiles]="selectedFiles" [title]="'Dokumente'" [showSubTitle]="false" [purpose]="['application_form', 'contract_draft']"></app-file-viewer>
      </ng-container>

      <form [formGroup]="form">
        <div *ngFor="let section of sections">
          <ng-container>

            <ng-container *ngIf="section.id === 'financialAssistance'; else defaultKeyValues">
              <app-financial-key></app-financial-key>
            </ng-container>

            <ng-template #defaultKeyValues>
              <app-file-viewer *ngIf="section.id ==='dataUpload'"  [downloadedFiles]="selectedFiles" [title]="'Daten-Upload'" [showSubTitle]="false" [purpose]="['sonstiges_antrag']"></app-file-viewer>

              <app-key-value-card-component
                *ngIf="section.id !== 'dataUpload'"
                [isEditable]="false"
                [title]="t(section.title)"
                [requestedFormGroupName]="section.id"
                [data]="applicationData?.[section.dataKey]"
                [serverData]="applicationData">
              </app-key-value-card-component>
            </ng-template>
          </ng-container>


        </div>
      </form>
      <ng-container>
        <div class="col-lg-12 buttonGroupSave"
             *ngIf="
             applicationData.sponsoringValue.application_state === applicationStateEnum.APPROVED_OD ||
             applicationData.sponsoringValue.application_state === applicationStateEnum.NOT_RECOMMENDED_OD">
          <app-text-button [isPrimaryButton]="false" [buttonText]="'Ich lehne den Zuschuss ab'"
                           (click)="openModal('rejected')"></app-text-button>
          <app-text-button [isPrimaryButton]="true" [buttonText]="'Ich gebe den Zuschuss frei'"
                           (click)="acceptSponsoring('accepted')"></app-text-button>
        </div>
      </ng-container>
      <div class="text-center" *ngIf="applicationData?.sponsoringValue?.application_state_label !== applicationStateEnum.CHECK_OD_LEAD ">
        <app-text-button [isPrimaryButton]="true" [buttonText]="'Zurück zum Dashboard'"
                         (click)="navigateToDashboard()"></app-text-button>
      </div>
      <app-media-box class="helpPart col-md-12 col-sm-12 col-lg-12 sectionSpaceDefaultTop text-center" [isText]="true"
                     [textContent]="mediaBoxContent"></app-media-box>
    </div>
  </ng-container>

  <ng-template #mediaBoxContent>
    <app-icons class="media-group" [iconColor]="'red'" [iconSize]="'large'" [iconName]="'question-icon'"></app-icons>
    <h5 class="text-center">{{ t('dashboard.mediaPart.helpTitle') }}</h5>
    <a href="mailto:sponsoring.regional@ergo.de"><p
      class="font-c3 text-center">{{ t('dashboard.mediaPart.helpDescription') }}</p></a>
  </ng-template>
</ng-container>
