import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {MatTab, MatTabGroup} from "@angular/material/tabs";
import {AsyncPipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {UserRoleEnum} from "../../../api/enum/userRoleEnum";
import {DataService} from "../../../core/services/data.service";
import {CookieService} from "ngx-cookie-service";


@Component({
  selector: 'app-navigation',
  standalone: true,
  imports: [
    MatTabGroup,
    MatTab,
    NgForOf,
    NgClass,
    RouterLink,
    RouterLinkActive,
    NgIf,
    AsyncPipe
  ],
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class NavigationComponent {
  protected readonly UserRoleEnum = UserRoleEnum;


  constructor(public cookieService: CookieService) {
    // nooop
  }

}
