import {ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {TranslocoDirective} from "@ngneat/transloco";
import {MatCard, MatCardContent, MatCardTitle} from "@angular/material/card";
import {DatePipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {FormService} from "../../../services/form-service/form.service";
import {DataService} from "../../../../core/services/data.service";
import {ActivatedRoute} from "@angular/router";
import {FormArray, ReactiveFormsModule} from "@angular/forms";
import {MatIcon} from "@angular/material/icon";
import {EditFormDialogComponent} from "../../edit-form-dialog/edit-form-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {IconService} from "../../../services/IconService";
import {CurrencyFormatDirective} from "../../../directives/currency-directive";

@Component({
  selector: 'app-financial-key',
  standalone: true,
  imports: [
    TranslocoDirective,
    MatCard,
    MatCardTitle,
    MatCardContent,
    DatePipe,
    NgForOf,
    NgIf,
    ReactiveFormsModule,
    MatIcon,
    NgClass
  ],
  templateUrl: './financial-key.component.html',
  styleUrl: './financial-key.component.scss'
})
export class FinancialKeyComponent implements OnChanges {
  @Input() isEditable = false;
  @Input() marcCorrectionArea = false;
  form = this.formService.buildForm();
  applicationData: any;
  protected readonly localStorage = localStorage;

  getSavedDataValues = this.localStorage.getItem('form-data') === null;

  sponsoringDurationTwoYears = false;
  fullPayedFirstYear = false;
  fullPayedSecondYear = false;
  bruttoIsNetto = false;
  isCardOpen: boolean = true;


  constructor(
    private route: ActivatedRoute,
    private formService: FormService,
    private dataService: DataService,
    private iconService: IconService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef) {
    this.route.paramMap.subscribe(params => {
      const state: any = history.state;
      if (state.applicationId) {
        this.getDataForSelectedApplication(state.applicationId);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.updateLocalState();
    }
  }

  initializeFormValues(): void {
    if (this.getSavedDataValues) {
      this.formService.initializeFormFromData(this.applicationData);
    } else {
      this.formService.initializeForm(this.form);
    }
  }


  getDataForSelectedApplication(applicationId: string): void {
    this.dataService.getApplicationById(applicationId).subscribe((result) => {
      this.applicationData = result;
      this.initializeFormValues();
    });
  }

  getLabelForFullPayedFirstYear(): string {
    return this.sponsoringDurationTwoYears ?
      'Wie wird die Sponsoringsumme im 1. Jahr gezahlt?' :
      'Wie wird die Sponsoringsumme ausgezahlt?';
  }

  getLabelForFullPayedSecondYear(): string {
    return this.sponsoringDurationTwoYears ?
      'Wie wird die Sponsoringsumme im 2. Jahr gezahlt?' :
      'Wie wird die Sponsoringsumme ausgezahlt?';
  }

  getRepetitionValue(): string {
    if (this.getSavedDataValues) {
      return this.applicationData.financialAssistance.repetition;
    } else {
      return this.form.get('financialAssistance.repetition')?.value;
    }
  }

  getRepetitionYear(): string {
    if (this.getSavedDataValues) {
      return this.applicationData.financialAssistance.repetitionYear;
    } else {
      return this.form.get('financialAssistance.repetitionYear')?.value;
    }
  }

  getZuschussValue(): string {
    if (this.getSavedDataValues) {
      return this.applicationData.financialAssistance.zuschuss;
    } else {
      return this.form.get('financialAssistance.zuschuss')?.value;
    }
  }


  openEditDialog(enterAnimationDuration: string, exitAnimationDuration: string) {
    const dialogRef = this.dialog.open(EditFormDialogComponent, {
      width: '920px',
      height: '75vh',
      enterAnimationDuration,
      exitAnimationDuration,
      data: this.applicationData && this.localStorage.getItem('form-data') === null ?
        {value: this.applicationData, name: 'financialAssistance', serverData: this.applicationData} :
        {name: 'financialAssistance', serverData: null},
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.form = result;
        const firstYear = this.form.get('firstYear.rates') as FormArray
        if (result.get('financialAssistance.firstYear.fullPayed')?.value === false) {
          firstYear.clear();
        }

        const secondYear = this.form.get('secondYear.rates') as FormArray
        if (this.form.get('secondYear.fullPayed')?.value === false) {
          secondYear.clear();
        }

        this.formService.initializeForm(this.form);
        this.cdr.detectChanges();
      }
    });
  }

  updateLocalState(): void {
    this.sponsoringDurationTwoYears = this.applicationData && this.getSavedDataValues ?
      this.applicationData.sponsoringValue.sponsoringDuration === 2 :
      this.form.get('sponsoringValue.sponsoringDuration')?.value === 2;

    this.fullPayedFirstYear = this.applicationData && this.getSavedDataValues ?
      this.applicationData.financialAssistance.firstYear.fullPayed :
      this.form.get('financialAssistance.firstYear.fullPayed')?.value;

    this.fullPayedSecondYear = this.applicationData && this.getSavedDataValues ?
      this.applicationData.financialAssistance.secondYear.fullPayed :
      this.form.get('financialAssistance.secondYear.fullPayed')?.value;

    this.bruttoIsNetto = this.applicationData && this.getSavedDataValues ?
      this.applicationData.sponsoringValue.taxFree :
      this.form.get('sponsoringValue.taxFree')?.value;
  }

  closeCard(): void {
    this.isCardOpen = !this.isCardOpen;
  }

  formatSponsoringValue(value: string) {
    if(value){
      const item = value.replace(/\./g, '').replace(',', '.');
      const numericValue = parseFloat(item);

      if (!isNaN(numericValue)) {
        return new Intl.NumberFormat('de-DE', {
          style: 'decimal',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(numericValue);
      } else {
        return '';
      }
    } else {
      return '';
    }
  }
}

