<table mat-table id="all-sponsorings-for-selected-sponsor" [dataSource]="dataSource">
    <ng-container matColumnDef="responsiblePersonal">
        <th mat-header-cell *matHeaderCellDef>
            <div class="header-icon-row">
                <h4>Ansprechpartner</h4>
                <app-table-sort [columnName]="'sponsoredName'" (sortDirection)="sortValues($event)"></app-table-sort>
            </div>
        </th>
        <td mat-cell *matCellDef="let element"><p class="font-c2">{{ element.responsiblePersonal ? element.responsiblePersonal : '-' }}</p></td>
    </ng-container>
    <ng-container matColumnDef="start">
        <th mat-header-cell *matHeaderCellDef>
            <div class="header-icon-row">
                <h4>Beginn</h4>
                <app-table-sort [columnName]="'sponsoredStartDate'"
                                (sortDirection)="sortValues($event)"></app-table-sort>
            </div>
        </th>
        <td mat-cell *matCellDef="let element"><p
                class="font-c2">{{ element.sponsoringStartDate | date: 'dd.MM.YYYY' }}</p></td>
    </ng-container>
    <ng-container matColumnDef="end">
        <th mat-header-cell *matHeaderCellDef>
            <div class="header-icon-row">
                <h4>Ende</h4>
                <app-table-sort [columnName]="'sponsoredEndDate'"
                                (sortDirection)="sortValues($event)"></app-table-sort>
            </div>
        </th>
        <td mat-cell *matCellDef="let element"><p
                class="font-c2">{{ element.sponsoringEndDate | date: 'dd.MM.YYYY' }}</p></td>
    </ng-container>
    <ng-container matColumnDef="sponsoringValue">
        <th mat-header-cell *matHeaderCellDef><h4>Sponsoring-Summe</h4></th>
        <td mat-cell *matCellDef="let element"><p class="font-c2" [innerHTML]="setSponsoringValue(element)"></p></td>
    </ng-container>
    <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef><h4>Status</h4></th>
        <td mat-cell *matCellDef="let element">
            <p class="font-c2">
                <app-application-state-component class="state"
                                                 [state]="element.status"></app-application-state-component>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="editApplication(row)" ></tr>
</table>
<mat-paginator
        [length]="paginationInformation.total"
        [pageSize]="6"
        [pageIndex]="paginationInformation.current - 1"
        (page)="onPageChange($event)">
</mat-paginator>
