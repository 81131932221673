import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges, SimpleChanges, ViewChild
} from '@angular/core';
import {MatCard, MatCardContent, MatCardTitle} from "@angular/material/card";
import {DatePipe, NgForOf, NgIf} from "@angular/common";
import {TranslocoDirective} from "@ngneat/transloco";
import {FormService} from "../../services/form-service/form.service";
import {MatIcon} from "@angular/material/icon";
import {EditFormDialogComponent} from "../edit-form-dialog/edit-form-dialog.component";
import {FileTypePipe} from "../../pipes/fileTypePipe";
import {MatDialog} from "@angular/material/dialog";
import {ReactiveFormsModule} from "@angular/forms";
import {FirstYearComponent} from "./first-year/first-year.component";
import {SecondYearComponent} from "./second-year/second-year.component";
import {IconService} from "../../services/IconService";

@Component({
  selector: 'app-financial-assistance-key-value-component',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatCard,
    MatCardContent,
    MatCardTitle,
    NgForOf,
    TranslocoDirective,
    NgIf,
    MatIcon,
    ReactiveFormsModule,
    FirstYearComponent,
    SecondYearComponent,
    DatePipe
  ],
  providers: [FileTypePipe],
  templateUrl: './financial-assistance-key-value-component.html',
  styleUrl: './financial-assistance-key-value-component.scss'
})

export class FinancialAssistanceKeyValueComponent implements OnChanges {
  @Input() isEditable = false;
  @Input() data: any;
  @ViewChild(FirstYearComponent) firstYearComponent!: FirstYearComponent;
  @ViewChild(SecondYearComponent) secondYearComponent!: SecondYearComponent;

  isEdited = false;
  isCardOpen: boolean = true;
  form = this.formService.buildForm();

  constructor(public formService: FormService,
              public dialog: MatDialog,
              private iconService: IconService,
              private cdr: ChangeDetectorRef,
  ) {
    this.formService.initializeForm(this.form);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.data) {
      this.formService.initializeFormFromData(this.data);
    }
    this.formService.initializeForm(this.form);
  }

  getValue(fieldName: string): any {
    if (this.data && this.data[fieldName] !== undefined) {
      return this.data[fieldName];
    } else {
      return this.form.get(fieldName)?.value;
    }
  }

  openEditDialog() {
    this.isEdited = false;
    const dialogRef = this.dialog.open(EditFormDialogComponent, {
      width: '920px',
      height: 'max-content',
      data: this.data ? {
        value: this.data,
        name: 'financialAssistance',
        serverData: true
      } : {name: 'financialAssistance', serverData: false},
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.form = result;
        this.cdr.detectChanges();
        this.updateChildComponents();
      }
    });
  }

  updateChildComponents() {
    if (this.firstYearComponent) {
      this.firstYearComponent.updateData(this.form);
    }

    if (this.secondYearComponent) {
      this.secondYearComponent.updateData(this.form);
    }
  }

  closeCard(): void {
    this.isCardOpen = !this.isCardOpen;
  }



}
