import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {MatRadioButton, MatRadioGroup} from "@angular/material/radio";
import {MatFormField} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TranslocoDirective} from "@ngneat/transloco";
import {CurrencyFormatDirective} from "../../directives/currency-directive";
import {NumericFieldDirective} from "../../directives/numeric-field-directive";

@Component({
  selector: 'app-funding-amount',
  standalone: true,
  imports: [
    MatRadioGroup,
    MatRadioButton,
    MatFormField,
    MatInput,
    FormsModule,
    TranslocoDirective,
    CurrencyFormatDirective,
    NumericFieldDirective,
    ReactiveFormsModule
  ],
  templateUrl: './funding-amount.component.html',
  styleUrl: './funding-amount.component.scss'
})
export class FundingAmountComponent implements OnInit {
  @Output() funding_data = new EventEmitter<any>();
  @Output() isFundingRulesImplemented = new EventEmitter<boolean>();

  form = new FormGroup({
    reduction: new FormControl<boolean>(false),
    funding_amount: new FormControl<any>(null),
    reduction_reason: new FormControl<any>(null),
  });

  constructor() {
    this.form.valueChanges.subscribe(value => {
      this.funding_data.emit(value);
      this.isFundingRulesImplemented.emit(this.isApproveButtonDisabled());
    });
  }

  ngOnInit() {
    this.isFundingRulesImplemented.emit(this.isApproveButtonDisabled());
  }

  isApproveButtonDisabled(): boolean {
    return !this.form.get('funding_amount')?.value || (!!this.form.get('reduction')?.value && !this.form.get('reduction_reason')?.value);
  }
}
