<mat-card>
  <div class="add-content row section-space-large">
    <app-icons class="section-space-large icon"
               [circleIcon]="true"
               [iconName]="'document'"
               [iconSize]="'large'"
               [bgColor]="'warningRed'">
    </app-icons>
    <h1 [innerHTML]="title"></h1>
    <p class="font-c2 section-space-large" [innerHTML]="description"></p>
  </div>
</mat-card>
