<ng-container *transloco="let t">
  <mat-card *ngIf="
  applicationData.sponsoringValue.application_state === applicationStateEnum.ZUSCHUSS_UPLOADED ||
  applicationData.sponsoringValue.application_state === applicationStateEnum.APPROVED_OD ||
  applicationData.sponsoringValue.application_state === applicationStateEnum.APPROVED_OD_LEAD ||
  applicationData.sponsoringValue.application_state === applicationStateEnum.OD_CHECK_ZUSCHUSS ||
  applicationData.sponsoringValue.application_state === applicationStateEnum.ZUSCHUSS_APPROVED_GLVKF ||
  ( this.applicationData.sponsoringValue?.application_state === applicationStateEnum.IN_WORK_ZUSCHUSS && this.applicationData?.sponsoringValue?.application_state_label === applicationStateEnum.CHECK_GLVKF)
">
    <h3 [innerHTML]="'Ihre Zuschuss-Dokumente werden derzeit von einem ERGO Sponsoring-Manager geprüft'"></h3>
    <h4 [innerHTML]="'Das sind die nächsten Schritte:<br>'"></h4>

    <div class="timeline">
      <div class="timeline-item">
        <div class="timeline-number font-c1">1.</div>
        <div class="timeline-content">
          <p class="font-c1" [innerHTML]="'Ihr ERGO Sponsoring-Manager wird Ihre hochgeladenen Dateien auf Richtigkeit und Vollständigkeit prüfen. Bei Bedarf erhalten Sie Benachrichtigungen oder Anfragen zur Ergänzung.'"></p>
        </div>
      </div>
    </div>
    <div class="timeline second">
      <div class="timeline-item">
        <div class="timeline-number font-c1">2.</div>
        <div class="timeline-content">
          <p class="font-c1" [innerHTML]="'Nach erfolgter fachlicher Prüfung erhalten Sie eine Bestätigung über die Bewilligung des Zuschusses, sofern alle Nachweise und Beleg vollständig vorliegen. Der Zuschussbetrag wird über die nächstmögliche Vermittlerabrechnung gutgeschrieben.'"></p>
        </div>
      </div>
    </div>
  </mat-card>
</ng-container>
