import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WizardService} from "../../../shared/services/wizard-service/wizard.service";
import {TextButtonComponent} from "../../../shared/components/button/text-button/text-button.component";
import {TranslocoModule} from "@ngneat/transloco";
import {WizardHeaderComponent} from "../wizard-header/wizard-header.component";
import {WizardFooterComponent} from "../wizard-footer/wizard-footer.component";
import {FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatRadioButton, MatRadioChange, MatRadioGroup} from "@angular/material/radio";
import {MatFormField, MatFormFieldModule} from "@angular/material/form-field";
import {MatOption, MatSelect} from "@angular/material/select";
import {HintComponent} from "../../../shared/components/hint/hint.component";
import {MatInput, MatInputModule} from "@angular/material/input";
import {NgForOf, NgIf} from "@angular/common";

import {
  MatDatepickerModule
} from "@angular/material/datepicker";
import {DateAdapter, MatNativeDateModule} from "@angular/material/core";
import {FormService} from "../../../shared/services/form-service/form.service";
import {StepperComponent} from "../../../shared/components/stepper/stepper.component";
import {ValidationService} from "../../../shared/services/validation-service";
import {MatCard, MatCardContent, MatCardTitle} from "@angular/material/card";
import {CurrencyFormatDirective} from "../../../shared/directives/currency-directive";
import {NumericFieldDirective} from "../../../shared/directives/numeric-field-directive";
import {GermanDateAdapter} from "../../../shared/utils/germanDateAdapter";

@Component({
  selector: 'app-wizard-sponsoring-financial-assistance',
  standalone: true,
  imports: [
    TextButtonComponent,
    TranslocoModule,
    WizardHeaderComponent,
    FormsModule,
    MatRadioButton,
    MatRadioGroup,
    ReactiveFormsModule,
    MatFormField,
    MatSelect,
    HintComponent,
    MatInput,
    MatOption,
    NgForOf,
    FormsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    WizardFooterComponent,
    StepperComponent,
    MatCard,
    MatCardTitle,
    MatCardContent,
    CurrencyFormatDirective,
    NumericFieldDirective
  ],
  providers: [{provide: DateAdapter, useClass: GermanDateAdapter}],
  templateUrl: './financial-assistance.component.html',
  styleUrl: './financial-assistance.component.scss'
})

export class FinancialAssistanceComponent implements OnInit {
  @Input() editMode = false;
  @Output() financialAssistanceUpdated: EventEmitter<any> = new EventEmitter<any>();

  form = this.formService.buildForm();
  rates: number[] = [2, 3, 4];


  get financialAssistance(): FormGroup {
    return this.form.get('financialAssistance') as FormGroup;
  }

  get listOfRatesFirstYear(): FormArray {
    return this.form.get('financialAssistance.firstYear.rates') as FormArray;
  }

  get listOfRatesSecondYear(): FormArray {
    return this.form.get('financialAssistance.secondYear.rates') as FormArray;
  }


  constructor(public wizardService: WizardService,
              private dateAdapter: DateAdapter<Date>,
              private fb: FormBuilder,
              public formService: FormService,
              public validationService: ValidationService) {
    this.dateAdapter.setLocale('de');
  }

  ngOnInit() {
    this.formService.initializeForm(this.form);
  }

  changePaymentMethod(fullPayed: MatRadioChange, formGroup: string, year: number): void {
    this.form.get(`financialAssistance.${formGroup}.fullPayed`)?.setValue(fullPayed.value);

    if (year === 1 || formGroup === 'firstYear') {
      this.clearRates(this.listOfRatesFirstYear);
      this.form.get('financialAssistance.firstYear.rateAmount')?.setValue(null);
      this.addRatesForFirstYear(1);
    } else if (formGroup === 'secondYear') {
      this.clearRates(this.listOfRatesSecondYear);
      this.form.get('financialAssistance.secondYear.rateAmount')?.setValue(null);
      this.addRatesForSecondYear(1);
    }
    this.formService.setFormData(this.form);
  }

  getSponsoringValue(year: string): string | null {
    const taxFree = this.form.get('sponsoringValue.taxFree')?.value

    if (year === 'firstYear') {
      const value = taxFree ? this.form.get('sponsoringValue.sponsoringValueNetAmountFirstYear')?.value : this.form.get('sponsoringValue.sponsoringValueGrossAmountFirstYear')?.value;
      return this.formatSponsoringValue(value);
    } else if (year === 'secondYear') {
      const value =  taxFree ? this.form.get('sponsoringValue.sponsoringValueNetAmountSecondYear')?.value : this.form.get('sponsoringValue.sponsoringValueGrossAmountSecondYear')?.value;
      return this.formatSponsoringValue(value);
    }
    return null
  }


  formatSponsoringValue(value: string): string {
    if(value){
      const item = value.replace(/\./g, '').replace(',', '.');
      const numericValue = parseFloat(item);
      if (!isNaN(numericValue)) {
        const formattedValue = new Intl.NumberFormat('de-DE', {
          style: 'decimal',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(numericValue);
        return formattedValue;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  onSelectRate(selectedRate: number, formGroup: string): void {
    if(formGroup === 'firstYear') {
      this.form.get('financialAssistance.firstYear.rateAmount')?.setValue(selectedRate);
      this.addRatesForFirstYear(selectedRate)
    }
    if(formGroup === 'secondYear') {
      this.form.get('financialAssistance.secondYear.rateAmount')?.setValue(selectedRate);
      this.addRatesForSecondYear(selectedRate);
    }
    this.formService.setFormData(this.form);
  }

  changeRepetition(event: MatRadioChange): void {
    if (!event) return;
    const repetitionYearControl = this.form.get('financialAssistance.repetitionYear');

    if (event.value === false) {
      repetitionYearControl?.setValue(null);
      repetitionYearControl?.clearValidators();
    } else {
      repetitionYearControl?.setValidators(Validators.required);
    }
    repetitionYearControl?.updateValueAndValidity();
    this.formService.setFormData(this.form);
    this.disableFormControl();
  }


  changeZuschuss(event: MatRadioChange) {
    if(event){
      this.formService.setFormData(this.form);
    }
  }

  isFormInvalid(form: any): boolean {
    const controls = this.validationService.isFinancialAssistanceInvalid(form);
    const hasInvalidStatus = controls.some(control => control.status === "INVALID");
    return hasInvalidStatus ||this.validationService.validateSponsoringRatesTotal(form, 'firstYear') || this.validationService.validateSponsoringRatesTotal(form, 'secondYear')
  }

  saveInEditMode(form: any): void {
    if(this.isFormInvalid(form)) {
      return;
    } else {
      this.formService.setFormData(form);
      this.financialAssistanceUpdated.emit(form);
    }
  }

  private validateForm(form: any): void {
    const dateSub = this.form.get('financialAssistance.financialAssistanceDate');
  }

  private addRatesForFirstYear(numberOfRates?: number): void {
    const rateControl = numberOfRates !== undefined ? numberOfRates : this.form.get('financialAssistance.firstYear.rateAmount')?.value;
    this.clearRates(this.listOfRatesFirstYear);

    if (numberOfRates === 1) {
      this.listOfRatesFirstYear.push(this.fb.group({
        date: this.fb.control(null, [Validators.required]),
      }));
    } else {
      for (let i = 0; i < rateControl; i++) {
        this.listOfRatesFirstYear.push(this.formService.createNewRateForm(i));
      }
    }
  }


  private addRatesForSecondYear(numberOfRates?: number): void {
    const rateControl = numberOfRates !== undefined ? numberOfRates : this.form.get('financialAssistance.secondYear.rateAmount')?.value;
    this.clearRates(this.listOfRatesSecondYear);

    if(this.form.get('sponsoringValue.sponsoringDuration')?.value === 2){
      if (numberOfRates === 1) {
        this.listOfRatesSecondYear.push(this.fb.group({
          date: this.fb.control(null, [Validators.required]),
        }));
      } else {
        for (let i = 0; i < rateControl; i++) {
          this.listOfRatesSecondYear.push(this.formService.createNewRateForm(i));
        }
      }
    }
  }

  private disableFormControl(): void {
    if (this.form.get('financialAssistance.repetition')?.value) {
      this.form.get('financialAssistance.repetitionYear')?.enable();
    } else {
      this.form.get('financialAssistance.repetitionYear')?.enable();
      localStorage.removeItem('form-data.financialAssistance.repetitionYear');
    }
  }

  private clearRates(rateList: FormArray<any>) {
    while (rateList.length !== 0) {
      rateList.removeAt(0);
    }
  }
}


